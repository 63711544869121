export enum ERROR_CODE {
	EMAIL_EXISTED = 'EMAIL_EXISTED',
	VERIFY_FAIL = 'VERIFY_FAIL',
	VERIFY_ERROR_LIMIT_REACHED = 'VERIFY_ERROR_LIMIT_REACHED',
	USER_NOT_FOUND = 'USER_NOT_FOUND',
	USER_EMAIL_NOT_FOUND = 'USER_NOT_FOUND',
	CODE_NOT_FOUND = 'CODE_NOT_FOUND',
	USER_IS_UNVERIFIED = 'USER_IS_UNVERIFIED',
	PASSWORD_ERROR = 'PASSWORD_ERROR',
	LOGIN_FAIL = 'LOGIN_FAIL',
	USER_IS_DISABLE = 'USER_IS_DISABLE',
	MOBILE_HAS_SOCIALITE = 'MOBILE_HAS_SOCIALITE',
	MOBILE_EXISTED = 'MOBILE_EXISTED',
	CODE_EXPIRED = 'CODE_EXPIRED',
	CODE_ERROR = 'CODE_ERROR',
	EMAIL_HAS_SOCIALITE = 'EMAIL_HAS_SOCIALITE',
	FACEBOOK_ID_IS_REGISTERED = 'FACEBOOK_ID_IS_REGISTERED',
	LINE_ID_IS_REGISTERED = 'LINE_ID_IS_REGISTERED',
	USER_IS_AUTHENTICATED = 'USER_IS_AUTHENTICATED',
	OLD_PASSWORD_ERROR = 'OLD_PASSWORD_ERROR',
	PASSWORD_SAME_AS_OLD = 'PASSWORD_SAME_AS_OLD',
	USER_NOT_FOUND_MOBILE = 'USER_NOT_FOUND_MOBILE',
	FACEBOOK_ACCOUNT_ALREADY_BIND = 'FACEBOOK_ACCOUNT_ALREADY_BIND',
	LINE_TOKEN_ERROR = 'LINE_TOKEN_ERROR',
	LINE_ACCOUNT_ALREADY_BIND = 'LINE_ACCOUNT_ALREADY_BIND',
}

export enum ERROR_MESSAGES {
	EMAIL_EXISTED = '電子信箱已被註冊，如有疑問請撥打客服專線',
	VERIFY_FAIL = '驗證碼錯誤，請重新輸入',
	VERIFY_ERROR_LIMIT_REACHED = '驗證碼輸入錯誤達 5 次，請重新取得驗證碼',
	USER_NOT_FOUND = '此電子信箱尚未註冊',
	USER_EMAIL_NOT_FOUND = '此電子信箱尚未註冊',
	CODE_NOT_FOUND = '驗證碼不存在或已驗證',
	USER_IS_UNVERIFIED = '會員尚未進行驗證',
	PASSWORD_ERROR = '密碼錯誤',
	LOGIN_FAIL = '帳號或密碼輸入錯誤',
	USER_IS_DISABLE = '此會員帳號已被停用',
	MOBILE_HAS_SOCIALITE = '手機號碼已使用第三方註冊，如有疑問請撥打客服專線',
	MOBILE_EXISTED = '手機號碼已被註冊，如有疑問請撥打客服專線',
	CODE_EXPIRED = '此驗證碼時效已超過',
	CODE_ERROR = '驗證碼錯誤',
	EMAIL_HAS_SOCIALITE = '電子信箱已使用第三方註冊，如有疑問請撥打客服專線',
	FACEBOOK_ID_IS_REGISTERED = '電子信箱已被註冊，如有疑問請撥打客服專線',
	LINE_ID_IS_REGISTERED = '電子信箱已被註冊，如有疑問請撥打客服專線',
	USER_IS_AUTHENTICATED = '會員已驗證過',
	OLD_PASSWORD_ERROR = '舊密碼錯誤',
	PASSWORD_SAME_AS_OLD = '新密碼不可與舊密碼相同',
	USER_NOT_FOUND_MOBILE = '此手機號碼尚未被註冊',
	FACEBOOK_ACCOUNT_ALREADY_BIND = '此 Facebook 帳號已被綁定',
	LINE_ACCOUNT_ALREADY_BIND = '此 LINE 帳號已被綁定',
}
