export enum PickupType {
	/**
	 * 宅配 (Home Delivery)
	 */
	HOME_DELIVERY = 'HOME_DELIVERY',

	/**
	 * 門市自取 (Store Pickup)
	 */
	STORE_PICKUP = 'STORE_PICKUP',
}
