import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import { getBanners } from 'models/banner';
import {
	getProducts,
	getStorePickupCalendarDetail,
	getHomeDeliveryCalendarDetail,
} from 'models/products';
import { setCartData } from 'models/cart';
import { toast } from 'models/toast';
import { setEcUserToken, getUserInfo } from 'models/member';
import { getMaintainImg, openMaintainModal } from 'models/modal';

import { getEcUserByStorage, setEcUserByStorage } from 'util/storage';
import { parseQueryString } from 'util/parseQueryString';

import ProductsRoute from './Products';
import SignInRoute from './SignIn';
import SignUpRoute from './SignUp';
import LineAuthRoute from './LineAuth';
import TermsOfServiceRoute from './TermsOfService';
import PrivacyStatementRoute from './PrivacyStatement';
import FaqRoute from './FrequentlyAskedQuestions';
import ShoppingGuideRoute from './ShoppingGuide';
import EmailVerifyRoute from './EmailVerify';
import ForgetPasswordRoute from './ForgetPassword';
import ResetPasswordRoute from './ResetPassword';
import ResetMobileRoute from './ResetMobile';
import CartRoute from './Cart';
import CheckoutRoute from './Checkout';
import MemberCentreRoute from './MemberCentre';
import SignUpLineRoute from './SignUpLine';
import MemberOrderDetailRoutes from './MemberOrderDetail';
import Payment3DSecureRoutes from './Payment3DSecure';

const childrenHomeRoute: CustomRoute = {
	path: '',
	components: () => [import(/* webpackChunkName: 'home' */ './Home')],
	render: ([Home]) => <Home />,
	onEnter: async ({ next, store }) => {
		const { verify } = parseQueryString();

		if (verify === 'success') {
			store.dispatch(
				toast({
					message: '註冊成功！開始選購吧',
					type: 'success',
					color: 'green',
				}),
			);
		}
		store.dispatch(getBanners());
		await store.dispatch(getMaintainImg());
		store.dispatch(openMaintainModal());
		// await store.dispatch(getProducts());
		console.log('on Enter Home');
		const children = await next();
		console.log('on Enter Home / end');
		return children;
	},
};

const routes: CustomRoute = {
	path: '/',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store }) => {
		const { token } = parseQueryString();
		if (token) {
			setEcUserByStorage(token);
		}
		const ecUserToken = getEcUserByStorage();
		await store.dispatch(setEcUserToken(ecUserToken));
		await store.dispatch(getProducts());
		await store.dispatch(getStorePickupCalendarDetail());
		await store.dispatch(getHomeDeliveryCalendarDetail());
		store.dispatch(setCartData());

		window.scrollTo(0, 0);

		if (ecUserToken) {
			await store.dispatch(getUserInfo());
		}

		console.log('on Enter Root');
		const children = await next();
		console.log('on Enter Root / end');

		return children;
	},
	children: [
		childrenHomeRoute,
		ProductsRoute,
		SignInRoute,
		SignUpRoute,
		LineAuthRoute,
		TermsOfServiceRoute,
		PrivacyStatementRoute,
		FaqRoute,
		ShoppingGuideRoute,
		EmailVerifyRoute,
		ForgetPasswordRoute,
		ResetPasswordRoute,
		ResetMobileRoute,
		CartRoute,
		CheckoutRoute,
		MemberCentreRoute,
		SignUpLineRoute,
		MemberOrderDetailRoutes,
		Payment3DSecureRoutes,
	],
};

export default routes;
