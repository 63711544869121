import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import {
	ShoppingQuestionCollection,
	ShoppingInstructionCollection,
} from 'util/api/swaggerApi/data-contracts';

import { createSelector } from 'reselect';
import { State as GlobalState } from './reducers';

export interface FaqPayload {
	faq: ShoppingQuestionCollection[];
}

export interface InstructionsPayload {
	shoppingGuide: ShoppingInstructionCollection[];
}

export const getFaqs = createAction<Promise<FaqPayload>>('GET_FAQS', async () => {
	try {
		const { v1ShoppingGuideQuestionsList } = api;
		const { data } = await v1ShoppingGuideQuestionsList();
		if (data?.data) {
			return {
				faq: data?.data,
			};
		}

		return { faq: [] };
	} catch (e) {
		return { faq: [] };
	}
});

export const getShoppingGuides = createAction<Promise<InstructionsPayload>>(
	'GET_SHOPPING_GUIDES',
	async () => {
		try {
			const { v1ShoppingGuideInstructionsList } = api;
			const { data } = await v1ShoppingGuideInstructionsList();
			if (data?.data) {
				return {
					shoppingGuide: data?.data.filter(item => item.isShow),
				};
			}

			return { shoppingGuide: [] };
		} catch (e) {
			return { shoppingGuide: [] };
		}
	},
);

export interface State {
	loading: boolean;
	faq: ShoppingQuestionCollection[];
	shoppingGuide: ShoppingInstructionCollection[];
}

export const defaultState: State = {
	loading: false,
	faq: [],
	shoppingGuide: [],
};

interface ShoppingGuidePayload {
	faq: ShoppingQuestionCollection[];
	shoppingGuide: ShoppingInstructionCollection[];
}

export const reducer = {
	shoppingGuide: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_FAQS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_FAQS_FULFILLED: (state, action: Action<ShoppingGuidePayload>) => ({
				...state,
				faq: action.payload.faq,
				loading: false,
			}),

			GET_SHOPPING_GUIDES_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_SHOPPING_GUIDES_FULFILLED: (state, action: Action<ShoppingGuidePayload>) => ({
				...state,
				shoppingGuide: action.payload.shoppingGuide,
				loading: false,
			}),
		},
		defaultState,
	),
};

const shoppingGuideActionsMap = {
	getFaqs,
	getShoppingGuides,
};

const faqSelector = (state: GlobalState) => state.shoppingGuide.faq;
const shoppingGuideSelector = (state: GlobalState) => state.shoppingGuide.shoppingGuide;

// Memoized selector
const mapHooksToState = createSelector(
	[faqSelector, shoppingGuideSelector],
	(faq, shoppingGuide) => ({
		faq,
		shoppingGuide,
	}),
);

type ShoppingGuideSelector = ReturnType<typeof mapHooksToState>;
type ShoppingGuideActionsMap = typeof shoppingGuideActionsMap;

export const useShoppingGuide = () =>
	useRedux<ShoppingGuideSelector, ShoppingGuideActionsMap>(
		mapHooksToState,
		shoppingGuideActionsMap,
	);
