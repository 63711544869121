/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch } from 'react';
import { createAction, handleActions, Action } from 'redux-actions';
import { nanoid } from 'nanoid';
import { useRedux } from 'util/hook/redux';
import { createSelector } from 'reselect';
import history from 'store/history';

import { toast } from 'models/toast';
import { ModalTypes, openModal } from 'models/modal';
import { signInSuccessCallBack } from 'models/signin';
// import { getLineNewMemberLink } from 'models/auth';

import { api } from 'util/api';
// import { omniNewRegistration } from 'util/omniEvent';
// import { gtmEvent } from 'util/gtmEvent';

import { ERROR_CODE } from 'enums/form';
import { StorageKey } from 'enums/storageKey';
import { V1AuthFacebookLinkCreateRequestPayload } from 'util/api/swaggerApi/data-contracts';

// import { ROUTE_PATHS } from 'routes';
import pushHistory from 'util/pushHistory';
import { setItem, getItem, removeItem } from 'util/storage';
import { GetState, State as GlobalState } from './reducers';

interface BasePayload {
	status: number;
	errorCode?: string | null;
}

interface Error {
	error: BasePayload;
}

interface GetNormalSignupResponse {
	userId: number;
}

interface CheckLineSignupUserEmailResponse {
	verifiedStatus: boolean;
}

interface normalSignupPayload {
	name: string;
	email: string;
	password: string;
	account: string;
	verifyCode: string;
	secondPassword: string;
}

export const normalSignup = createAction(
	'NORMAL_SIGNUP',
	(formData: normalSignupPayload) => async (dispatch: Dispatch<any>) => {
		try {
			const { name, email, password, account, verifyCode, secondPassword } = formData;
			const { v1AuthRegisterCreate } = api;
			const { status } = await v1AuthRegisterCreate({
				email,
				password,
				name,
				mobile: account,
				code: verifyCode,
				confirm_password: secondPassword,
			});
			if (status === 200) {
				dispatch(sendVerificationEmail(email, 'REGISTRATION'));
				const encodedEmail = encodeURIComponent(email);
				window.location.href = `/email-verify?email=${encodedEmail}&type=REGISTRATION`;
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const sendMobileVerificationCode = createAction(
	'SEND_MOBILE_VERIFICATION_CODE',
	(mobile: string) => async (dispatch: Dispatch<any>) => {
		try {
			const { v1AuthSendMobileVerificationSmsCreate } = api;
			const { status } = await v1AuthSendMobileVerificationSmsCreate({ mobile });
			if (status === 200) {
				const id = nanoid();
				dispatch(
					toast({
						id,
						message: '已成功發送手機驗證碼',
						type: 'success',
						color: 'green',
					}),
				);
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const sendVerificationEmail = createAction(
	'SEND_VERIFICATION_EMAIL',
	(email: string, usage: string) => async (dispatch: Dispatch<any>) => {
		try {
			const { v1AuthSendUserVerificationEmailCreate } = api;
			const { status } = await v1AuthSendUserVerificationEmailCreate({ email, usage });

			if (status === 200 && usage === 'RESET_PASSWORD') {
				const encodedEmail = encodeURIComponent(email);
				window.location.href = `/email-verify?email=${encodedEmail}&type=RESET_PASSWORD`;
			}

			return { status };
		} catch (e) {
			dispatch(openModal(ModalTypes.ErrorModal));
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

// export const verifySignupEmailCode = createAction(
// 	'VERIFY_SIGNUP_EMAIL_CODE',
// 	(email: string, code: string) => async (dispatch: Dispatch<any>, getState: GetState) => {
// 		try {
// 			const {
// 				discountEvents: { registrationEvent },
// 			} = getState();
// 			const { v1AuthVerifyEmailCodeCreate } = api;
// 			const { status, data } = await v1AuthVerifyEmailCodeCreate({
// 				email,
// 				code,
// 				use_for: 'register',
// 			});
// 			if (status === 200) {
// 				const { receivedRegistrationStatus } = data?.data;
// 				if (receivedRegistrationStatus && registrationEvent.length > 0) {
// 					// 註冊禮券領取成功
// 					dispatch(openModal(ModalTypes.SignUpGiftModal));
// 				}

// 				const id = nanoid();
// 				dispatch(
// 					toast({
// 						id,
// 						message: '驗證成功，請登入會員',
// 						type: 'success',
// 						color: 'green',
// 					}),
// 				);

// 				if (receivedRegistrationStatus === false) {
// 					// 註冊禮券領取失敗
// 					return { status, errorCode: 'received_registration_failed' };
// 				}

// 				pushHistory(history, `/${ROUTE_PATHS.signin}`);
// 			}
// 			return { status };
// 		} catch (e) {
// 			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
// 		}
// 	},
// );

// export const checkLineSignupUserEmail = createAction(
// 	'CHECK_LINE_SIGNUP_USER_EMAIL',
// 	(email: string) => async (dispatch: Dispatch<any>) => {
// 		try {
// 			const { v1AuthCheckUserEmailCreate } = api;
// 			const { status, data } = await v1AuthCheckUserEmailCreate({ email });
// 			if (status === 200) {
// 				const { verifiedStatus } = data?.data as CheckLineSignupUserEmailResponse;
// 				if (verifiedStatus) {
// 					window.location.href = `/bind?email=${email}`;
// 					return { status };
// 				}
// 				window.location.href = `/email-verify?email=${encodeURIComponent(email)}&type=signup`;
// 			}
// 			return { status };
// 		} catch (e) {
// 			// 查無此會員
// 			if ((e as Error).error.errorCode === ERROR_CODE.USER_NOT_FOUND) {
// 				dispatch(getLineNewMemberLink(email));
// 				return { status: 200 };
// 			}
// 			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
// 		}
// 	},
// );

/**
 * Facebook 登入
 */
const facebookNativeLogin = () =>
	new Promise((resolve, reject) => {
		window.FB.login(
			(response: any) => {
				if (response.status === 'connected') {
					resolve(response);
				} else {
					console.log(response);
				}
			},
			{ scope: 'public_profile,email' },
		);
	});

/**
 * Facebook 綁定
 */
export const bindFacebook = createAction(
	'BIND_FACEBOOK',
	(formData: normalSignupPayload) =>
		async (dispatch: Dispatch<any>): Promise<object | null> => {
			try {
				const facebookBind = getItem(StorageKey.FACEBOOK_BIND);
				const socialiteToken = facebookBind ? JSON.parse(facebookBind).socialiteToken : null;
				const facebookEmail = facebookBind ? JSON.parse(facebookBind).email : null;

				const { name, email, account, verifyCode } = formData;

				const { v1AuthFacebookLinkCreate } = api;
				const { status } = await v1AuthFacebookLinkCreate({
					email,
					name,
					mobile: account,
					code: verifyCode,
					socialite_token: socialiteToken,
				});

				if (status === 200) {
					if (facebookEmail === email) {
						pushHistory(history, `/?verify=success`);
						window.scrollTo(0, 0);
					} else {
						dispatch(sendVerificationEmail(email, 'REGISTRATION'));
						const encodedEmail = encodeURIComponent(email);
						window.location.href = `/email-verify?email=${encodedEmail}&type=REGISTRATION`;
					}
					removeItem(StorageKey.FACEBOOK_BIND);
				}
				return { status };
			} catch (e) {
				return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
			}
		},
);

export const facebook = createAction(
	'SIGNUP_FACEBOOK',
	() =>
		async (dispatch: Dispatch<any>): Promise<object | null> => {
			const auth: any = await facebookNativeLogin();

			const { accessToken } = auth.authResponse;

			const { v1AuthFacebookVerifyCreate } = api;
			const { status, data } = await v1AuthFacebookVerifyCreate({ token: accessToken });

			if (status === 200 && data?.data) {
				const { token } = data?.data as { name: string; token: string };

				// 如果有 token 就直接登入，沒有則跳轉到註冊頁面
				if (token) {
					dispatch(
						signInSuccessCallBack({
							token,
							message: '登入成功！',
							method: 'normal',
						}),
					);
				} else {
					setItem(StorageKey.FACEBOOK_BIND, JSON.stringify(data?.data));

					window.location.href = '/signup-facebook';
				}

				return data.data;
			}

			if (status !== 200) {
				window.location.href = '/signup-facebook';
			}

			return null;
		},
);

export interface State {
	loading: boolean;
	normalSignupStatus: BasePayload;
	verificationEmailStatus: BasePayload;
	verifySignupEmailCodeStatus: BasePayload;
	checkLineSignupUserEmailStatus: BasePayload;
	verificationCodeStatus: BasePayload;
	facebookLoginStatus: V1AuthFacebookLinkCreateRequestPayload;
	bingFacebookStatus: BasePayload;
}

export const defaultState: State = {
	loading: false,
	normalSignupStatus: {
		status: 0,
		errorCode: null,
	},
	verificationEmailStatus: {
		status: 0,
		errorCode: null,
	},
	verifySignupEmailCodeStatus: {
		status: 0,
		errorCode: null,
	},
	checkLineSignupUserEmailStatus: {
		status: 0,
		errorCode: null,
	},
	verificationCodeStatus: {
		status: 0,
		errorCode: null,
	},
	bingFacebookStatus: {
		status: 0,
		errorCode: null,
	},
	facebookLoginStatus: {
		name: '',
		email: '',
		mobile: '',
		socialite_token: '', // Add the missing property 'socialite_token'
	},
};

export const reducer = {
	signup: handleActions<State, any>(
		{
			NORMAL_SIGNUP_PENDING: state => ({
				...state,
				loading: true,
			}),
			NORMAL_SIGNUP_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				normalSignupStatus: action.payload,
				loading: false,
			}),
			NORMAL_SIGNUP_REJECTED: state => ({
				...state,
				loading: false,
			}),
			SEND_VERIFICATION_EMAIL_PENDING: state => ({
				...state,
				loading: true,
			}),
			SEND_VERIFICATION_EMAIL_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				verificationEmailStatus: action.payload,
				loading: false,
			}),
			SEND_VERIFICATION_EMAIL_REJECTED: state => ({
				...state,
				loading: false,
			}),
			SEND_MOBILE_VERIFICATION_CODE_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				verificationCodeStatus: action.payload,
				loading: false,
			}),
			SEND_MOBILE_VERIFICATION_CODE_REJECTED: state => ({
				...state,
				loading: false,
			}),
			VERIFY_SIGNUP_EMAIL_CODE_PENDING: state => ({
				...state,
				loading: true,
			}),
			VERIFY_SIGNUP_EMAIL_CODE_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				verifySignupEmailCodeStatus: action.payload,
				loading: false,
			}),
			VERIFY_SIGNUP_EMAIL_CODE_REJECTED: state => ({
				...state,
				loading: false,
			}),
			CHECK_LINE_SIGNUP_USER_EMAIL_PENDING: state => ({
				...state,
				loading: true,
			}),
			CHECK_LINE_SIGNUP_USER_EMAIL_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				checkLineSignupUserEmailStatus: action.payload,
				loading: false,
			}),
			CHECK_LINE_SIGNUP_USER_EMAIL_REJECTED: state => ({
				...state,
				loading: false,
			}),
			SIGNUP_FACEBOOK_FULFILLED: (
				state,
				action: Action<V1AuthFacebookLinkCreateRequestPayload>,
			) => ({
				...state,
				facebookLoginStatus: action.payload,
				loading: false,
			}),
			BIND_FACEBOOK_PENDING: state => ({
				...state,
				loading: true,
			}),
			BIND_FACEBOOK_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				bingFacebookStatus: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const signupActionsMap = {
	normalSignup,
	sendVerificationEmail,
	sendMobileVerificationCode,
	facebook,
	bindFacebook,
	// verifySignupEmailCode,
	// checkLineSignupUserEmail,
};

const normalSignupStatusSelector = (state: GlobalState) => state.signup.normalSignupStatus;
const verificationEmailStatusSelector = (state: GlobalState) =>
	state.signup.verificationEmailStatus;
const verificationCodeStatusSelector = (state: GlobalState) => state.signup.verificationCodeStatus;
const loadingSelector = (state: GlobalState) => state.signup.loading;
const facebookLoginStatusSelector = (state: GlobalState) => state.signup.facebookLoginStatus;
const bindFacebookStatusSelector = (state: GlobalState) => state.signup.bingFacebookStatus;

const mapHooksToState = createSelector(
	[
		normalSignupStatusSelector,
		verificationEmailStatusSelector,
		verificationCodeStatusSelector,
		loadingSelector,
		facebookLoginStatusSelector,
		bindFacebookStatusSelector,
	],
	(
		normalSignupStatus,
		verificationEmailStatus,
		verificationCodeStatus,
		loading,
		facebookLoginStatus,
		bindFacebookStatus,
	) => ({
		normalSignupStatus,
		verificationEmailStatus,
		verificationCodeStatus,
		loading,
		facebookLoginStatus,
		bindFacebookStatus,
	}),
);

type SignupSelector = ReturnType<typeof mapHooksToState>;
type SignupActionsMap = typeof signupActionsMap;

export const useSignup = () =>
	useRedux<SignupSelector, SignupActionsMap>(mapHooksToState, signupActionsMap);
