import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { api } from 'util/api';

import { getImageFromStorage } from 'util/getImageFromStorage';
import { createSelector } from 'reselect';
import {
	ProductResource,
	CalendarResource,
	ProductCollection,
} from 'util/api/swaggerApi/data-contracts';

import { PickupType } from 'enums/pickupType';

import { State as GlobalState } from './reducers';

const defaultProduct: ProductResource = {
	id: 1,
	name: '產品名稱',
	briefDescription: '產品簡述',
	productType: 'NORMAL',
	shippingType: 'ROOM_TEMP',
	volume: 'L',
	price: 100,
	purchaseLimit: 1,
	weight: 0,
	ingredients: '成分',
	expiration: '保存期限',
	shippingInfo: '運送資訊',
	introduction: '<p>產品介紹</p>',
	remark: '<p>備註</p>',
	isShow: true,
	status: 'ONLINE',
	images: [],
	shippingFeeImage: '',
};

interface ProductsPayload {
	products: ProductResource[];
}

interface AddonProductsPayload {
	addonProducts: ProductResource[];
}

export const getProducts = createAction<Promise<ProductsPayload>>('GET_PRODUCTS', async () => {
	try {
		const { v1ProductsList } = api;
		const { data } = await v1ProductsList();
		const products = data?.data
			?.filter((product: ProductResource) => product.isShow && product.status === 'ONLINE')
			.map((product: ProductResource) => ({
				...product,
				images: product?.images?.map(img => getImageFromStorage(img)),
			})) as ProductResource[];

		return { products };
	} catch (e) {
		return { products: [] };
	}
});

export const getAddonProducts = createAction<Promise<AddonProductsPayload>>(
	'GET_ADDON_PRODUCTS',
	async () => {
		try {
			const { v1ProductsAddonsList } = api;
			const { data } = await v1ProductsAddonsList();
			const addonProducts = data?.data
				?.filter((product: ProductCollection) => product.status === 'ONLINE')
				.map((product: ProductCollection) => ({
					...product,
					images: product?.images?.map(img => getImageFromStorage(img)),
				})) as ProductCollection[];

			return { addonProducts };
		} catch (e) {
			return { addonProducts: [] };
		}
	},
);

interface ProductPayload {
	product: ProductResource;
}

export const getProductById = createAction<Promise<ProductPayload>, number>(
	'GET_PRODUCT_BY_ID',
	async (id: number) => {
		try {
			const { v1ProductsDetail } = api;
			const { data } = await v1ProductsDetail(id);
			const product = {
				...data?.data,
				images: data?.data?.images?.map((img: string) => getImageFromStorage(img)),
				shippingFeeImage: getImageFromStorage(data?.data?.shippingFeeImage ?? ''),
			};

			return { product };
		} catch (e) {
			return { product: defaultProduct };
		}
	},
);

interface CalendarDetailPayload {
	storePickupCalendar: CalendarResource;
}

export const getStorePickupCalendarDetail = createAction<Promise<CalendarDetailPayload>>(
	'GET_STORE_CALENDAR_DETAIL',
	async () => {
		try {
			const { v1CalendarDetail } = api;
			const { data } = await v1CalendarDetail({ type: PickupType.STORE_PICKUP });
			const storePickupCalendar = {
				...data?.data,
			};

			return { storePickupCalendar };
		} catch (e) {
			return { storePickupCalendar: {} };
		}
	},
);

interface HomeDeliveryCalendarDetailPayload {
	homeDeliveryCalendar: CalendarResource;
}

export const getHomeDeliveryCalendarDetail = createAction<
	Promise<HomeDeliveryCalendarDetailPayload>
>('GET_HOME_CALENDAR_DETAIL', async () => {
	try {
		const { v1CalendarDetail } = api;
		const { data } = await v1CalendarDetail({ type: PickupType.HOME_DELIVERY });
		const homeDeliveryCalendar = {
			...data?.data,
		};

		return { homeDeliveryCalendar };
	} catch (e) {
		return { homeDeliveryCalendar: {} };
	}
});

export const removeSelectProduct = createAction('REMOVE_SELECT_PRODUCT');

// For Global State usage
export interface State {
	loading: boolean;
	products: ProductResource[];
	addonProducts: ProductResource[];
	select: ProductResource;
	storePickupCalendar: CalendarResource;
	homeDeliveryCalendar: CalendarResource;
}

export const defaultState: State = {
	loading: false,
	products: [],
	addonProducts: [],
	select: {},
	storePickupCalendar: {},
	homeDeliveryCalendar: {},
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	products: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_PRODUCTS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_PRODUCTS_FULFILLED: (state, action: Action<ProductsPayload>) => ({
				...state,
				products: [...action.payload.products],
				loading: false,
			}),
			GET_PRODUCT_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_PRODUCT_BY_ID_FULFILLED: (state, action: Action<ProductPayload>) => ({
				...state,
				select: action.payload.product,
				loading: false,
			}),
			REMOVE_SELECT_PRODUCT: state => ({
				...state,
				select: {},
			}),
			GET_STORE_CALENDAR_DETAIL_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_STORE_CALENDAR_DETAIL_FULFILLED: (state, action: Action<CalendarDetailPayload>) => ({
				...state,
				storePickupCalendar: action.payload.storePickupCalendar,
				loading: false,
			}),
			GET_HOME_CALENDAR_DETAIL_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_HOME_CALENDAR_DETAIL_FULFILLED: (
				state,
				action: Action<HomeDeliveryCalendarDetailPayload>,
			) => ({
				...state,
				homeDeliveryCalendar: action.payload.homeDeliveryCalendar,
				loading: false,
			}),
			GET_ADDON_PRODUCTS_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_ADDON_PRODUCTS_FULFILLED: (state, action: Action<AddonProductsPayload>) => ({
				...state,
				addonProducts: [...action.payload.addonProducts],
				loading: false,
			}),
		},
		defaultState,
	),
};

const productsActionsMap = {
	getProducts,
	removeSelectProduct,
	getStorePickupCalendarDetail,
	getHomeDeliveryCalendarDetail,
	getAddonProducts,
};

const productsSelector = (state: GlobalState) => state.products.products;
const selectSelector = (state: GlobalState) => state.products.select;
const loadingSelector = (state: GlobalState) => state.products.loading;
const storePickupCalendarSelector = (state: GlobalState) => state.products.storePickupCalendar;
const homeDeliveryCalendarSelector = (state: GlobalState) => state.products.homeDeliveryCalendar;
const addonProductsSelector = (state: GlobalState) => state.products.addonProducts;

// Memoized selector
const mapHooksToState = createSelector(
	[
		productsSelector,
		selectSelector,
		loadingSelector,
		storePickupCalendarSelector,
		homeDeliveryCalendarSelector,
		addonProductsSelector,
	],
	(products, select, loading, storePickupCalendar, homeDeliveryCalendar, addonProducts) => ({
		products,
		select,
		loading,
		storePickupCalendar,
		homeDeliveryCalendar,
		addonProducts,
	}),
);

type productsSelector = ReturnType<typeof mapHooksToState>;
type productsActionsMap = typeof productsActionsMap;

export const useProducts = () =>
	useRedux<productsSelector, productsActionsMap>(mapHooksToState, productsActionsMap);
