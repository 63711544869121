/* eslint-disable no-shadow */
import history from 'store/history';

import { MemberFeatureType } from 'enums/memberFeatureType';
import { OrderConfirmationType } from 'enums/orderConfirmationType';

import Success from 'images/icon/order-success.inline.svg';
import Fail from 'images/icon/order-fail.inline.svg';
import Waiting from 'images/icon/order-waiting.inline.svg';
import pushHistory from 'util/pushHistory';
import { isEmpty, isExist } from 'util/helper';

export const orderStatus = {
	[OrderConfirmationType.PAYMENT_SUCCESSFUL]: {
		icon: Success,
		title: '訂單已成立',
		content: `感謝您的購買，訂單已建立成功！`,
		btn: '查看我的訂單',
		handleClickBtn: () => {
			history.push({
				pathname: '/member-centre',
				search: `type=${MemberFeatureType.ORDERS}`,
			});
			window.scrollTo(0, 0);
		},
	},
	[OrderConfirmationType.ORDER_ESTABLISHED]: {
		icon: Success,
		title: '訂單已成立',
		content: `訂單號碼`,
		contentSec:
			'已成立\n請備好現金並等待商品送達\n欲查詢購買明細及追蹤出貨進度，請點擊下方查看我的訂單',
		btn: '查看我的訂單',
		handleClickBtn: () => {
			// pushHistory(history, `/${ROUTE_PATHS.memberCentre}?type=${MemberFeatureType.ORDERS}`);
			window.scrollTo(0, 0);
		},
	},
	[OrderConfirmationType.WAITING_FOR_TRANSFER]: {
		icon: Waiting,
		title: '待付款',
		content: ``,
		contentSec: ' 已成立\n請使用網路銀行或實體 ATM 將款項轉入以下帳戶：',
		btn: '查看我的訂單',
		handleClickBtn: () => {
			history.push({
				pathname: '/member-centre',
				search: `type=${MemberFeatureType.ORDERS}`,
			});
			window.scrollTo(0, 0);
		},
	},
	[OrderConfirmationType.PAYMENT_FAIL]: {
		icon: Fail,
		title: '付款失敗',
		content: `您已超過付款時效，訂單已取消，請點擊下方按鈕重新選購。`,
		btn: '前往選購',
		handleClickBtn: () => {
			history.push({ pathname: '/' });
		},
	},
	[OrderConfirmationType.ORDER_FAIL]: {
		icon: Fail,
		title: '訂單提交失敗',
		content: `系統偵測到網路異常，\n請確認網路環境後再次提交訂單。`,
		btn: '回購物清單',
		handleClickBtn: () => {
			history.push({ pathname: '/cart' });
			window.scrollTo(0, 0);
		},
	},
	paymentInvalid: {
		icon: Fail,
		title: '付款失敗',
		content: `很抱歉，訂單號碼`,
		contentSec:
			'重新付款次數已達上限，系統已自動取消訂單\n您可點擊下方按鈕並重新下單，造成不便敬請見諒！',
		btn: '重新選購',
		handleClickBtn: () => {
			pushHistory(history, '/products?all=1');
		},
	},
};

interface RedirectParams {
	status: string;
	orderId?: number;
	orderNo?: string;
	orderMessage?: string;
}

export const redirectToResultPageByStatus = ({
	status,
	orderId,
	orderNo,
	orderMessage,
}: RedirectParams) => {
	if (isEmpty(status)) {
		return '';
	}

	let queryString = '';

	switch (status) {
		case OrderConfirmationType.PAYMENT_SUCCESSFUL:
			queryString = `
				status=${OrderConfirmationType.PAYMENT_SUCCESSFUL}&
				orderNo=${isExist(orderNo) ? orderNo : ''}&
				message=${isExist(orderMessage) ? orderMessage : ''}
			`;
			break;
		case OrderConfirmationType.WAITING_FOR_TRANSFER:
			queryString = `
				status=${OrderConfirmationType.WAITING_FOR_TRANSFER}&
				orderId=${isExist(orderId) ? orderId : ''}&
				orderNo=${isExist(orderNo) ? orderNo : ''}&
				message=${isExist(orderMessage) ? orderMessage : ''}
			`;
			break;
		case OrderConfirmationType.ORDER_FAIL:
			queryString = `
				status=${OrderConfirmationType.ORDER_FAIL}&
				message=${isExist(orderMessage) ? orderMessage : ''}
			`;
			break;
		case OrderConfirmationType.PAYMENT_FAIL:
			queryString = `
				status=${OrderConfirmationType.PAYMENT_FAIL}&
				orderId=${isExist(orderId) ? orderId : ''}&
				orderNo=${isExist(orderNo) ? orderNo : ''}&
				message=${isExist(orderMessage) ? orderMessage : ''}
			`;
			break;
		default:
			break;
	}

	history.push({
		pathname: '/checkout',
		search: queryString,
	});

	window.scrollTo({ top: 0 });

	return undefined;
};
