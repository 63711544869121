import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getEcUserByStorage } from 'util/storage';
import pushHistory from 'util/pushHistory';

const routes: CustomRoute = {
	path: '/change-password',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'productsInner' */ './component')],
			render: ([ChangePassword]) => <ChangePassword />,
			onEnter: async ({ next, history }) => {
				const ecUserToken = getEcUserByStorage();

				if (!ecUserToken) {
					pushHistory(history, `/`);
					window.scrollTo(0, 0);
					return null;
				}

				const children = await next();
				return children;
			},
		},
	],
};
export default routes;
