import React from 'react';
import classnames from 'classnames';

import Link from 'components/atoms/Link';

import useMedia from 'util/hook/useMedia';

import LogoIcon from 'images/logo-footer.inline.svg';
import FacebookIcon from 'images/icon/facebook.inline.svg';
import InstagramIcon from 'images/icon/instagram.inline.svg';
// import LineIcon from 'images/icon/line.inline.svg';
import PhoneIcon from 'images/icon/phone.inline.svg';
import PageTopIcon from 'images/icon/page-top.inline.svg';

import styles from './index.module.css';

type LinkType = 'internal' | 'external';

export interface FooterItemsProperty {
	title: string;
	data: {
		item: string;
		link?: string;
		linkType?: LinkType;
		Icon?: string;
		onClick?: () => void;
	}[];
	className?: string;
}

const FooterItems: React.FC<FooterItemsProperty> = ({ title, data, className }) => (
	<div className={classnames(styles.footerItems, className)}>
		<p className={styles.title}>{title}</p>
		{data.map(itemData => {
			const { Icon } = itemData;
			return (
				<Link
					className={classnames(styles.item, { [styles.customService]: title === '服務時間' })}
					key={itemData.item}
					to={itemData.link}
					type={itemData.linkType}
					onClick={itemData.onClick}
				>
					{Icon && <Icon />}
					{itemData.item}
				</Link>
			);
		})}
	</div>
);

const PageTopButton = () => {
	const moveToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	return (
		<button
			type="button"
			className={styles.pageTopButton}
			onClick={moveToTop}
			aria-label="Scroll to top"
		>
			<PageTopIcon />
		</button>
	);
};

interface FooterProperty {
	className?: string;
}

const Footer: React.FC<FooterProperty> = ({ className }) => {
	const media = useMedia();

	const telPhone = '0423808199';
	return (
		<footer className={classnames(styles.footer, className)}>
			<div className={styles.wrapper}>
				<div className={styles.logoContainer}>
					{/* <img src={LogoIcon} alt="logo" /> */}
					<LogoIcon />
				</div>
				<div className={styles.itemsContainer}>
					{/* 服務時間 */}
					<FooterItems
						title="服務時間"
						data={[
							{ item: '10:00 - 19:00 （週三店休）' },
							{ item: '台中市南屯區永春東七路 725 號' },
						]}
						className={styles.service}
					/>

					{/* 客服電話 */}
					<div className={classnames(styles.footerItems, styles.phone)}>
						<p className={styles.title}>客服電話</p>
						<a href={`tel:${telPhone}`} className={classnames(styles.item, styles.callTo)}>
							<PhoneIcon />
							04-2380 8199
						</a>
					</div>

					{/* 顧客服務 */}
					<FooterItems
						title="顧客服務"
						data={[
							{ item: '訂購說明', link: '/shopping-guide' },
							{ item: '常見問題', link: '/faq' },
							{ item: '隱私權說明', link: '/privacy-statement' },
							{ item: '註冊條款', link: '/terms-of-service' },
						]}
					/>

					{/* 官方社群 */}
					{media === 'mobile' && (
						<div className={classnames(styles.footerItems, styles.follow)}>
							<p className={styles.title}>官方社群</p>
							<div className={styles.socials}>
								<div
									onKeyDown={() => {}}
									role="button"
									tabIndex={-1}
									className={classnames(styles.item, styles.social)}
									aria-label="Instagram"
								>
									<Link
										to="https://www.instagram.com/one.only.bakery/"
										className={styles.socialBtn}
										type="external"
									>
										<InstagramIcon />
									</Link>
								</div>
								<div
									onKeyDown={() => {}}
									role="button"
									tabIndex={-1}
									className={classnames(styles.item, styles.social)}
									aria-label="Facebook"
								>
									<Link
										to="https://www.facebook.com/oneonlybakery"
										className={styles.socialBtn}
										type="external"
									>
										<FacebookIcon />
									</Link>
								</div>

								{
									// 移至 Phrase 2
									/* <div
									onKeyDown={() => {}}
									role="button"
									tabIndex={-1}
									className={classnames(styles.item, styles.social)}
									aria-label="Line"
								>
									<Link className={styles.socialBtn}>
										<LineIcon />
									</Link>
								</div> */
								}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className={styles.bottomContainer}>
				<div />
				<p className={styles.copyright}>&copy; {new Date().getFullYear()} All rights reserved.</p>
				<div className={styles.links}>
					<Link to="https://www.instagram.com/one.only.bakery/" type="external">
						<InstagramIcon />
					</Link>
					<Link to="https://www.facebook.com/oneonlybakery" type="external">
						<FacebookIcon />
					</Link>
					{
						// 移至 Phrase 2
						/* <Link to="/" type="external">
						<LineIcon />
					</Link> */
					}
				</div>
			</div>
			<PageTopButton />
		</footer>
	);
};

export default Footer;
