import React, { useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import CloseIcon from 'images/icon/close-modal.inline.svg';

import formatWithThousandsSeparator from 'util/formatWithThousandsSeparator';

import { createPortal } from 'react-dom';
import { ModalTypes, useModal } from 'models/modal';
import styles from './index.module.css';

interface ClassName {
	modal?: string;
	wrapper?: string;
	closeBtn?: string;
	title?: string;
	backdrop?: string;
}

interface ModalProperty {
	className?: ClassName;
	children?: React.ReactNode;
	title?: string;
	onClose?: () => void;
	showCloseButton?: boolean;
	noBackdrop?: boolean;
	type: ModalTypes;
	cartCalculate?: number;
}

const ModalComponent: React.FC<ModalProperty> = ({
	title = '',
	children,
	showCloseButton = false,
	onClose = () => {},
	type,
	className,
	noBackdrop = false,
	cartCalculate,
}) => {
	const [{ type: currentType }, { closeModal }] = useModal();
	const [isScrollTop, setIsScrollTop] = useState<boolean>(true);

	// 非打開狀態就不 render 底下的 modal，直接 return
	if (!currentType.includes(type)) {
		return null;
	}

	const onCloseClick = () => {
		closeModal();
		onClose();
	};

	const handleBackdropClick = () => {
		if (showCloseButton) {
			onCloseClick();
		}
	};

	const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	// e.currentTarget.scrollTop 會印出目前 scroll 的位置，當值 = 0 代表已經到 top
	// !e.currentTarget.scrollTop：若已經到 top 則為 true
	const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
		setIsScrollTop(e.currentTarget.scrollTop === 0);
	};

	return (
		<div
			className={classnames(styles.backdrop, className?.backdrop, {
				[styles.noBackdrop]: noBackdrop,
			})}
			onClick={handleBackdropClick}
			onKeyDown={() => {}}
			role="button"
			tabIndex={-1}
		>
			<div
				className={classnames(styles.modal, className?.modal)}
				onClick={handleModalClick}
				onKeyDown={() => {}}
				role="button"
				tabIndex={-1}
			>
				<div
					className={classnames(styles.wrapper, className?.wrapper, {
						[styles.fixed]: !isScrollTop && title,
					})}
					onScroll={handleScroll}
				>
					{title && (
						<h2 className={classnames(styles.title, className?.title)}>
							{title}{' '}
							{title === '購物車' && <span>( {formatWithThousandsSeparator(cartCalculate)} )</span>}
						</h2>
					)}
					{children}
				</div>
				{showCloseButton && (
					<button
						type="button"
						className={classnames(styles.closeButton, className?.closeBtn)}
						onClick={onCloseClick}
					>
						<CloseIcon />
					</button>
				)}
			</div>
		</div>
	);
};

const Modal: React.FC<ModalProperty> = ({
	title,
	children,
	showCloseButton,
	onClose,
	type,
	className,
	noBackdrop,
	cartCalculate,
}) => {
	const refDiv = useRef(document.createElement('div'));

	useLayoutEffect(() => {
		let modalRoot = document.getElementById('modal-root');

		if (modalRoot === null) {
			modalRoot = document.createElement('div');
			modalRoot.setAttribute('id', 'modal-root');
			document.body.appendChild(modalRoot);
		}

		modalRoot.appendChild(refDiv.current);

		return () => {
			if (modalRoot) {
				modalRoot.removeChild(refDiv.current);
			}
		};
	}, []);

	return createPortal(
		<ModalComponent
			title={title}
			showCloseButton={showCloseButton}
			onClose={onClose}
			type={type}
			className={className}
			noBackdrop={noBackdrop}
			cartCalculate={cartCalculate}
		>
			{children}
		</ModalComponent>,
		refDiv.current,
	);
};

export default Modal;
