import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { clearUserOrderDetail, getUserOrderDetailById } from 'models/order';
import { getUserInfo } from 'models/member';

const routes: CustomRoute = {
	path: '/member-order-detail',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '/:id',
			components: () => [import(/* webpackChunkName: 'member-order-detail' */ './component')],
			render: ([MemberOrderDetail]) => <MemberOrderDetail />,
			onEnter: async ({ next, store, params }) => {
				const { id } = params;
				await store.dispatch(clearUserOrderDetail());
				await store.dispatch(getUserInfo());
				await store.dispatch(getUserOrderDetailById(parseInt(id as string, 10)));
				const children = await next();				
				return children;
			},
		},
	],
};
export default routes;
