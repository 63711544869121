import React from 'react';
import classnames from 'classnames';
import RemindIcon from 'images/icon/text-remind.inline.svg';
import RemindWarningIcon from 'images/icon/remind-warning.inline.svg';

import styles from './index.module.css';

interface RemindTextProperty {
	text: string;
	className?: string;
	color?: string;
	icon?: string;
}

const TextRemind: React.FC<RemindTextProperty> = ({ text, className, color, icon }) => (
	<div
		className={classnames(
			styles.remindText,
			styles[color!],
			icon === 'remind-warning' && styles.remindWarning,
			className,
		)}
	>
		<span>{icon === 'remind-warning' ? <RemindWarningIcon /> : <RemindIcon />}</span>
		<p>{text}</p>
	</div>
);

export default TextRemind;
