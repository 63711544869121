import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { getProductById } from 'models/products';
import {
	getFreeShippingEventList,
	getThresholdRewardsList,
	getDiscountEvents,
} from 'models/discountEvent';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ store, next, params }) => {
		// const query = store.getState().routing.search;
		const id = Number(params.id);
		await store.dispatch(getProductById(id));
		store.dispatch(getFreeShippingEventList(Number(params.id)));
		store.dispatch(getThresholdRewardsList());
		store.dispatch(getDiscountEvents(Number(params.id)));

		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'productsInner' */ './component')],
			render: ([ProductsInner]) => <ProductsInner />,
			onEnter: async ({ next }) => {
				const children = await next();
				return children;
			},
		},
	],
};
export default routes;
