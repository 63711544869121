import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { ModalTypes, useModal } from 'models/modal';
import UserIcon from 'images/icon/user.inline.svg';
import styles from './index.module.css';

type DropdownProps = {
	useCustomContainer?: boolean; // Now correctly utilized
	children: React.ReactNode;
	isActive?: boolean;
	setFeatureMap?: React.Dispatch<React.SetStateAction<{ isPanelOpen: boolean }>>;
	isPanelOpen?: boolean;
};

const Dropdown: React.FC<DropdownProps> = ({
	useCustomContainer = false,
	children,
	isActive,
	setFeatureMap,
	isPanelOpen,
}) => {
	const [{ type }, { closeModal }] = useModal();
	const [isOpen, setIsOpen] = useState(false);
	const containerClass = useCustomContainer ? styles.calendarContainer : styles.container;

	const toggleDropdown = () => {
		if (isPanelOpen && setFeatureMap) {
			setFeatureMap(pre => ({ ...pre, isPanelOpen: !pre.isPanelOpen }));
		} else if (type[1] === ModalTypes.ModalSideCart) {
			closeModal(ModalTypes.ModalSideCart);
		}
		setIsOpen(!isOpen);
	};

	// Close dropdown function
	const closeDropdown = () => setIsOpen(false);

	useEffect(() => {
		// Close dropdown if the panel is open or the current modal is ModalSideCart
		if (isPanelOpen || type[1] === ModalTypes.ModalSideCart) {
			setIsOpen(false);
		}
	}, [isPanelOpen, type]);

	return (
		<button type="button" className={styles.dropdownWrapper} onClick={toggleDropdown}>
			<p
				className={classnames(styles.dropdownLink, {
					[styles.isOpen]: isOpen,
					[styles.isActive]: isActive,
				})}
			>
				<UserIcon />
			</p>

			{isOpen && (
				<button type="button" className={containerClass} onClick={e => e.stopPropagation()}>
					{React.Children.map(children, child => {
						if (!React.isValidElement(child)) {
							return child;
						}

						const originalOnClick = child.props.onClick;
						const enhancedOnClick = (e: React.MouseEvent) => {
							e.stopPropagation();
							if (originalOnClick) {
								originalOnClick(e);
							}
							closeDropdown();
						};

						// Explicitly specify the Attributes type parameter
						return React.cloneElement(child as React.ReactElement<any>, {
							onClick: enhancedOnClick,
						});
					})}
				</button>
			)}
		</button>
	);
};

export const DropdownNavMobile: React.FC<{
	children: React.ReactNode;
	isActive?: boolean;
	setFeatureMap?: React.Dispatch<React.SetStateAction<{ isPanelOpen: boolean }>>;
	isPanelOpen?: boolean;
}> = ({ children, isActive, setFeatureMap, isPanelOpen }) => (
	<Dropdown isActive={isActive} setFeatureMap={setFeatureMap} isPanelOpen={isPanelOpen}>
		{children}
	</Dropdown>
);
