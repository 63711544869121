// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isExist = (value: any) =>
	value !== null && value !== '' && typeof value !== 'undefined';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (value: any) => !isExist(value);

export const sleep = (time: number) =>
	new Promise<void>(resolve => setTimeout(() => resolve(), time));

export const lazyExecute = <I, O>(callBack: (i?: I) => O) => {
	if (isExist(callBack)) {
		setTimeout(() => {
			callBack();
		}, 100);
	}
};

export function getParentNode<T extends HTMLElement>(node: HTMLElement): T {
	return node.parentNode as T;
}

export const focusInChildren: (
	relatedTarget: EventTarget,
	currentTarget: EventTarget,
	// eslint-disable-next-line @typescript-eslint/ban-types
) => {} | boolean = (relatedTarget: EventTarget, currentTarget: EventTarget) => {
	if (relatedTarget === null) {
		return false;
	}

	if (relatedTarget === currentTarget) {
		return true;
	}

	const targetParent = getParentNode(relatedTarget as HTMLElement);

	return focusInChildren(targetParent, currentTarget);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = (data: any, key: string) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data.reduce((rv: any, x: any) => {
		// eslint-disable-next-line no-param-reassign
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});

export const validatePhone = (value: string) => {
	const phoneRegex = /^(09)[0-9]{8}$/;
	return phoneRegex.test(value);
};

export const validateEmail = (value: string) => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	return emailRegex.test(value);
};

export const validatePassword = (value: string) => {
	const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,20}$/;
	return passwordRegex.test(value);
};

export const validateNumber = (value: string) => {
	const NumberRegex = /^\d+$/;
	return NumberRegex.test(value);
};

export const validateBirthday = (value: string) => {
	const NumberRegex =
		/^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$|^\d{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])$/;
	return NumberRegex.test(value);
};

export const handlePurposeValue = (
	purposeValue: [{ value: string }],
	otherPurpose: { value: string },
) => {
	const otherItem = purposeValue.find(item => item.value === '其他');
	const filteredPurpose = purposeValue.filter(item => item.value !== '其他');

	if (!Array.isArray(purposeValue)) return null;
	return filteredPurpose
		.map(item => item.value)
		.concat(otherItem ? [`${otherItem.value}：${otherPurpose.value}`] : [])
		.join('、');
};

export const formatNumberWithCommas = (number: number) =>
	number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const generateHash = (str: string): number => {
	let hash = 0;
	Array.from(str).forEach(char => {
		hash += char.charCodeAt(0);
	});
	return hash;
};

export const splitDateTime = (dateTimeString?: string): { date: string; time: string } => {
	if (!dateTimeString) {
		return { date: '', time: '' };
	}

	const [date, time] = dateTimeString.split(' ');
	return { date, time };
};

export const formateDate = (date: Date) => {
	const year = date.getFullYear();
	const month =
		(date.getMonth() + 1).toString().length < 2 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
	const day = date.getDate().toString().length < 2 ? `0${date.getDate()}` : date.getDate();
	return `${year}/${month}/${day}`;
};

export const formateStringToDate = (dateString: string): Date => {
	const year = parseInt(dateString.split('/')[0], 10);
	const month = parseInt(dateString.split('/')[1], 10);
	const day = parseInt(dateString.split('/')[2], 10);

	return new Date(year, month - 1, day);
};

export const isObjectEmpty = (obj: object) =>
	Object.keys(obj).length === 0 && obj.constructor === Object;
