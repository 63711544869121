// Correcting the usage of useCustomContainer prop in DropdownNav and CalendarDropdown components

import React from 'react';
import classnames from 'classnames';
import ChevronDownIcon from 'images/icon/chevron-down.inline.svg';
import UserIcon from 'images/icon/user.inline.svg';
import styles from './index.module.css';

type DropdownProps = {
	triggerText?: string;
	iconType?: 'chevron' | 'user';
	useCustomContainer?: boolean; // Now correctly utilized
	children: React.ReactNode;
	isActive?: boolean;
	className?: string;
};

const Dropdown: React.FC<DropdownProps> = ({
	triggerText,
	iconType,
	useCustomContainer = false, // Default value is false
	children,
	isActive,
	className,
}) => {
	const containerClass = useCustomContainer ? styles.calendarContainer : styles.container;

	return (
		<li className={classnames(styles.dropdownWrapper, className)}>
			{triggerText && (
				<p className={classnames(styles.dropdownLink, { [styles.isActive]: isActive })}>
					{triggerText}
					{iconType === 'chevron' && (
						<span className={classnames(styles.icon, { [styles.rotate]: iconType === 'chevron' })}>
							<ChevronDownIcon />
						</span>
					)}
				</p>
			)}
			{iconType === 'user' && !triggerText && (
				<p className={classnames(styles.dropdownLink, { [styles.isActive]: isActive })}>
					<UserIcon />
				</p>
			)}
			<div className={containerClass}>{children}</div>
		</li>
	);
};

export const DropdownNav: React.FC<DropdownProps> = ({
	triggerText,
	iconType,
	useCustomContainer,
	children,
	isActive,
	className,
}) => (
	<Dropdown
		triggerText={triggerText}
		iconType={iconType}
		useCustomContainer={useCustomContainer}
		isActive={isActive}
		className={className}
	>
		{children}
	</Dropdown>
);

export const CalendarDropdown: React.FC<Omit<DropdownProps, 'useCustomContainer'>> = ({
	triggerText,
	iconType,
	children,
}) => (
	<Dropdown triggerText={triggerText} iconType={iconType} useCustomContainer>
		{children}
	</Dropdown>
);

export const IconDropdown: React.FC<{
	children: React.ReactNode;
	isActive?: boolean;
}> = ({ children, isActive }) => (
	<Dropdown iconType="user" isActive={isActive}>
		{children}
	</Dropdown>
);
