import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/forget-password',
	components: () => [import(/* webpackChunkName: 'ForgetPassword' */ './component')],
	render: ([ForgetPassword]) => <ForgetPassword />,
};

export default routes;
