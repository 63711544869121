/* eslint-disable react/button-has-type */
import React from 'react';
import classnames from 'classnames';
import CartRedIcon from 'images/icon/shopping-cart-red.inline.svg';
import Plus from 'images/icon/counting-plus.inline.svg';
import BagIcon from 'images/icon/bag.inline.svg';
import PhoneIcon from 'images/icon/phone-sign-up.inline.svg';
import ArrowRightIcon from 'images/icon/arrow-right.inline.svg';
import LockIcon from 'images/icon/lock.inline.svg';
import TruckIcon from 'images/icon/truck.inline.svg';
// import Loading from 'images/icon/loading.inline.svg';

import styles from './index.module.css';

interface ButtonProperty {
	size?: 'small' | 'medium' | 'large';
	pattern?: 'fill' | 'primaryOutline' | 'secondaryOutline';
	name: string;
	disabled?: boolean;
	handleClick: () => void;
	Icon?: 'plus' | 'cart' | string;
	iconPosition?: 'left' | 'right';
	className?: string;
	type?: 'submit';
	loading?: boolean;
	isSignInDisabled?: boolean;
}

const Button: React.FC<ButtonProperty> = ({
	size = 'medium',
	pattern = 'primaryOutline',
	name,
	handleClick = () => {},
	Icon,
	iconPosition = 'right',
	disabled,
	className,
	type,
	loading = false,
	isSignInDisabled = false,
}) => {
	const buttonStyleClass = disabled ? styles.disabled : styles[pattern];
	const signInDisabled = disabled && isSignInDisabled && styles.signInDisabled;

	let IconComponent;

	switch (Icon) {
		case 'plus':
			IconComponent = Plus;
			break;
		case 'cart':
			IconComponent = CartRedIcon;
			break;
		case 'bag':
			IconComponent = BagIcon;
			break;
		case 'phone':
			IconComponent = PhoneIcon;
			break;
		case 'arrowRight':
			IconComponent = ArrowRightIcon;
			break;
		case 'lock':
			IconComponent = LockIcon;
			break;
		case 'truck':
			IconComponent = TruckIcon;
			break;
		default:
			IconComponent = Icon;
	}
	return (
		<button
			type={type || 'button'}
			className={classnames(
				styles.button,
				styles[size],
				styles[iconPosition],
				buttonStyleClass,
				loading && styles.loading,
				className,
				signInDisabled,
			)}
			onClick={handleClick}
			disabled={disabled || loading}
		>
			{!loading && name}
			{IconComponent && <IconComponent />}
			{/* {loading && (
				<span style={{ height: '23px' }} className={styles.loadingSvg}>
					<Loading />
				</span>
			)} */}
		</button>
	);
};

export default Button;
