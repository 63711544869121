import React, { ReactNode, useState, useMemo, useEffect } from 'react';
import Link from 'components/atoms/Link';
import { DropdownNav, CalendarDropdown, IconDropdown } from 'components/atoms/DropdownNav';
import { DropdownNavMobile } from 'components/atoms/DropdownNavMobile';
import IconCart from 'components/atoms/IconCart';
import Calendar from 'components/atoms/Calendar';
import ModalSideCart from 'components/molecules/ModalSideCart';

import { useProductsForCart } from 'util/hook/useProducts';
import useMedia from 'util/hook/useMedia';
import { isExist } from 'util/helper';
import { ModalTypes, useModal } from 'models/modal';
import { useRouting, useHistory } from 'models/routing';
import { useMember } from 'models/member';
import { useSignin } from 'models/signin';
import { useProducts } from 'models/products';

import { MemberFeatureType } from 'enums/memberFeatureType';
import { PickupType } from 'enums/pickupType';

import ArrowUpRightIcon from 'images/icon/arrow-up-right.inline.svg';
import Tip from 'images/icon/tip.inline.svg';
import MenuIcon from 'images/icon/menu.inline.svg';
import CrossIcon from 'images/icon/cross.inline.svg';
import ChevronDownIcon from 'images/icon/chevron-down.inline.svg';
import ChevronUpIcon from 'images/icon/chevron-up.inline.svg';
import LogOutIcon from 'images/icon/log-out.inline.svg';

import { fixBackgroundScrollY, unfixBackgroundScrollY } from 'util/documentBackgroundScroll';

import classnames from 'classnames';
import styles from './index.module.css';

interface HeaderProperty {
	// eslint-disable-next-line react/no-unused-prop-types
	closeHeader?: () => void;
	className?: string;
	setFeatureMap?: React.Dispatch<React.SetStateAction<{ isPanelOpen: boolean }>>;
}

const MobileHeaderPanel: React.FC<HeaderProperty> = ({ className, setFeatureMap }) => {
	const [isOpen, setOpen] = useState(false);
	const [{ storePickupCalendar, homeDeliveryCalendar, loading }] = useProducts();
	const [pickupType, setPickupType] = useState<PickupType>(PickupType.HOME_DELIVERY);
	const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

	const maxDate = pickupType === PickupType.HOME_DELIVERY
		? homeDeliveryCalendar.end
		: storePickupCalendar.end;

	const unavailableDates = pickupType === PickupType.HOME_DELIVERY
		? homeDeliveryCalendar.unavailableDates
		: storePickupCalendar.unavailableDates;

	// 後端回傳的不可選購日期資料，如果出現任何問題，直接把日曆鎖住（避免使用者有機會選到不該選的日期）
	const isCalenderLocked = !isExist(maxDate) || !isExist(unavailableDates);

	useEffect(() => {
		const handleResize = () => {
			setViewportHeight(window.innerHeight);
		};

		window.addEventListener('resize', handleResize);
		window.addEventListener('orientationchange', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('orientationchange', handleResize);
		};
	}, []);

	return (
		<div className={classnames(styles.backdrop, className)}>
			<div
				className={classnames(styles.mobilePanel, className)}
				style={{ maxHeight: `calc(${viewportHeight}px - 72px)` }}
			>
				<Link
					to="/"
					className={styles.item}
					onClick={() =>
						setFeatureMap && setFeatureMap(pre => ({ ...pre, isPanelOpen: !pre.isPanelOpen }))
					}
				>
					<p>產品訂購</p>
				</Link>

				<div
					className={styles.dropdownItem}
					onClick={() => setOpen(!isOpen)}
					onKeyDown={() => {}}
					role="button"
					tabIndex={-1}
				>
					<div className={classnames(styles.title, { [styles.isOpen]: isOpen })}>
						<p>訂購須知</p>
						{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
					</div>
					{isOpen && (
						<div className={classnames(styles.child, { [styles.isOpen]: isOpen })}>
							<Link
								to="/shopping-guide"
								className={styles.link}
								onClick={() =>
									setFeatureMap && setFeatureMap(pre => ({ ...pre, isPanelOpen: !pre.isPanelOpen }))
								}
							>
								訂購說明
							</Link>
							<Link
								to="/faq"
								className={styles.link}
								onClick={() =>
									setFeatureMap && setFeatureMap(pre => ({ ...pre, isPanelOpen: !pre.isPanelOpen }))
								}
							>
								常見問題
							</Link>
						</div>
					)}
				</div>

				<div className={styles.calendarItem}>
					<div className={styles.title}>
						<button
							className={classnames({ [styles.isActive]: pickupType === PickupType.HOME_DELIVERY })}
							type="button"
							onClick={() => setPickupType(PickupType.HOME_DELIVERY)}
						>
							宅配日期
						</button>
						<button
							className={classnames({ [styles.isActive]: pickupType === PickupType.STORE_PICKUP })}
							type="button"
							onClick={() => setPickupType(PickupType.STORE_PICKUP)}
						>
							自取日期
						</button>
					</div>

					<div className={styles.child}>
						<Calendar
							isOpen
							openPanel={() => {}}
							closePanel={() => {}}
							onDateChange={() => {}}
							maxDate={isCalenderLocked ? new Date() : new Date(maxDate ?? '')}
							unavailableDates={isCalenderLocked ? [new Date()] : unavailableDates}
							monthsShown={1}
							MobileCalendarContainer={MobileCalendarContainer}
							loading={loading}
						/>
					</div>
				</div>

				<a href="https://oneonlybakery.com/" className={styles.item}>
					<p>回官網</p>
					<span>
						<ArrowUpRightIcon />
					</span>
				</a>
			</div>
		</div>
	);
};

interface MobileCalendarContainerProps {
	children: ReactNode;
}

const MobileCalendarContainer: React.FC<MobileCalendarContainerProps> = ({ children }) => (
	<div className={styles.calendarContainer}>{children}</div>
);

const Navigator: React.FC = () => {
	const media = useMedia();
	const [{ type }, { openModal, closeModal }] = useModal();
	const [{ ecUserToken }] = useMember();
	const [, { logout }] = useSignin();
	const [{ storePickupCalendar, homeDeliveryCalendar, loading }] = useProducts();
	const history = useHistory();
	const productsData = useProductsForCart();
	const [
		{
			routing: { pathname },
		},
	] = useRouting();

	const [{ isPanelOpen }, setFeatureMap] = useState({
		isPanelOpen: false,
	});

	const cartProductNum = useMemo(
		() =>
			productsData
				.filter(prod => prod.productType !== 'ADDON')
				.reduce((acc, cur) => acc + cur.count, 0),
		[productsData],
	);

	useEffect(() => {
		if (isPanelOpen) {
			fixBackgroundScrollY();
		} else {
			unfixBackgroundScrollY();
		}
		return () => {
			unfixBackgroundScrollY();
		};
	}, [isPanelOpen]);

	const isCalenderLocked = (maxDate?: string, unavailableDates?: string[]) => !isExist(maxDate) || !isExist(unavailableDates);

	return (
		<nav className={styles.nav}>
			<ModalSideCart title="購物車" />

			<ul>
				<li>
					<Link className={classnames({ [styles.isActive]: pathname === '/' })} to="/">
						產品訂購
					</Link>
				</li>
				<DropdownNav
					triggerText="訂購須知"
					iconType="chevron"
					isActive={pathname === '/faq' || pathname === '/shopping-guide'}
				>
					<Tip />
					<Link
						to="/shopping-guide"
						className={classnames(styles.dropdownLink, {
							[styles.isActive]: pathname === '/shopping-guide',
						})}
					>
						訂購說明
					</Link>
					<Link
						to="/faq"
						className={classnames(styles.dropdownLink, { [styles.isActive]: pathname === '/faq' })}
					>
						常見問題
					</Link>
				</DropdownNav>

				<CalendarDropdown triggerText="自取日期" iconType="user">
					<Tip />
					<Calendar
						isOpen
						openPanel={() => {}}
						closePanel={() => {}}
						onDateChange={() => {}}
						monthsShown={1}
						maxDate={
							isCalenderLocked(storePickupCalendar.end, storePickupCalendar.unavailableDates)
								? new Date()
								: new Date(storePickupCalendar.end ?? '')
						}
						unavailableDates={
							isCalenderLocked(storePickupCalendar.end, storePickupCalendar.unavailableDates)
								? [new Date()]
								: storePickupCalendar.unavailableDates
						}
						MobileCalendarContainer={MobileCalendarContainer}
						loading={loading}
					/>
				</CalendarDropdown>

				<CalendarDropdown triggerText="宅配日期" iconType="user">
					<Tip />
					<Calendar
						isOpen
						openPanel={() => {}}
						closePanel={() => {}}
						onDateChange={() => {}}
						monthsShown={1}
						maxDate={
							isCalenderLocked(homeDeliveryCalendar.end, homeDeliveryCalendar.unavailableDates)
								? new Date()
								: new Date(homeDeliveryCalendar.end ?? '')
						}
						unavailableDates={
							isCalenderLocked(homeDeliveryCalendar.end, homeDeliveryCalendar.unavailableDates)
								? [new Date()]
								: homeDeliveryCalendar.unavailableDates
						}
						MobileCalendarContainer={MobileCalendarContainer}
						loading={loading}
					/>
				</CalendarDropdown>

				<li>
					<a href="https://oneonlybakery.com/" className={styles.link}>
						回官網
						<span className={styles.icon}>
							<ArrowUpRightIcon />
						</span>
					</a>
				</li>
			</ul>

			<div className={styles.tools}>
				{media === 'desktop' && !ecUserToken && (
					<IconDropdown>
						<Tip />
						<Link to="/signin" className={classnames(styles.dropdownLink)}>
							登入
						</Link>
						<Link to="/signup" className={classnames(styles.dropdownLink)}>
							註冊
						</Link>
					</IconDropdown>
				)}

				{media === 'desktop' && ecUserToken && (
					<IconDropdown>
						<Tip />

						<button
							type="button"
							onClick={() => {
								history.push({ pathname: '/member-centre', search: `type=${MemberFeatureType.ACCOUNT}` });
								window.scrollTo(0, 0);
							}}
							className={classnames(styles.dropdownLink)}
						>
							帳戶設定
						</button>
						<button
							type="button"
							onClick={() => {
								history.push({ pathname: '/member-centre', search: `type=${MemberFeatureType.ORDERS}` });
								window.scrollTo(0, 0);
							}}
							className={classnames(styles.dropdownLink)}
						>
							我的訂單
						</button>
						<button
							type="button"
							onClick={() => {
								history.push({ pathname: '/member-centre', search: `type=${MemberFeatureType.COUPONS}` });
								window.scrollTo(0, 0);
							}}
							className={classnames(styles.dropdownLink)}
						>
							我的優惠券
						</button>

						<div className={styles.divider} />

						<button
							type="button"
							onClick={logout}
							className={classnames(styles.dropdownLink, styles.logout)}
						>
							<LogOutIcon />
							登出
						</button>
					</IconDropdown>
				)}

				{media !== 'desktop' && !ecUserToken && (
					<DropdownNavMobile setFeatureMap={setFeatureMap} isPanelOpen={isPanelOpen}>
						<Tip />
						<Link to="/signin" className={classnames(styles.dropdownLink)}>
							登入
						</Link>
						<Link to="/signup" className={classnames(styles.dropdownLink)}>
							註冊
						</Link>
					</DropdownNavMobile>
				)}

				{media !== 'desktop' && ecUserToken && (
					<DropdownNavMobile setFeatureMap={setFeatureMap} isPanelOpen={isPanelOpen}>
						<button
							type="button"
							onClick={() => {
								history.push({ pathname: '/member-centre', search: `type=${MemberFeatureType.ACCOUNT}` });
								window.scrollTo(0, 0);
							}}
							className={classnames(styles.dropdownLink)}
						>
							帳戶設定
						</button>
						<button
							type="button"
							onClick={() => {
								history.push({ pathname: '/member-centre', search: `type=${MemberFeatureType.ORDERS}` });
								window.scrollTo(0, 0);
							}}
							className={classnames(styles.dropdownLink)}
						>
							我的訂單
						</button>
						<button
							type="button"
							onClick={() => {
								history.push({ pathname: '/member-centre', search: `type=${MemberFeatureType.COUPONS}` });
								window.scrollTo(0, 0);
							}}
							className={classnames(styles.dropdownLink)}
						>
							我的優惠券
						</button>

						<div className={styles.divider} />

						<button
							type="button"
							onClick={logout}
							className={classnames(styles.dropdownLink, styles.logout)}
						>
							<LogOutIcon />
							登出
						</button>
					</DropdownNavMobile>
				)}

				<button
					type="button"
					className={styles.cart}
					onClick={() => {
						// 用於手機裝置時，點擊購物車按鈕時，關閉手機版的選單
						setFeatureMap(pre => ({ ...pre, isPanelOpen: false }));

						if (pathname === '/cart' || pathname === '/checkout') {
							history.push({ pathname: '/cart' });
						} else {
							openModal(ModalTypes.ModalSideCart);
						}
					}}
				>
					<span className={styles.icon}>
						<IconCart
							quantity={cartProductNum}
							isCartActive={type[1] === ModalTypes.ModalSideCart}
						/>
					</span>
				</button>

				<button
					className={styles.menu}
					type="button"
					onClick={() => {
						// 用於手機裝置時，點擊選單按鈕時，關閉購物車 Modal
						if (type[1] === ModalTypes.ModalSideCart) {
							closeModal(ModalTypes.ModalSideCart);
						}
						setFeatureMap(pre => ({ ...pre, isPanelOpen: !pre.isPanelOpen }));
					}}
				>
					{isPanelOpen ? <CrossIcon /> : <MenuIcon />}
				</button>
			</div>

			{media === 'mobile' && isPanelOpen && <MobileHeaderPanel setFeatureMap={setFeatureMap} />}
		</nav>
	);
};

export default Navigator;
