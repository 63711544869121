import { combineReducers } from 'redux';

import * as routing from './routing';
import * as member from './member';
import * as banners from './banner';
import * as toast from './toast';
import * as modal from './modal';
import * as shoppingGuide from './shoppingGuide';
import * as products from './products';
import * as cart from './cart';
import * as signup from './signup';
import * as signin from './signin';
import * as thirdPartyAuth from './thirdPartyAuth';
import * as discountEvents from './discountEvent';
import * as coupon from './coupon';
import * as order from './order';

// For Global State interface
export interface State {
	banners: banners.State;
	member: member.State;
	routing: routing.State;
	toast: toast.State;
	modal: modal.State;
	shoppingGuide: shoppingGuide.State;
	products: products.State;
	cart: cart.State;
	signup: signup.State;
	signin: signin.State;
	thirdPartyAuth: thirdPartyAuth.State;
	discountEvents: discountEvents.State;
	coupon: coupon.State;
	order: order.State;
}

export type GetState = () => State;

export const defaultState: State = {
	banners: banners.defaultState,
	member: member.defaultState,
	routing: routing.defaultState,
	toast: toast.defaultState,
	modal: modal.defaultState,
	shoppingGuide: shoppingGuide.defaultState,
	products: products.defaultState,
	cart: cart.defaultState,
	signup: signup.defaultState,
	signin: signin.defaultState,
	thirdPartyAuth: thirdPartyAuth.defaultState,
	discountEvents: discountEvents.defaultState,
	coupon: coupon.defaultState,
	order: order.defaultState,
};

const reducers = combineReducers<State>({
	...routing.reducer,
	...member.reducer,
	...banners.reducer,
	...toast.reducer,
	...modal.reducer,
	...shoppingGuide.reducer,
	...products.reducer,
	...cart.reducer,
	...signup.reducer,
	...signin.reducer,
	...thirdPartyAuth.reducer,
	...discountEvents.reducer,
	...coupon.reducer,
	...order.reducer,
});

export default reducers;
