/* eslint-disable indent */
import { Dispatch } from 'redux';
import { handleActions, createAction, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { createSelector } from 'reselect';
import { GetState, State as GlobalState } from './reducers';

export type ToastType = 'error' | 'warn' | 'success' | 'delete';

export type ToastColor = 'green' | 'purple' | 'red' | 'warning';

export interface ToastInfo {
	id?: string;
	type: ToastType;
	color: ToastColor;
	message: string;
}

export interface State {
	toastList: ToastInfo[];
}

export const defaultState: State = {
	toastList: [],
};

export const toast = createAction<State, ToastInfo>('TOAST', params => ({
	toastList: [params],
}));

export const popToast = createAction('POP_TOAST', () => (_: Dispatch, getState: GetState) => {
	const {
		toast: { toastList },
	} = getState();
	return {
		toastList: toastList.slice(0, toastList.length - 1),
	};
});

export const cleanToastById = createAction<
	(dispatch: Dispatch, getState: GetState) => State,
	string
>('CLEAN_TOAST_BY_ID', id => (_: Dispatch, getState: GetState) => {
	const {
		toast: { toastList },
	} = getState();
	return {
		toastList: toastList.filter(toastData => toastData.id !== id),
	};
});

export const cleanAllToast = createAction('CLEAN_ALL_TOAST');

export const reducer = {
	toast: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			TOAST: (state, action: Action<State>) => ({
				...state,
				toastList: action.payload.toastList,
			}),
			POP_TOAST: (state, action: Action<State>) => ({
				...state,
				toastList: action.payload.toastList,
			}),
			CLEAN_TOAST_BY_ID: (state, action: Action<State>) => ({
				...state,
				toastList: action.payload.toastList,
			}),
			CLEAN_ALL_TOAST: state => ({
				...state,
				toastList: [],
			}),
		},
		defaultState,
	),
};

const toastActionMap = {
	toast,
	popToast,
	cleanToastById,
	cleanAllToast,
};

const mapHooksToState = createSelector(
	(state: GlobalState) => state.toast.toastList,
	toastList => ({
		toastList,
	}),
);

type ToastSelector = ReturnType<typeof mapHooksToState>;
type ToastActionsMap = typeof toastActionMap;

export const useToast = () =>
	useRedux<ToastSelector, ToastActionsMap>(mapHooksToState, toastActionMap);
