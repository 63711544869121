import React from 'react';
import history from 'store/history';

import { setCartData, getShippingImages } from 'models/cart';
import { getAddonProducts } from 'models/products';
import { getUserInfo } from 'models/member';
import { getUserCouponList } from 'models/coupon';

import { CustomRoute } from 'util/hook/useRouter';
import { getEcUserByStorage } from 'util/storage';
import pushHistory from 'util/pushHistory';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/cart',
	components: () => [import(/* webpackChunkName: 'cart' */ './component')],
	render: ([Cart]) => <Cart />,
	onEnter: async ({ next, store }) => {
		const ecUserToken = getEcUserByStorage();

		if (!ecUserToken) {
			pushHistory(history, `/signin?redirect=/cart`);
			window.scrollTo(0, 0);
			return null;
		}
		await store.dispatch(setCartData());
		await store.dispatch(getUserInfo());
		await store.dispatch(getShippingImages());
		await store.dispatch(getAddonProducts());
		await store.dispatch(getUserCouponList({ status: 'RECEIVED' }));

		const children = await next();
		return children;
	},
};
export default routes;
