/**
 * 發票類型之列舉
 *
 * @export
 * @enum {number}
 */
export enum InvoiceType {
	/**
	 * 個人 - 電子發票
	 */
	PERSON = 'E_INVOICE',

	/**
	 * 個人 - 手機載具
	 */
	ELECTRONIC = 'CARRIER',

	/**
	 * 公司發票
	 */
	COMPANY = 'COMPANY',
}
