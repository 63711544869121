/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';
import history from 'store/history';
import { nanoid } from 'nanoid';
import { createSelector } from 'reselect';
// import { ROUTE_PATHS } from 'routes';

import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import {
	setItem,
	getItem,
	removeItem,
	removeEcUserByStorage,
	setEcUserByStorage,
} from 'util/storage';
import { parseQueryString } from 'util/parseQueryString';
// import { gtmEvent } from 'util/gtmEvent';

import { toast } from 'models/toast';

import { StorageKey } from 'enums/storageKey';
import { ERROR_CODE } from 'enums/form';
import { StorageType } from 'enums/storageType';

// import { ApiError, BaseErrorExtra, BasePayload } from 'types/apiError';
import pushHistory from 'util/pushHistory';
import { GetState, State as GlobalState } from './reducers';
import { clearUserInfo, setEcUserToken } from './member';

// import { setErrMessageEmpty, setSelectCoupon } from './coupon';

interface BasePayload {
	status: number;
	errorCode?: string | null;
	message?: undefined | string | null;
}

interface Error {
	error: BasePayload;
}

interface NormalSigninResponse {
	token: string;
}

interface VerifyResetPasswordEmailCodeResponse {
	key: string;
}

interface SigninSuccessPayload {
	token: string;
	message: string;
	method: 'normal' | 'line';
}

interface normalSignupPayload {
	password: string;
	secondPassword: string;
}

interface resetMobilePayload {
	verifyCode: string;
	account: string;
}

/**
 * 登入後需要執行事項的介面
 *
 * 登入一般情況下會回到首頁，但特殊情境下會到指定頁面，此時需要紀錄返回的路徑
 *
 * 特殊情境目前有
 * - 點擊首頁優惠券，若是沒登入要導至登入頁，登入成功後回到會員中心 - 我的優惠券
 * - 領取優惠券頁面，若是沒登入要導至登入頁，登入成功後回到會員中心 - 我的優惠券，並且協助領取優惠券
 * - 點擊常見問答的訂單列表，若是沒登入要導至登入頁，登入成功後回到會員中心 - 訂單列表
 *
 * @export
 * @interface SigninSuccessCallbackInfo
 */
export interface SigninSuccessCallbackInfo {
	backPath: string;
}

export const normalSignin = createAction(
	'NORMAL_SIGNIN',
	(mobile: string, password: string) => async (dispatch: Dispatch<any>) => {
		try {
			const { v1AuthLoginCreate } = api;
			const { status, data } = await v1AuthLoginCreate({ mobile, password });
			// 成功：更新本地 token
			if (status === 200) {
				const { token } = data?.data as NormalSigninResponse;
				dispatch(
					signInSuccessCallBack({
						token,
						message: '登入成功！',
						method: 'normal',
					}),
				);
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const checkResetPasswordUserEmail = createAction(
	'CHECK_RESET_PASSWORD_USER_EMAIL',
	(email: string) => async () => {
		try {
			const { v1AuthCheckUserEmailCreate } = api;
			const { status } = await v1AuthCheckUserEmailCreate({ email });
			// 成功：更新本地 token
			if (status === 200) {
				const redirectPath = `email-verify?email=${encodeURIComponent(
					email,
				)}&lastStep=true&type=resetPassword`;
				window.location.assign(redirectPath);
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

// export const sendResetPasswordVerificationEmail = createAction(
// 	'SEND_RESET_PASSWORD_VERIFICATION_EMAIL',
// 	(email: string) => async (dispatch: Dispatch<any>) => {
// 		try {
// 			const { v1AuthSendResetPasswordVerificationEmailCreate } = api;
// 			const { status } = await v1AuthSendResetPasswordVerificationEmailCreate({ email });
// 			if (status === 200) {
// 				const id = nanoid();
// 				dispatch(
// 					toast({
// 						id,
// 						message: '已發送驗證信件',
// 						type: 'success',
// 						color: 'purple',
// 					}),
// 				);
// 			}
// 			return { status };
// 		} catch (e) {
// 			return { status: (e as ApiError).error.status, errorCode: (e as ApiError).error.errorCode };
// 		}
// 	},
// );

// export const verifyResetPasswordEmailCode = createAction(
// 	'VERIFY_RESET_PASSWORD_EMAIL_CODE',
// 	(email: string, code: string) => async () => {
// 		try {
// 			const { v1AuthVerifyEmailCodeCreate } = api;
// 			const { status, data } = await v1AuthVerifyEmailCodeCreate({
// 				email,
// 				code,
// 				use_for: 'resetPassword',
// 			});
// 			if (status === 200) {
// 				// 將 key 與 Email 存在 LocalStoage 後前往重設密碼
// 				const resetPasswordObject = {
// 					key: (data?.data as VerifyResetPasswordEmailCodeResponse).key,
// 					email,
// 				};
// 				setItem(StorageKey.RESET_PASSWORD, JSON.stringify(resetPasswordObject));
// 				window.location.href = '/reset-password';
// 			}
// 			return { status };
// 		} catch (e) {
// 			return { status: (e as ApiError).error.status, errorCode: (e as ApiError).error.errorCode };
// 		}
// 	},
// );

export const resetPassword = createAction(
	'RESET_PASSWORD',
	(formData: normalSignupPayload) => async () => {
		try {
			const { key } = parseQueryString();
			const { v1AuthResetPasswordCreate } = api;
			const { password, secondPassword } = formData;
			const { status } = await v1AuthResetPasswordCreate({
				confirm_password: secondPassword,
				password,
				key,
			});
			if (status === 200) {
				const redirectPath = `/signin?resetPasswordSuccess=true`;
				window.location.href = redirectPath;
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const resetMobile = createAction(
	'RESET_MOBILE',
	(formData: resetMobilePayload) => async () => {
		try {
			const { key } = parseQueryString();
			const { v1AuthResetMobileCreate } = api;
			const { verifyCode, account } = formData;
			const { status } = await v1AuthResetMobileCreate({
				new_mobile: account,
				code: verifyCode,
				key,
			});
			if (status === 200) {
				const redirectPath = `/signin?resetMobileSuccess=true`;
				window.location.href = redirectPath;
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const logout = createAction('LOGOUT', () => async (dispatch: Dispatch<any>) => {
	try {
		const { v1AuthLogoutCreate } = api;
		await v1AuthLogoutCreate();
	} catch (e) {
		console.log(e);
	}

	const id = nanoid();
	dispatch(
		toast({
			id,
			message: '登出成功',
			type: 'success',
			color: 'green',
		}),
	);

	dispatch(setEcUserToken(''));
	dispatch(clearUserInfo());
	// dispatch(setSelectCoupon({}));
	// dispatch(setErrMessageEmpty());
	removeEcUserByStorage();
	pushHistory(history, '/');
	window.scrollTo(0, 0);
});

/**
 * 登入成功之後要做的事情，目前僅有儲存 token 後返回首頁
 */
export const signInSuccessCallBack = createAction(
	'SIGN_IN_SUCCESS_CALLBACK',
	(params: SigninSuccessPayload) => async (dispatch: Dispatch, getState: GetState) => {
		const { token, message } = params;
		const { redirect } = parseQueryString();

		if (message) {
			const id = nanoid();
			dispatch(
				toast({
					id,
					message,
					type: 'success',
					color: 'green',
				}),
			);
		}

		let path = '/';
		if (redirect) {
			path = decodeURIComponent(redirect);
		}

		const storageData = getItem(StorageKey.SIGNIN_CALLBACK_INFO);

		if (storageData) {
			const signinSuccessInfo = JSON.parse(storageData) as SigninSuccessCallbackInfo;
			path = signinSuccessInfo.backPath;
		}

		removeItem(StorageKey.SIGNIN_CALLBACK_INFO);
		setEcUserByStorage(token);
		dispatch(setEcUserToken(token));
		// await dispatch(getUserInfo());

		// const {
		// 	member: {
		// 		userInfo: { id: userId },
		// 	},
		// } = getState();

		pushHistory(history, path);
		window.scrollTo(0, 0);
	},
);
export interface ApiError {
	error: BasePayload;
}

export const checkErrorStatus = createAction('CHECK_ERROR_STATUS', async (e: ApiError) => {
	const {
		error: { status },
	} = e;

	let newPathname = window.location.pathname;
	if (newPathname === '/checkout') {
		newPathname = '/cart';
	}

	switch (status) {
		case 401:
			removeEcUserByStorage();
			setEcUserToken('');
			pushHistory(history, `/signin?redirect=${newPathname}`);
			window.scrollTo(0, 0);
			break;

		default:
			break;
	}
});

export interface State {
	loading: boolean;
	normalSigninStatus: BasePayload;
	checkResetPasswordUserEmailStatus: BasePayload;
	resetPasswordVerificationEmailStatus: BasePayload;
	verifyResetPasswordEmailCodeStatus: BasePayload;
	resetPasswordStatus: BasePayload;
	resetMobileStatus: BasePayload;
}

export const defaultState: State = {
	loading: false,
	normalSigninStatus: {
		status: 0,
	},
	checkResetPasswordUserEmailStatus: {
		status: 0,
	},
	resetPasswordVerificationEmailStatus: {
		status: 0,
	},
	verifyResetPasswordEmailCodeStatus: {
		status: 0,
	},
	resetPasswordStatus: {
		status: 0,
	},
	resetMobileStatus: {
		status: 0,
	},
};

export const reducer = {
	signin: handleActions<State, any>(
		{
			NORMAL_SIGNIN_PENDING: state => ({
				...state,
				loading: true,
			}),
			NORMAL_SIGNIN_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				normalSigninStatus: action.payload,
				loading: false,
			}),
			NORMAL_SIGNIN_REJECTED: state => ({
				...state,
				loading: false,
			}),
			CHECK_RESET_PASSWORD_USER_EMAIL_PENDING: state => ({
				...state,
				loading: true,
			}),
			CHECK_RESET_PASSWORD_USER_EMAIL_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				checkResetPasswordUserEmailStatus: action.payload,
				loading: false,
			}),
			CHECK_RESET_PASSWORD_USER_EMAIL_REJECTED: state => ({
				...state,
				loading: false,
			}),
			SEND_RESET_PASSWORD_VERIFICATION_EMAIL_PENDING: state => ({
				...state,
				loading: true,
			}),
			SEND_RESET_PASSWORD_VERIFICATION_EMAIL_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				sendResetPasswordVerificationEmailStatus: action.payload,
				loading: false,
			}),
			SEND_RESET_PASSWORD_VERIFICATION_EMAIL_REJECTED: state => ({
				...state,
				loading: false,
			}),
			VERIFY_RESET_PASSWORD_EMAIL_CODE_PENDING: state => ({
				...state,
				loading: true,
			}),
			VERIFY_RESET_PASSWORD_EMAIL_CODE_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				verifyResetPasswordEmailCodeStatus: action.payload,
				loading: false,
			}),
			VERIFY_RESET_PASSWORD_EMAIL_CODE_REJECTED: state => ({
				...state,
				loading: false,
			}),
			RESET_PASSWORD_PENDING: state => ({
				...state,
				loading: true,
			}),
			RESET_PASSWORD_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				resetPasswordStatus: action.payload,
				loading: false,
			}),
			RESET_PASSWORD_REJECTED: state => ({
				...state,
				loading: false,
			}),
			RESET_MOBILE_PENDING: state => ({
				...state,
				loading: true,
			}),
			RESET_MOBILE_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				resetMobileStatus: action.payload,
				loading: false,
			}),
			RESET_MOBILE_REJECTED: state => ({
				...state,
				loading: false,
			}),
		},
		defaultState,
	),
};

const signinActionsMap = {
	normalSignin,
	checkResetPasswordUserEmail,
	// sendResetPasswordVerificationEmail,
	// verifyResetPasswordEmailCode,
	resetPassword,
	resetMobile,
	logout,
	// signInSuccessCallBack,
	checkErrorStatus,
};

const normalSigninStatusSelector = (state: GlobalState) => state.signin.normalSigninStatus;
const checkResetPasswordUserEmailStatusSelector = (state: GlobalState) =>
	state.signin.checkResetPasswordUserEmailStatus;
const sendResetPasswordVerificationEmailStatusSelector = (state: GlobalState) =>
	state.signin.resetPasswordVerificationEmailStatus;
const verifyResetPasswordEmailCodeStatusSelector = (state: GlobalState) =>
	state.signin.verifyResetPasswordEmailCodeStatus;
const resetPasswordStatusSelector = (state: GlobalState) => state.signin.resetPasswordStatus;
const resetMobileStatusSelector = (state: GlobalState) => state.signin.resetMobileStatus;
const loadingSelector = (state: GlobalState) => state.signin.loading;

// Memoized selector using createSelector
const mapHooksToState = createSelector(
	[
		normalSigninStatusSelector,
		checkResetPasswordUserEmailStatusSelector,
		sendResetPasswordVerificationEmailStatusSelector,
		verifyResetPasswordEmailCodeStatusSelector,
		resetPasswordStatusSelector,
		resetMobileStatusSelector,
		loadingSelector,
	],
	(
		normalSigninStatus,
		checkResetPasswordUserEmailStatus,
		sendResetPasswordVerificationEmailStatus,
		verifyResetPasswordEmailCodeStatus,
		resetPasswordStatus,
		resetMobileStatus,
		loading,
	) => ({
		normalSigninStatus,
		checkResetPasswordUserEmailStatus,
		sendResetPasswordVerificationEmailStatus,
		verifyResetPasswordEmailCodeStatus,
		resetPasswordStatus,
		resetMobileStatus,
		loading,
	}),
);

type SigninSelector = ReturnType<typeof mapHooksToState>;
type SigninActionsMap = typeof signinActionsMap;

export const useSignin = () =>
	useRedux<SigninSelector, SigninActionsMap>(mapHooksToState, signinActionsMap);
