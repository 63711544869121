import React from 'react';
import classnames from 'classnames';

import styles from './index.module.css';

export type LabelSize = 'xxs' | 'xsLight' | 'xs' | 'small' | 'large' | 'smallInPage';

export interface LabelCalendarProperty {
	text: string;
	backgroundColor?: string;
	size?: LabelSize;
	Icon?: string;
	textColor?: string;
	className?: string;
	dotColor?: string;
}

const LabelCalendar: React.FC<LabelCalendarProperty> = ({
	text,
	backgroundColor = '#fff',
	size = 'small',
	Icon,
	textColor,
	className,
	dotColor,
}) => (
	<div
		className={classnames(styles.productLabel, className, styles[size], !text && styles.empty)}
		style={{ backgroundColor }}
	>
		{Icon && <Icon />}
		<div className={styles.circle} style={{ backgroundColor: dotColor }} />
		<p style={{ color: textColor }}>{text}</p>
	</div>
);

export default LabelCalendar;
