/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch } from 'react';
import { createAction, handleActions, Action } from 'redux-actions';
import { nanoid } from 'nanoid';
import { useRedux } from 'util/hook/redux';
import { createSelector } from 'reselect';
import history from 'store/history';

import { toast } from 'models/toast';
import { ModalTypes, openModal } from 'models/modal';
import { signInSuccessCallBack } from 'models/signin';
import { sendVerificationEmail } from 'models/signup';
import { getUserInfo } from 'models/member';

import { api } from 'util/api';
// import { omniNewRegistration } from 'util/omniEvent';

import { ERROR_CODE, ERROR_MESSAGES } from 'enums/form';
import { StorageKey } from 'enums/storageKey';
import {
	V1AuthFacebookLinkCreateRequestPayload,
	UserResource,
} from 'util/api/swaggerApi/data-contracts';

// import { ROUTE_PATHS } from 'routes';
import pushHistory from 'util/pushHistory';
import { setItem, getItem, removeItem } from 'util/storage';
import { GetState, State as GlobalState } from './reducers';

import { ApiError } from './signin';

const { PROXY } = process.env;

interface BasePayload {
	status: number;
	errorCode?: string | null;
}

interface Error {
	error: BasePayload;
}

interface normalSignupPayload {
	name: string;
	email: string;
	password: string;
	account: string;
	verifyCode: string;
	secondPassword: string;
}

interface GetLineIdTokenResponse {
	idToken: string;
}

interface GetLineMemberInfoResponse {
	email?: string;
	name?: string;
	token?: string;
	socialiteToken?: string;
	userVerifiedStatus?: boolean;
}

interface BindLineFormDataPayload {
	name: string;
	email: string;
	password: string;
	account: string;
	verifyCode: string;
	secondPassword: string;
	mobile: string;
}

/**
 * Facebook 登入
 */
const facebookNativeLogin = () =>
	new Promise((resolve, reject) => {
		window.FB.login(
			(response: any) => {
				if (response.status === 'connected') {
					resolve(response);
				} else {
					console.log(response);
				}
			},
			{ scope: 'public_profile,email' },
		);
	});

/**
 * Facebook 綁定 / 註冊
 */
export const bindFacebook = createAction(
	'BIND_FACEBOOK',
	(formData: normalSignupPayload) =>
		async (dispatch: Dispatch<any>): Promise<object | null> => {
			try {
				const facebookBind = getItem(StorageKey.FACEBOOK_BIND);
				const socialiteToken = facebookBind ? JSON.parse(facebookBind).socialiteToken : null;
				const facebookEmail = facebookBind ? JSON.parse(facebookBind).email : null;

				const { name, email, account, verifyCode } = formData;

				const { v1AuthFacebookLinkCreate } = api;
				const { status, data } = await v1AuthFacebookLinkCreate({
					email,
					name,
					mobile: account,
					code: verifyCode,
					socialite_token: socialiteToken,
				});

				if (status === 200 && data?.data) {
					const { token } = data?.data as { token: string };

					if (facebookEmail === email) {
						pushHistory(history, `/?verify=success&token=${token}`);
						window.scrollTo(0, 0);
					} else {
						dispatch(sendVerificationEmail(email, 'REGISTRATION'));
						const encodedEmail = encodeURIComponent(email);
						window.location.href = `/email-verify?email=${encodedEmail}&type=REGISTRATION`;
					}
					removeItem(StorageKey.FACEBOOK_BIND);
				}
				return { status };
			} catch (e) {
				return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
			}
		},
);

/**
 * Facebook 註冊 / 登入，若已綁定則會直接登入
 */
export const facebook = createAction(
	'SIGNUP_FACEBOOK',
	() =>
		async (dispatch: Dispatch<any>): Promise<object | null> => {
			const auth: any = await facebookNativeLogin();

			const { accessToken } = auth.authResponse;

			try {
				const { v1AuthFacebookVerifyCreate } = api;
				const { status, data } = await v1AuthFacebookVerifyCreate({ token: accessToken });

				if (status === 200 && data?.data) {
					const { token } = data?.data as { name: string; token: string };

					// 如果有 token 就直接登入，沒有則跳轉到註冊頁面
					if (token) {
						dispatch(
							signInSuccessCallBack({
								token,
								message: '登入成功！',
								method: 'normal',
							}),
						);
					} else {
						setItem(StorageKey.FACEBOOK_BIND, JSON.stringify(data?.data));

						window.location.href = '/signup-facebook';
					}

					return data.data;
				}

				return null;
			} catch (error) {
				dispatch(checkThirdPartyAuthError(error as ApiError));
				return null;
			}
		},
);

export const checkThirdPartyAuthError = createAction(
	'CHECK_THIRD_PARTY_AUTH_ERROR',
	(e: ApiError) => async (dispatch: Dispatch<any>) => {
		const {
			error: { errorCode, message },
		} = e;

		if (errorCode === ERROR_CODE.EMAIL_EXISTED) {
			pushHistory(history, '/signin');
			window.scrollTo(0, 0);

			const id = nanoid();
			dispatch(
				toast({
					id,
					message: '您的電子信箱已被註冊，請登入會員。',
					type: 'success',
					color: 'green',
				}),
			);
		} else {
			dispatch(
				toast({
					id: nanoid(),
					message: message || '', // Add a default value of an empty string
					type: 'warn',
					color: 'warning',
				}),
			);
		}
	},
);

/**
 * 取得 Line Token
 */
export const getLineIdToken = createAction(
	'GET_LINE_ID_TOKEN',
	(code: string, type: 'register' | 'bind') => async (dispatch: Dispatch<any>) => {
		try {
			if (PROXY === 'develop') {
				dispatch(getLineMemberInfo(type));
				return { status: 0 };
			}

			const { v1AuthLineGetTokenCreate } = api;
			const { status, data } = await v1AuthLineGetTokenCreate({ code, type });

			if (status === 200) {
				// 將 id_token 存在 localStorage 中
				setItem(StorageKey.LINE_AUTH, (data?.data as GetLineIdTokenResponse).idToken);
				dispatch(getLineMemberInfo(type));
				return { status };
			}
			return { status };
		} catch (error) {
			return { status: (error as Error).error.status, errorCode: (error as Error).error.errorCode };
		}
	},
);

/**
 * Line 註冊 / 登入，傳入 id_token 來取得 Line 個人資訊，若 LineID 已綁定則會直接登入
 */
export const getLineMemberInfo = createAction(
	'GET_LINE_MEMBER_INFO',
	(type: 'register' | 'bind') => async (dispatch: Dispatch<any>, getState: GetState) => {
		const {
			member: { userInfo },
		} = getState();

		try {
			const idToken = getItem(StorageKey.LINE_AUTH);
			if (!idToken) {
				dispatch(openModal(ModalTypes.ErrorModal));
				return { status: 0 };
			}
			const { v1AuthLineVerifyCreate } = api;
			const { status, data } = await v1AuthLineVerifyCreate({
				id_token: idToken as string,
				...(type === 'bind' && { mobile: userInfo.mobile }),
			});

			if (status === 200 && data?.data) {
				const { token, socialiteToken } = data?.data as GetLineMemberInfoResponse;

				// 如果有 token 就直接登入，沒有則跳轉到註冊頁面
				if (token) {
					if (type === 'register') {
						dispatch(
							signInSuccessCallBack({
								token,
								message: '登入成功',
								method: 'line',
							}),
						);
					}
				}

				if (type === 'bind') {
					setItem(StorageKey.LINE_BIND, JSON.stringify(data?.data));
					dispatch(bindLine(userInfo as BindLineFormDataPayload, type));
				}

				// 無 access Token 情境:
				if (socialiteToken) {
					if (type === 'bind') return null; // 如果在會員專區頁面做綁定，則直接 return

					setItem(StorageKey.LINE_BIND, JSON.stringify(data?.data));
					window.location.href = '/signup-line';
				}
			}
			return { status };
		} catch (error) {
			const { errorCode } = (error as Error).error;
			if (errorCode === ERROR_CODE.LINE_ACCOUNT_ALREADY_BIND) {
				history.push({
					pathname: '/member-centre',
					search: 'type=1',
				});

				window.scrollTo(0, 0);
				const id = nanoid();

				dispatch(
					toast({
						id,
						message: ERROR_MESSAGES.LINE_ACCOUNT_ALREADY_BIND,
						type: 'warn',
						color: 'warning',
					}),
				);
			}

			return { status: (error as Error).error.status, errorCode: (error as Error).error.errorCode };
		}
	},
);

/**
 * 綁定 Line 帳號
 */
export const bindLine = createAction(
	'BIND_LINE',
	(formData: BindLineFormDataPayload, type: 'bind' | 'register') =>
		async (dispatch: Dispatch<any>) => {
			if (type !== 'bind' && type !== 'register') {
				return { status: 0 };
			}

			try {
				const lineBind = getItem(StorageKey.LINE_BIND);
				const socialiteToken = lineBind ? JSON.parse(lineBind).socialiteToken : null;
				const lineEmail = lineBind ? JSON.parse(lineBind).email : null;

				// 從註冊頁面來的，會帶入 formData 來綁定
				if (type === 'register') {
					const { name, email, account, verifyCode } = formData;
					const { v1AuthLineLinkCreate } = api;
					const { status, data } = await v1AuthLineLinkCreate({
						email,
						name,
						mobile: account,
						code: verifyCode,
						socialite_token: socialiteToken,
					});

					if (status === 200 && data?.data) {
						const { token } = data?.data as { token: string };

						if (lineEmail === email) {
							pushHistory(history, `/?verify=success&token=${token}`);
							window.scrollTo(0, 0);
						} else {
							dispatch(sendVerificationEmail(email, 'REGISTRATION'));
							const encodedEmail = encodeURIComponent(email);
							window.location.href = `/email-verify?email=${encodedEmail}&type=REGISTRATION`;
						}
					}
					removeItem(StorageKey.LINE_BIND);
					return { status };
				}

				// 從會員中心綁定，會自動會員資料
				if (type === 'bind') {
					const { name, email, mobile, verifyCode } = formData;
					const { v1AuthLineLinkCreate } = api;
					const { status, data } = await v1AuthLineLinkCreate({
						email,
						name,
						mobile,
						code: verifyCode,
						socialite_token: socialiteToken,
					});

					if (status === 200 && data?.data) {
						const id = nanoid();

						history.push({
							pathname: '/member-centre',
							search: 'type=1',
						});

						window.scrollTo(0, 0);
						dispatch(getUserInfo());
						dispatch(
							toast({
								id,
								message: '已成功綁定 Line',
								type: 'success',
								color: 'green',
							}),
						);
						removeItem(StorageKey.LINE_BIND);
					}
					return { status };
				}

				return { status: 0 };
			} catch (error) {
				const { errorCode } = (error as Error).error;

				if (errorCode === ERROR_CODE.USER_IS_UNVERIFIED) {
					window.scrollTo(0, 0);
					const id = nanoid();

					history.push({
						pathname: '/member-centre',
						search: 'type=1',
					});

					dispatch(
						toast({
							id,
							message: ERROR_MESSAGES.USER_IS_UNVERIFIED,
							type: 'warn',
							color: 'warning',
						}),
					);
				}

				return {
					status: (error as Error).error.status,
					errorCode: (error as Error).error.errorCode,
				};
			}
		},
);

export interface State {
	loading: boolean;

	facebookLoginStatus: V1AuthFacebookLinkCreateRequestPayload;
	getLineIdTokenStatus: BasePayload;
	getLineMemberInfoStatus: BasePayload;
	bingFacebookStatus: BasePayload;
	bindLineStatus: BasePayload;
}

export const defaultState: State = {
	loading: false,

	bingFacebookStatus: {
		status: 0,
		errorCode: null,
	},
	facebookLoginStatus: {
		name: '',
		email: '',
		mobile: '',
		socialite_token: '', // Add the missing property 'socialite_token'
	},
	getLineIdTokenStatus: {
		status: 0,
		errorCode: null,
	},
	getLineMemberInfoStatus: {
		status: 0,
		errorCode: null,
	},
	bindLineStatus: {
		status: 0,
		errorCode: null,
	},
};

export const reducer = {
	thirdPartyAuth: handleActions<State, any>(
		{
			SIGNUP_FACEBOOK_FULFILLED: (
				state,
				action: Action<V1AuthFacebookLinkCreateRequestPayload>,
			) => ({
				...state,
				facebookLoginStatus: action.payload,
				loading: false,
			}),
			BIND_FACEBOOK_PENDING: state => ({
				...state,
				loading: true,
			}),
			BIND_FACEBOOK_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				bingFacebookStatus: action.payload,
				loading: false,
			}),
			GET_LINE_ID_TOKEN_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_LINE_ID_TOKEN_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				getLineIdTokenStatus: action.payload,
				loading: false,
			}),
			GET_LINE_ID_TOKEN_REJECTED: state => ({
				...state,
				loading: false,
			}),
			BIND_LINE_PENDING: state => ({
				...state,
				loading: true,
			}),
			BIND_LINE_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				bindLineStatus: action.payload,
				loading: false,
			}),
			BIND_LINE_REJECTED: state => ({
				...state,
				loading: false,
			}),
			GET_LINE_MEMBER_INFO_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_LINE_MEMBER_INFO_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				getLineMemberInfoStatus: action.payload,
				loading: false,
			}),
			GET_LINE_MEMBER_INFO_REJECTED: state => ({
				...state,
				loading: false,
			}),
		},
		defaultState,
	),
};

const thirdPartyAuthActionsMap = {
	facebook,
	bindFacebook,
	bindLine,
	getLineIdToken,
	getLineMemberInfo,
};

const loadingSelector = (state: GlobalState) => state.thirdPartyAuth.loading;
const facebookLoginStatusSelector = (state: GlobalState) =>
	state.thirdPartyAuth.facebookLoginStatus;
const bindFacebookStatusSelector = (state: GlobalState) => state.thirdPartyAuth.bingFacebookStatus;
const getLineIdTokenStatusSelector = (state: GlobalState) =>
	state.thirdPartyAuth.getLineIdTokenStatus;
const getLineMemberInfoStatusSelector = (state: GlobalState) =>
	state.thirdPartyAuth.getLineMemberInfoStatus;
const bindLineStatusSelector = (state: GlobalState) => state.thirdPartyAuth.bindLineStatus;

const mapHooksToState = createSelector(
	[
		loadingSelector,
		facebookLoginStatusSelector,
		bindFacebookStatusSelector,
		getLineIdTokenStatusSelector,
		getLineMemberInfoStatusSelector,
		bindLineStatusSelector,
	],
	(
		loading,
		facebookLoginStatus,
		bindFacebookStatus,
		getLineIdTokenStatus,
		getLineMemberInfoStatus,
		bindLineStatus,
	) => ({
		loading,
		facebookLoginStatus,
		bindFacebookStatus,
		getLineIdTokenStatus,
		getLineMemberInfoStatus,
		bindLineStatus,
	}),
);

type ThirdPartyAuthSelector = ReturnType<typeof mapHooksToState>;
type ThirdPartyAuthActionsMap = typeof thirdPartyAuthActionsMap;

export const useThirdPartyAuth = () =>
	useRedux<ThirdPartyAuthSelector, ThirdPartyAuthActionsMap>(
		mapHooksToState,
		thirdPartyAuthActionsMap,
	);
