export enum VALIDATION_RULES {
	REQUIRED = 'required',
	EMAIL = 'email',
	ACCOUNT = 'account',
	EMAIL_VERIFY = 'emailVerify',
	PASSWORD = 'password',
	CONFIRM_PASSWORD = 'confirmPassword',
	MOBILE = 'mobile',
	TELEPHONE = 'telephoneNumber',
	MOBILE_BARCODE = 'mobileBarcode',
	CERTIFICATE = 'certificate',
	TAX_ID = 'taxId',
	PATTERN = 'pattern',
	INTERNATIONAL_MOBILE = 'internationalMobile',
	VERIFY_CODE = 'verifyCode',
	NAME = 'name',
	ADDRESS = 'address',
}

export enum ERROR_MESSAGES {
	REQUIRED = '此欄位必填！',
	EMAIL = '電子信箱格式錯誤',
	ACCOUNT = '格式錯誤',
	EMAIL_VERIFY = '驗證碼錯誤，請重新輸入',
	PASSWORD = '密碼長度至少為 8 碼，須包含英文字母及數字',
	CONFIRM_PASSWORD = '密碼輸入錯誤',
	MOBILE = '格式錯誤，請確認您的手機號碼已輸入 09 開頭及 10 碼文字',
	TELEPHONE = '市話號碼格式錯誤',
	MOBILE_BARCODE = '條碼格式錯誤',
	CERTIFICATE = '條碼格式錯誤',
	TAX_ID = '統編格式錯誤',
	TAX_NUMBER = '統編輸入錯誤',
	EMAIL_EXISTED = '電子信箱已被註冊，如有疑問請撥打客服專線',
	VERIFY_FAIL = '驗證碼錯誤，請重新輸入',
	VERIFY_ERROR_LIMIT_REACHED = '驗證碼輸入錯誤達 5 次，請重新取得驗證碼',
	USER_NOT_FOUND = '此電子信箱尚未註冊',
	USER_IS_DISABLE = '此會員帳號已被停用',
	USER_EMAIL_NOT_FOUND = '此電子信箱尚未註冊',
	CODE_NOT_FOUND = '驗證碼不存在或已驗證',
	PASSWORD_ERROR = '密碼錯誤',
	PATTERN = '格式錯誤',
	LOGIN_FAIL = '帳號或密碼輸入錯誤',
	INTERNATIONAL_MOBILE = '手機號碼格式錯誤',
	MOBILE_HAS_SOCIALITE = '手機號碼已使用第三方註冊，如有疑問請撥打客服專線',
	MOBILE_EXISTED = '手機號碼已被註冊，如有疑問請撥打客服專線',
	CODE_EXPIRED = '此驗證碼時效已超過',
	CODE_ERROR = '驗證碼錯誤',
	EMAIL_HAS_SOCIALITE = '電子信箱已使用第三方註冊，如有疑問請撥打客服專線',
	USER_IS_UNVERIFIED = '會員尚未進行驗證',
	USER_IS_AUTHENTICATED = '會員已驗證過',
	OLD_PASSWORD_ERROR = '舊密碼錯誤',
	PASSWORD_SAME_AS_OLD = '新密碼不可與舊密碼相同',
	USER_NOT_FOUND_MOBILE = '此手機號碼尚未被註冊',
	CODE_LESS_THAN_SIX = '驗證碼不足 6 位數',
	NAME = '姓名需為中文 2-5 個字或英文 4-10 個字元，且不可有符號',
	ADDRESS = '地址需為 4-60 個字',
	LINE_TOKEN_ERROR = '解析 token 取得資料失敗',
}
