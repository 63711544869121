import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/payment3DSecure',
	components: () => [import(/* webpackChunkName: 'Payment3DSecure' */ './component')],
	render: ([Payment3DSecureProperty]) => <Payment3DSecureProperty />,
};

export default routes;
