/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	AdminUserResource,
	AdvanceSearchable,
	AdvanceSearchRequest,
	ApiResponseTemplate,
	BannerResource,
	BscoreBscoreAdminUserInitAccountCreateRequestPayload,
	BscoreBscoreAdminUserUnlockAccountCreateRequestPayload,
	BscoreDestroyAllCreateRequestPayload,
	BscoreExcelImportExampleFileCreateRequestPayload,
	BscoreExcelImportUploadUrlCreateRequestPayload,
	BscoreExportResult,
	BscoreForgetPasswordCreateRequestPayload,
	BscoreGetForgetMailInfoListRequestParams,
	BscoreGetUnlockMailInfoListRequestParams,
	BscoreGetVerifyMailInfoListRequestParams,
	BscoreImportResult,
	BscoreLoginCreateRequestPayload,
	BscoreResetPasswordCreateRequestPayload,
	BscoreResetPasswordFromMailCreateRequestPayload,
	BscoreSettingsUpdateRequestPayload,
	BscoreSubListDestroyAllCreateRequestPayload,
	BscoreSubListExcelImportExampleFileCreateRequestPayload,
	BscoreSubListExcelImportUploadUrlCreateRequestPayload,
	BscoreSubListUpdateListCreateRequestParams,
	BscoreSubListUpdateListCreateRequestPayload,
	BscoreSubListUpdateRowCreateRequestPayload,
	BscoreUnlockAccountCreateRequestPayload,
	BscoreUpdateListCreateRequestParams,
	BscoreUpdateListCreateRequestPayload,
	BscoreUpdateRowCreateRequestPayload,
	BscoreUploadUploadUrlCreateRequestPayload,
	BscoreVerifyAccountCreateRequestPayload,
	BsxListResource,
	CalendarResource,
	CapacityPickupListResource,
	CapacityShippingListResource,
	CouponEventResource,
	DiscountEventResource,
	ExcelSearchRequest,
	FreeShippingEventResource,
	GetDataCreate,
	GetDataUpdate,
	GetListDataPaginator,
	ImportRequest,
	MenuItemResource,
	MenuResource,
	ModalResource,
	OptionsAddressResource,
	OrderListResource,
	OrderResource,
	PickupOptionsResource,
	ProductCollection,
	ProductResource,
	SearchRequest,
	ShippingFeeImageCollection,
	ShoppingCartResource,
	ShoppingInstructionCollection,
	ShoppingQuestionCollection,
	ThresholdRewardResource,
	UserCouponResource,
	UserResource,
	V1AuthCheckUserEmailCreateRequestPayload,
	V1AuthFacebookLinkCreateRequestPayload,
	V1AuthFacebookVerifyCreateRequestPayload,
	V1AuthLineGetTokenCreateRequestPayload,
	V1AuthLineLinkCreateRequestPayload,
	V1AuthLineVerifyCreateRequestPayload,
	V1AuthLoginCreateRequestPayload,
	V1AuthRegisterCreateRequestPayload,
	V1AuthResetMobileCreateRequestPayload,
	V1AuthResetPasswordCreateRequestPayload,
	V1AuthSendMobileVerificationSmsCreateRequestPayload,
	V1AuthSendUserVerificationEmailCreateRequestPayload,
	V1CalendarDetailRequestParams,
	V1CalendarPickupOptionsListRequestParams,
	V1DiscountEventsListRequestParams,
	V1FreeShippingEventsListRequestParams,
	V1TestEcpayLogisticsCallbackCreateRequestPayload,
	V1TestEcpayPaymentCallbackCreateRequestPayload,
	V1TestLineLoginCallbackVerifyListRequestParams,
	V1UserChangeEmailUpdateRequestPayload,
	V1UserChangePasswordUpdateRequestPayload,
	V1UserCouponsListRequestParams,
	V1UserCouponsReceiveCreateRequestPayload,
	V1UserOrderCheckoutCreateRequestPayload,
	V1UserOrderPaymentCreateRequestPayload,
	V1UserOrderShoppingCartUpdateRequestPayload,
	V1UserOrdersListRequestParams,
	V1UserUnlinkSocialiteUpdateRequestPayload,
	V1UserUpdateRequestParams,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * No description
	 *
	 * @tags Backstagex-List
	 * @name BackstagexProductsDetail
	 * @summary 取得所有商品-後台模組使用
	 * @request GET:/api/backstagex/{menu_item_key}/products
	 * @secure
	 */
	backstagexProductsDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BsxListResource[];
				};
			},
			any
		>({
			path: `/api/backstagex/${menuItemKey}/products`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-List
	 * @name BackstagexProductCombinationsDetail
	 * @summary 取得所有商品組合-後台模組使用
	 * @request GET:/api/backstagex/{menu_item_key}/product-combinations
	 * @secure
	 */
	backstagexProductCombinationsDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BsxListResource[];
				};
			},
			any
		>({
			path: `/api/backstagex/${menuItemKey}/product-combinations`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-List
	 * @name BackstagexGiftsDetail
	 * @summary 取得所有贈品-後台模組使用
	 * @request GET:/api/backstagex/{menu_item_key}/gifts
	 * @secure
	 */
	backstagexGiftsDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BsxListResource[];
				};
			},
			any
		>({
			path: `/api/backstagex/${menuItemKey}/gifts`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-List
	 * @name BackstagexCityZonesDetail
	 * @summary 取得縣市鄉鎮選項-後台模組使用
	 * @request GET:/api/backstagex/{menu_item_key}/city-zones
	 * @secure
	 */
	backstagexCityZonesDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BsxListResource[];
				};
			},
			any
		>({
			path: `/api/backstagex/${menuItemKey}/city-zones`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-List
	 * @name BackstagexCapacityShippingDetail
	 * @summary 取得宅配產能-後台模組使用
	 * @request GET:/api/backstagex/{menu_item_key}/capacity-shipping
	 * @secure
	 */
	backstagexCapacityShippingDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: CapacityShippingListResource[];
				};
			},
			any
		>({
			path: `/api/backstagex/${menuItemKey}/capacity-shipping`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-List
	 * @name BackstagexCapacityPickupDetail
	 * @summary 取得宅配產能-後台模組使用
	 * @request GET:/api/backstagex/{menu_item_key}/capacity-pickup
	 * @secure
	 */
	backstagexCapacityPickupDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: CapacityPickupListResource[];
				};
			},
			any
		>({
			path: `/api/backstagex/${menuItemKey}/capacity-pickup`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-Update
	 * @name BackstagexBatchUpdateUpdate
	 * @summary 批次更新訂單自取狀態
	 * @request PUT:/api/backstagex/{menu_item_key}/batch-update
	 * @secure
	 */
	backstagexBatchUpdateUpdate = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/backstagex/${menuItemKey}/batch-update`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-Update
	 * @name BackstagexArchiveUpdate
	 * @summary 作廢活動-後台模組使用
	 * @request PUT:/api/backstagex/{menu_item_key}/{id}/archive
	 * @secure
	 */
	backstagexArchiveUpdate = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/backstagex/${menuItemKey}/${id}/archive`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-Update
	 * @name BackstagexOrderOrderCancelUpdate
	 * @summary 取消訂單-後台模組使用
	 * @request PUT:/api/backstagex/order:order/{id}/cancel
	 * @secure
	 */
	backstagexOrderOrderCancelUpdate = (id: string, order: string, params: RequestParams = {}) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/backstagex/order${order}/${id}/cancel`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-Update
	 * @name BackstagexOrderOrderPayDetailDetail
	 * @summary 信用卡金流查詢-後台模組使用
	 * @request GET:/api/backstagex/order:order/{id}/payDetail
	 * @secure
	 */
	backstagexOrderOrderPayDetailDetail = (id: string, order: string, params: RequestParams = {}) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/backstagex/order${order}/${id}/payDetail`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-Update
	 * @name BackstagexOrderOrderQueryTradeInfoDetail
	 * @summary 查詢交易資訊-後台模組使用
	 * @request GET:/api/backstagex/order:order/{id}/queryTradeInfo
	 * @secure
	 */
	backstagexOrderOrderQueryTradeInfoDetail = (
		id: string,
		order: string,
		params: RequestParams = {},
	) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/backstagex/order${order}/${id}/queryTradeInfo`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得宅配/自取日曆資訊，unavailable_dates 為不可選擇的日期，包含不可預約或已滿
	 *
	 * @tags Calendar
	 * @name V1CalendarDetail
	 * @summary 取得宅配/自取日曆資訊
	 * @request GET:/api/v1/calendar/{type}
	 */
	v1CalendarDetail = (
		{ type, ...query }: V1CalendarDetailRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: CalendarResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/calendar/${type}`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Calendar
	 * @name V1CalendarPickupOptionsList
	 * @summary 取得自取日期的時段選項
	 * @request GET:/api/v1/calendar/pickup-options
	 */
	v1CalendarPickupOptionsList = (
		query: V1CalendarPickupOptionsListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: PickupOptionsResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/calendar/pickup-options`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Coupon-Event
	 * @name V1CouponEventsList
	 * @summary 取得優惠券活動資訊
	 * @request GET:/api/v1/coupon-events
	 */
	v1CouponEventsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: CouponEventResource[];
				};
			},
			any
		>({
			path: `/api/v1/coupon-events`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Coupon-Event
	 * @name V1CouponEventsDetail
	 * @summary 取得單一優惠券活動資訊
	 * @request GET:/api/v1/coupon-events/{code}
	 */
	v1CouponEventsDetail = (code: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: CouponEventResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/coupon-events/${code}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 狀態：上線｜類型：全館產品
	 *
	 * @tags Discount-Event
	 * @name V1DiscountEventsList
	 * @summary 取得折扣優惠資訊
	 * @request GET:/api/v1/discount-events
	 */
	v1DiscountEventsList = (query: V1DiscountEventsListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: DiscountEventResource[];
				};
			},
			any
		>({
			path: `/api/v1/discount-events`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Discount-Event
	 * @name V1DiscountEventsDetail
	 * @summary 取得單一折扣優惠資訊
	 * @request GET:/api/v1/discount-events/{id}
	 */
	v1DiscountEventsDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: DiscountEventResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/discount-events/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 此 API 為解析 Facebook token 取得個人資訊，若已綁定則會直接登入，若未綁定則走綁定流程回傳相關資訊
	 *
	 * @tags Auth
	 * @name V1AuthFacebookVerifyCreate
	 * @summary (第三方_fb 2.) Facebook 註冊/登入
	 * @request POST:/api/v1/auth/facebook/verify
	 */
	v1AuthFacebookVerifyCreate = (
		data: V1AuthFacebookVerifyCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/facebook/verify`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Free-Shipping-Event
	 * @name V1FreeShippingEventsList
	 * @summary 取得免運活動資訊
	 * @request GET:/api/v1/free-shipping-events
	 */
	v1FreeShippingEventsList = (
		query: V1FreeShippingEventsListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: FreeShippingEventResource[];
				};
			},
			any
		>({
			path: `/api/v1/free-shipping-events`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Free-Shipping-Event
	 * @name V1FreeShippingEventsDetail
	 * @summary 取得單一免運活動資訊
	 * @request GET:/api/v1/free-shipping-events/{id}
	 */
	v1FreeShippingEventsDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: FreeShippingEventResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/free-shipping-events/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 此 API 為解析 code 取得 id_token
	 *
	 * @tags Auth
	 * @name V1AuthLineGetTokenCreate
	 * @summary 取得 Line Token
	 * @request POST:/api/v1/auth/line/get-token
	 */
	v1AuthLineGetTokenCreate = (
		data: V1AuthLineGetTokenCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						id_token?: string;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/line/get-token`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * @description 此 API 為解析 id_token 取得 Line 個人資訊，若 LineID 已綁定則會直接登入，若未綁定則走綁定流程回傳相關資訊
	 *
	 * @tags Auth
	 * @name V1AuthLineVerifyCreate
	 * @summary Line 註冊/登入
	 * @request POST:/api/v1/auth/line/verify
	 */
	v1AuthLineVerifyCreate = (
		data: V1AuthLineVerifyCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/line/verify`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得上線的 Modal 資訊
	 *
	 * @tags Modal
	 * @name V1ModalsList
	 * @summary 取得 Modal 資訊
	 * @request GET:/api/v1/modals
	 */
	v1ModalsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ModalResource;
				};
			},
			any
		>({
			path: `/api/v1/modals`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Options
	 * @name V1OptionsAddressList
	 * @summary 取得地址選項
	 * @request GET:/api/v1/options/address
	 */
	v1OptionsAddressList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: OptionsAddressResource;
				};
			},
			any
		>({
			path: `/api/v1/options/address`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上架的資料
	 *
	 * @tags Product
	 * @name V1ProductsList
	 * @summary 取得產品列表
	 * @request GET:/api/v1/products
	 */
	v1ProductsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductCollection[];
				};
			},
			any
		>({
			path: `/api/v1/products`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product
	 * @name V1ProductsDetail
	 * @summary 取得單一產品資訊
	 * @request GET:/api/v1/products/{id}
	 */
	v1ProductsDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/products/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上架的資料
	 *
	 * @tags Product
	 * @name V1ProductsAddonsList
	 * @summary 取得加價購產品列表
	 * @request GET:/api/v1/products/addons
	 */
	v1ProductsAddonsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductCollection[];
				};
			},
			any
		>({
			path: `/api/v1/products/addons`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Shipping-Fee
	 * @name V1ShippingFeesList
	 * @summary 取得運費圖片列表
	 * @request GET:/api/v1/shipping-fees
	 */
	v1ShippingFeesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ShippingFeeImageCollection[];
				};
			},
			any
		>({
			path: `/api/v1/shipping-fees`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Shopping-Cart
	 * @name V1UserOrderShoppingCartList
	 * @summary 取得暫存訂單
	 * @request GET:/api/v1/user/order/shopping-cart
	 * @secure
	 */
	v1UserOrderShoppingCartList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ShoppingCartResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/order/shopping-cart`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 宅配時間選項： ALL(不指定), AM(上午), PM(下午)<br>自取時間選項：1(11:00 - 12:00), 2(12:00 - 13:00), 3(13:00 - 14:00), 4(14:00 - 15:00), 5(15:00 - 16:00), 6(16:00 - 17:00), 7(17:00 - 18:00)
	 *
	 * @tags Shopping-Cart
	 * @name V1UserOrderShoppingCartUpdate
	 * @summary 建立或更新暫存訂單
	 * @request PUT:/api/v1/user/order/shopping-cart
	 * @secure
	 */
	v1UserOrderShoppingCartUpdate = (
		data: V1UserOrderShoppingCartUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ShoppingCartResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/order/shopping-cart`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上架的訂購說明
	 *
	 * @tags Shopping-guide
	 * @name V1ShoppingGuideInstructionsList
	 * @summary 取得訂購說明列表
	 * @request GET:/api/v1/shopping-guide/instructions
	 */
	v1ShoppingGuideInstructionsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ShoppingInstructionCollection[];
				};
			},
			any
		>({
			path: `/api/v1/shopping-guide/instructions`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上架的常見問題
	 *
	 * @tags Shopping-guide
	 * @name V1ShoppingGuideQuestionsList
	 * @summary 取得常見問題列表
	 * @request GET:/api/v1/shopping-guide/questions
	 */
	v1ShoppingGuideQuestionsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ShoppingQuestionCollection[];
				};
			},
			any
		>({
			path: `/api/v1/shopping-guide/questions`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Threshold-Reward
	 * @name V1ThresholdRewardsList
	 * @summary 取得滿額贈資訊
	 * @request GET:/api/v1/threshold-rewards
	 */
	v1ThresholdRewardsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ThresholdRewardResource[];
				};
			},
			any
		>({
			path: `/api/v1/threshold-rewards`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Threshold-Reward
	 * @name V1ThresholdRewardsDetail
	 * @summary 取得單一滿額贈資訊
	 * @request GET:/api/v1/threshold-rewards/{id}
	 */
	v1ThresholdRewardsDetail = (id: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ThresholdRewardResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/threshold-rewards/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 一般註冊第一步，重設手機第三步，參數 mobile: 手機號碼，格式為 +886912345678
	 *
	 * @tags Auth
	 * @name V1AuthSendMobileVerificationSmsCreate
	 * @summary (一般註冊 1.) 發送簡訊 OTP 驗證碼
	 * @request POST:/api/v1/auth/send-mobile-verification-sms
	 */
	v1AuthSendMobileVerificationSmsCreate = (
		data: V1AuthSendMobileVerificationSmsCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			{
				/**
				 * 回應狀態
				 * @example 200
				 */
				status?: number;
				/**
				 * 回應訊息
				 * @example "successful"
				 */
				message?: string;
				/** 回應資料 */
				data?: {
					/**
					 * 回應資料
					 * @example "null"
					 */
					data?: string;
				}[];
			},
			| BscoreImportResult
			| {
					/**
					 * 回應標題
					 * @example "格式錯誤"
					 */
					title?: string;
					/**
					 * 回應訊息
					 * @example "The given data was invalid."
					 */
					message?: string;
					/** 錯誤資料 */
					errors?: {
						/** 錯誤項目 */
						mobilePhone?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/send-mobile-verification-sms`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthRegisterCreate
	 * @summary (一般註冊 2.) 會員註冊
	 * @request POST:/api/v1/auth/register
	 */
	v1AuthRegisterCreate = (data: V1AuthRegisterCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						user_id?: number;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/register`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * @description 一般註冊第三步驟，忘記密碼第一步驟<br/>參數 usage: REGISTRATION(一般註冊) | RESET_PASSWORD(忘記密碼)
	 *
	 * @tags Auth
	 * @name V1AuthSendUserVerificationEmailCreate
	 * @summary 寄送會員驗證信件
	 * @request POST:/api/v1/auth/send-user-verification-email
	 */
	v1AuthSendUserVerificationEmailCreate = (
		data: V1AuthSendUserVerificationEmailCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate,
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/send-user-verification-email`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthLoginCreate
	 * @summary 會員一般登入
	 * @request POST:/api/v1/auth/login
	 */
	v1AuthLoginCreate = (data: V1AuthLoginCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example "eyJ0e..." */
						token?: string;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
						password?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/login`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthRefreshTokenCreate
	 * @summary Refresh Token
	 * @request POST:/api/v1/auth/refresh-token
	 * @secure
	 */
	v1AuthRefreshTokenCreate = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						token?: string;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/refresh-token`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthLogoutCreate
	 * @summary 登出
	 * @request POST:/api/v1/auth/logout
	 * @secure
	 */
	v1AuthLogoutCreate = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/logout`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthFacebookLinkCreate
	 * @summary (第三方_fb 2.) 會員綁定/註冊 Facebook 帳號
	 * @request POST:/api/v1/auth/facebook/link
	 */
	v1AuthFacebookLinkCreate = (
		data: V1AuthFacebookLinkCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/facebook/link`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthLineLinkCreate
	 * @summary (第三方_Line 2.) 會員綁定/註冊 LINE 帳號
	 * @request POST:/api/v1/auth/line/link
	 */
	v1AuthLineLinkCreate = (data: V1AuthLineLinkCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/line/link`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthCheckUserEmailCreate
	 * @summary 確認 email 是否為會員
	 * @request POST:/api/v1/auth/check-user-email
	 */
	v1AuthCheckUserEmailCreate = (
		data: V1AuthCheckUserEmailCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** 會員ID */
						user_id?: number;
						/** 會員信箱驗證狀態 */
						verified_status?: boolean;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
					}[];
			  }
		>({
			path: `/api/v1/auth/check-user-email`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthResetPasswordCreate
	 * @summary (忘記密碼 2.) 重設會員密碼
	 * @request POST:/api/v1/auth/reset-password
	 */
	v1AuthResetPasswordCreate = (
		data: V1AuthResetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/reset-password`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthResetMobileCreate
	 * @summary (重設手機 2.) 重設會員手機號碼
	 * @request POST:/api/v1/auth/reset-mobile
	 */
	v1AuthResetMobileCreate = (
		data: V1AuthResetMobileCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/reset-mobile`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserList
	 * @summary 取得會員資訊
	 * @request GET:/api/v1/user
	 * @secure
	 */
	v1UserList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserResource;
				};
			},
			any
		>({
			path: `/api/v1/user`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserUpdate
	 * @summary 更新會員資訊
	 * @request PUT:/api/v1/user
	 * @secure
	 */
	v1UserUpdate = (query: V1UserUpdateRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user`,
			method: 'PUT',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserChangePasswordUpdate
	 * @summary 更改密碼
	 * @request PUT:/api/v1/user/change-password
	 * @secure
	 */
	v1UserChangePasswordUpdate = (
		data: V1UserChangePasswordUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/user/change-password`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserChangeEmailUpdate
	 * @summary 更改 email
	 * @request PUT:/api/v1/user/change-email
	 * @secure
	 */
	v1UserChangeEmailUpdate = (
		data: V1UserChangeEmailUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/user/change-email`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserUnlinkSocialiteUpdate
	 * @summary 解除榜定社群帳號
	 * @request PUT:/api/v1/user/unlink-socialite
	 * @secure
	 */
	v1UserUnlinkSocialiteUpdate = (
		data: V1UserUnlinkSocialiteUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/user/unlink-socialite`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * @description 每頁6筆資料
	 *
	 * @tags User-Coupon
	 * @name V1UserCouponsList
	 * @summary 取得會員持有的優惠券列表
	 * @request GET:/api/v1/user/coupons
	 * @secure
	 */
	v1UserCouponsList = (query: V1UserCouponsListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						data?: UserCouponResource[];
						meta?: {
							/** @example 1 */
							current_page?: number;
							/** @example "FULL_URL?page=1" */
							first_page_url?: string;
							/** @example 1 */
							from?: number;
							/** @example 1 */
							last_page?: number;
							/** @example "FULL_URL?page=1" */
							last_page_url?: string;
							/** @example null */
							next_page_url?: string;
							/** @example "FULL_URL" */
							path?: string;
							/** @example 6 */
							per_page?: number;
							/** @example null */
							prev_page_url?: string;
							/** @example 5 */
							to?: number;
							/** @example 5 */
							total?: number;
							links?: {
								/** @example "FULL_URL?page=1" */
								url?: string;
								/** @example "1" */
								label?: string;
								/** @example true */
								active?: boolean;
							}[];
						};
					};
				};
			},
			any
		>({
			path: `/api/v1/user/coupons`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Coupon
	 * @name V1UserCouponsReceiveCreate
	 * @summary 領取優惠券
	 * @request POST:/api/v1/user/coupons/receive
	 * @secure
	 */
	v1UserCouponsReceiveCreate = (
		data: V1UserCouponsReceiveCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserCouponResource[];
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/coupons/receive`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 每頁 5 筆資料
	 *
	 * @tags User-Order
	 * @name V1UserOrdersList
	 * @summary 取得會員訂單列表
	 * @request GET:/api/v1/user/orders
	 * @secure
	 */
	v1UserOrdersList = (query: V1UserOrdersListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						data?: OrderListResource[];
						meta?: {
							/** @example 1 */
							current_page?: number;
							/** @example "FULL_URL?page=1" */
							first_page_url?: string;
							/** @example 1 */
							from?: number;
							/** @example 1 */
							last_page?: number;
							/** @example "FULL_URL?page=1" */
							last_page_url?: string;
							/** @example null */
							next_page_url?: string;
							/** @example "FULL_URL" */
							path?: string;
							/** @example 5 */
							per_page?: number;
							/** @example null */
							prev_page_url?: string;
							/** @example 5 */
							to?: number;
							/** @example 5 */
							total?: number;
							links?: {
								/** @example "FULL_URL?page=1" */
								url?: string;
								/** @example "1" */
								label?: string;
								/** @example true */
								active?: boolean;
							}[];
						};
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/orders`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Order
	 * @name V1UserOrderDetail
	 * @summary 取得會員特定訂單的詳細資訊
	 * @request GET:/api/v1/user/order/{orderId}
	 * @secure
	 */
	v1UserOrderDetail = (orderId: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: OrderResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/order/${orderId}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Order
	 * @name V1UserOrderCheckoutCreate
	 * @summary 建立正式訂單
	 * @request POST:/api/v1/user/order/checkout
	 * @secure
	 */
	v1UserOrderCheckoutCreate = (
		data: V1UserOrderCheckoutCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: OrderListResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/order/checkout`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Order
	 * @name V1UserOrderPaymentCreate
	 * @summary 進入付款流程
	 * @request POST:/api/v1/user/order/payment
	 * @secure
	 */
	v1UserOrderPaymentCreate = (
		data: V1UserOrderPaymentCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/user/order/payment`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestEcpayPaymentCallbackCreate
	 * @summary 【後端內部測試用】付款結果通知
	 * @request POST:/api/v1/test/ecpay/payment-callback
	 */
	v1TestEcpayPaymentCallbackCreate = (
		data: V1TestEcpayPaymentCallbackCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<string, any>({
			path: `/api/v1/test/ecpay/payment-callback`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestEcpayLogisticsCallbackCreate
	 * @summary 【後端內部測試用】物流狀態通知
	 * @request POST:/api/v1/test/ecpay/logistics-callback
	 */
	v1TestEcpayLogisticsCallbackCreate = (
		data: V1TestEcpayLogisticsCallbackCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<string, any>({
			path: `/api/v1/test/ecpay/logistics-callback`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestLineLoginCallbackList
	 * @summary 【後端內部測試用】LineLogin (只回傳 code 不做解析)
	 * @request GET:/api/v1/test/line-login/callback
	 */
	v1TestLineLoginCallbackList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						code?: string;
					};
				};
			},
			any
		>({
			path: `/api/v1/test/line-login/callback`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestLineLoginCallbackVerifyList
	 * @summary 【後端內部測試用】LineLogin 接收 code 解析
	 * @request GET:/api/v1/test/line-login/callback-verify
	 */
	v1TestLineLoginCallbackVerifyList = (
		query: V1TestLineLoginCallbackVerifyListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/line-login/callback-verify`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 依權重大小排序，僅回傳前十筆的資料
	 *
	 * @tags Banner
	 * @name V1BannersList
	 * @summary 取得 Banner 資訊
	 * @request GET:/api/v1/banners
	 */
	v1BannersList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BannerResource[];
				};
			},
			any
		>({
			path: `/api/v1/banners`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Banner
	 * @name V1BannersDetail
	 * @summary 取得單一 Banner 資訊
	 * @request GET:/api/v1/banners/{id}
	 */
	v1BannersDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BannerResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/banners/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 後台登入用，會取得 JWT Token
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreLoginCreate
	 * @request POST:/api/bscore/login
	 */
	bscoreLoginCreate = (data: BscoreLoginCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * JWT token
						 * @example "eyJ0eXAiOiJ..."
						 */
						token?: string;
						/** 模組 route 列表，提供跳轉用 */
						default?: string[];
					};
				};
			},
			any
		>({
			path: `/api/bscore/login`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description Refresh Token
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreRefreshTokenCreate
	 * @request POST:/api/bscore/refresh-token
	 * @secure
	 */
	bscoreRefreshTokenCreate = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * JWT token
						 * @example "eyJ0eXAiOiJ..."
						 */
						token?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/refresh-token`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 變更密碼
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreResetPasswordCreate
	 * @request POST:/api/bscore/resetPassword
	 * @secure
	 */
	bscoreResetPasswordCreate = (
		data: BscoreResetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/resetPassword`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_admin_user
	 * @name BscoreMeList
	 * @summary 取得目前使用者的資料
	 * @request GET:/api/bscore/me
	 * @secure
	 */
	bscoreMeList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/me`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 重置使用者，並寄送驗證信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreBscoreAdminUserInitAccountCreate
	 * @request POST:/api/bscore/bscore:admin-user/init-account
	 * @secure
	 */
	bscoreBscoreAdminUserInitAccountCreate = (
		adminUser: string,
		data: BscoreBscoreAdminUserInitAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/bscore${adminUser}/init-account`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 寄送帳號解鎖信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreBscoreAdminUserUnlockAccountCreate
	 * @request POST:/api/bscore/bscore:admin-user/unlock-account
	 * @secure
	 */
	bscoreBscoreAdminUserUnlockAccountCreate = (
		adminUser: string,
		data: BscoreBscoreAdminUserUnlockAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/bscore${adminUser}/unlock-account`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得帳號解鎖信 user 資料
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetUnlockMailInfoList
	 * @request GET:/api/bscore/get-unlock-mail-info
	 */
	bscoreGetUnlockMailInfoList = (
		query: BscoreGetUnlockMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-unlock-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 解鎖帳號
	 *
	 * @tags bscore_admin_user
	 * @name BscoreUnlockAccountCreate
	 * @request POST:/api/bscore/unlock-account
	 */
	bscoreUnlockAccountCreate = (
		data: BscoreUnlockAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/unlock-account`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得信箱驗證信 user 資料
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetVerifyMailInfoList
	 * @request GET:/api/bscore/get-verify-mail-info
	 */
	bscoreGetVerifyMailInfoList = (
		query: BscoreGetVerifyMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-verify-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 驗證帳號
	 *
	 * @tags bscore_admin_user
	 * @name BscoreVerifyAccountCreate
	 * @request POST:/api/bscore/verify-account
	 */
	bscoreVerifyAccountCreate = (
		data: BscoreVerifyAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/verify-account`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreForgetPasswordCreate
	 * @request POST:/api/bscore/forget-password
	 */
	bscoreForgetPasswordCreate = (
		data: BscoreForgetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						next_can_send?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/forget-password`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼 - 重設密碼資訊
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetForgetMailInfoList
	 * @request GET:/api/bscore/get-forget-mail-info
	 */
	bscoreGetForgetMailInfoList = (
		query: BscoreGetForgetMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-forget-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼 - 重設密碼
	 *
	 * @tags bscore_admin_user
	 * @name BscoreResetPasswordFromMailCreate
	 * @request POST:/api/bscore/reset-password-from-mail
	 */
	bscoreResetPasswordFromMailCreate = (
		data: BscoreResetPasswordFromMailCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/reset-password-from-mail`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreGetModuleConfigDetail
	 * @summary 取得選單對應的模組 config
	 * @request GET:/api/bscore/{menu_item_key}/get-module-config
	 * @secure
	 */
	bscoreGetModuleConfigDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<GetDataCreate, any>({
			path: `/api/bscore/${menuItemKey}/get-module-config`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreGetSearchableDetail
	 * @summary 取得進階搜尋欄位
	 * @request GET:/api/bscore/{menu_item_key}/get-searchable
	 * @secure
	 */
	bscoreGetSearchableDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<AdvanceSearchable[], any>({
			path: `/api/bscore/${menuItemKey}/get-searchable`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 一般搜尋
	 *
	 * @tags bscore
	 * @name BscoreSearchCreate
	 * @request POST:/api/bscore/{menu_item_key}/search
	 * @secure
	 */
	bscoreSearchCreate = (menuItemKey: string, data: SearchRequest, params: RequestParams = {}) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreAdvanceSearchCreate
	 * @summary 進階搜尋
	 * @request POST:/api/bscore/{menu_item_key}/advance-search
	 * @secure
	 */
	bscoreAdvanceSearchCreate = (
		menuItemKey: string,
		data: AdvanceSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/advance-search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCreateDetail
	 * @summary 新增資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/create
	 * @secure
	 */
	bscoreCreateDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<GetDataCreate, any>({
			path: `/api/bscore/${menuItemKey}/create`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreEditDetail
	 * @summary 編輯資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/edit
	 * @secure
	 */
	bscoreEditDetail = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCreate
	 * @summary 新增
	 * @request POST:/api/bscore/{menu_item_key}
	 * @secure
	 */
	bscoreCreate = (menuItemKey: string, data: GetDataCreate, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdate
	 * @summary 更新
	 * @request PUT:/api/bscore/{menu_item_key}/{id}
	 * @secure
	 */
	bscoreUpdate = (
		menuItemKey: string,
		id: string,
		data: GetDataUpdate,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreDelete
	 * @summary 刪除
	 * @request DELETE:/api/bscore/{menu_item_key}/{id}
	 * @secure
	 */
	bscoreDelete = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCopyCreate
	 * @summary 複製
	 * @request POST:/api/bscore/{menu_item_key}/copy
	 * @secure
	 */
	bscoreCopyCreate = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/copy`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreDestroyAllCreate
	 * @summary 批次刪除
	 * @request POST:/api/bscore/{menu_item_key}/destroy-all
	 * @secure
	 */
	bscoreDestroyAllCreate = (
		menuItemKey: string,
		data: BscoreDestroyAllCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/destroy-all`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdateRowCreate
	 * @summary 列表單一行更新
	 * @request POST:/api/bscore/{menu_item_key}/update-row
	 * @secure
	 */
	bscoreUpdateRowCreate = (
		menuItemKey: string,
		data: BscoreUpdateRowCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/update-row`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdateListCreate
	 * @summary reorder/update status
	 * @request POST:/api/bscore/{menu_item_key}/update-list
	 * @secure
	 */
	bscoreUpdateListCreate = (
		{ menuItemKey, ...query }: BscoreUpdateListCreateRequestParams,
		data: BscoreUpdateListCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/update-list`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得 Menu Item 對應的 Dashboard Config
	 *
	 * @tags bscore_dashboard
	 * @name BscoreGetDashboardConfigDetail
	 * @request GET:/api/bscore/{menu_item_key}/get-dashboard-config
	 * @secure
	 */
	bscoreGetDashboardConfigDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/get-dashboard-config`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得 Metric Key 對應的 Metric
	 *
	 * @tags bscore_dashboard
	 * @name BscoreMetricDetail
	 * @request GET:/api/bscore/metric/{metric_key}
	 * @secure
	 */
	bscoreMetricDetail = (metricKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/metric/${metricKey}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelExportCreate
	 * @summary 匯出
	 * @request POST:/api/bscore/{menu_item_key}/excel-export
	 * @secure
	 */
	bscoreExcelExportCreate = (
		menuItemKey: string,
		data: ExcelSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-export`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelExportCreate
	 * @summary SubList 匯出
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-export
	 * @secure
	 */
	bscoreSubListExcelExportCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: ExcelSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-export`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportCreate
	 * @summary 匯入
	 * @request POST:/api/bscore/{menu_item_key}/excel-import
	 * @secure
	 */
	bscoreExcelImportCreate = (
		menuItemKey: string,
		data: ImportRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-import`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportUploadUrlCreate
	 * @summary 取得匯入用上傳檔案網址
	 * @request POST:/api/bscore/{menu_item_key}/excel-import-upload-url
	 * @secure
	 */
	bscoreExcelImportUploadUrlCreate = (
		menuItemKey: string,
		data: BscoreExcelImportUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-import-upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportCreate
	 * @summary SubList 匯入
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import
	 * @secure
	 */
	bscoreSubListExcelImportCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: ImportRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportUploadUrlCreate
	 * @summary 取得 SubList 匯入用上傳檔案網址
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import-upload-url
	 * @secure
	 */
	bscoreSubListExcelImportUploadUrlCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListExcelImportUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import-upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportExampleFileCreate
	 * @summary 下載匯入範例檔案
	 * @request POST:/api/bscore/{menu_item_key}/excel-import-example-file
	 * @secure
	 */
	bscoreExcelImportExampleFileCreate = (
		menuItemKey: string,
		data: BscoreExcelImportExampleFileCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<void, any>({
			path: `/api/bscore/${menuItemKey}/excel-import-example-file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportExampleFileCreate
	 * @summary 下載 SubList 匯入範例檔案
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import-example-file
	 * @secure
	 */
	bscoreSubListExcelImportExampleFileCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListExcelImportExampleFileCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<void, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import-example-file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * @description 列表頁側邊欄，列出 Menu 和 MenuItem
	 *
	 * @tags bscore_menu
	 * @name BscoreSubMenuNaviList
	 * @request GET:/api/bscore/sub-menu-navi
	 * @secure
	 */
	bscoreSubMenuNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: MenuResource[];
				};
			},
			any
		>({
			path: `/api/bscore/sub-menu-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 列表頁側邊欄，沒有 Menu 的 Menuitem 會在此列出
	 *
	 * @tags bscore_menu
	 * @name BscoreMenuItemNaviList
	 * @request GET:/api/bscore/menu-item-navi
	 * @secure
	 */
	bscoreMenuItemNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: MenuItemResource[];
				};
			},
			any
		>({
			path: `/api/bscore/menu-item-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 用於列表頁側邊欄置底的 Menu 和 MenuItem
	 *
	 * @tags bscore_menu
	 * @name BscoreBottomMenuNaviList
	 * @request GET:/api/bscore/bottom-menu-navi
	 * @secure
	 */
	bscoreBottomMenuNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						menus?: MenuResource[];
						menuItems?: MenuItemResource[];
					};
				};
			},
			any
		>({
			path: `/api/bscore/bottom-menu-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_menu
	 * @name BscoreInfoDetail
	 * @summary 提供前端 menu_item_key 的資訊
	 * @request GET:/api/bscore/{menu_item_key}/info
	 * @secure
	 */
	bscoreInfoDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/info`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得後台設定
	 *
	 * @tags bscore
	 * @name BscoreClientSettingsList
	 * @request GET:/api/bscore/client-settings
	 */
	bscoreClientSettingsList = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/client-settings`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_setting_sub_list
	 * @name BscoreSettingsEditDetail
	 * @summary 設定編輯頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/settings/edit
	 * @secure
	 */
	bscoreSettingsEditDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<BscoreImportResult, any>({
			path: `/api/bscore/${menuItemKey}/settings/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_setting_sub_list
	 * @name BscoreSettingsUpdate
	 * @summary 設定編輯頁更新
	 * @request PUT:/api/bscore/{menu_item_key}/settings
	 * @secure
	 */
	bscoreSettingsUpdate = (
		menuItemKey: string,
		data: BscoreSettingsUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<BscoreImportResult, any>({
			path: `/api/bscore/${menuItemKey}/settings`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListSearchCreate
	 * @summary SubList 一般搜尋
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/search
	 * @secure
	 */
	bscoreSubListSearchCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: SearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListCreateDetail
	 * @summary SubList 新增資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/create
	 * @secure
	 */
	bscoreSubListCreateDetail = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/create`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListEditDetail
	 * @summary SubList 編輯資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}/edit
	 * @secure
	 */
	bscoreSubListEditDetail = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListCreate
	 * @summary SubList 新增
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}
	 * @secure
	 */
	bscoreSubListCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdate
	 * @summary SubList 更新
	 * @request PUT:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}
	 * @secure
	 */
	bscoreSubListUpdate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListDelete
	 * @summary SubList 刪除
	 * @request DELETE:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}
	 * @secure
	 */
	bscoreSubListDelete = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListDestroyAllCreate
	 * @summary SubList 批次刪除
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/destroy-all
	 * @secure
	 */
	bscoreSubListDestroyAllCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListDestroyAllCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/destroy-all`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdateRowCreate
	 * @summary SubList 列表單一行更新
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/update-row
	 * @secure
	 */
	bscoreSubListUpdateRowCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListUpdateRowCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/update-row`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdateListCreate
	 * @summary SubList reorder/update status
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/update-list
	 * @secure
	 */
	bscoreSubListUpdateListCreate = (
		{ menuItemKey, id, subListKey, ...query }: BscoreSubListUpdateListCreateRequestParams,
		data: BscoreSubListUpdateListCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/update-list`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreTaskDetail
	 * @summary 取得 task 狀態
	 * @request GET:/api/bscore/task/{task}
	 * @secure
	 */
	bscoreTaskDetail = (task: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						status?: 'WAITING' | 'PROCESSING' | 'FINISH' | 'FAILED';
						result?: BscoreExportResult | BscoreImportResult;
					};
				};
			},
			any
		>({
			path: `/api/bscore/task/${task}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUploadUploadUrlCreate
	 * @summary 取得檔案上傳的 url
	 * @request POST:/api/bscore/upload/upload-url
	 * @secure
	 */
	bscoreUploadUploadUrlCreate = (
		data: BscoreUploadUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						filename?: string;
						/** 上傳用 Url */
						uploadUrl?: string;
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/upload/upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得後台版本資訊
	 *
	 * @tags bscore
	 * @name BscoreVersionList
	 * @request GET:/api/bscore/version
	 * @secure
	 */
	bscoreVersionList = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/version`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
}
