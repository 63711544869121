import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { getShoppingGuides } from 'models/shoppingGuide';

const routes: CustomRoute = {
	path: '/shopping-guide',
	components: () => [import(/* webpackChunkName: 'ShoppingGuide' */ './component')],
	render: ([ShoppingGuide]) => <ShoppingGuide />,
	onEnter: async ({ next, store }) => {
		await store.dispatch(getShoppingGuides());

		const children = await next();

		return children;
	},
};
export default routes;
