import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/reset-mobile',
	components: () => [import(/* webpackChunkName: 'ResetMobile' */ './component')],
	render: ([ResetMobile]) => <ResetMobile />,
};

export default routes;
