/**
 * 載具種類之列舉，發票為個人發票時使用
 *
 * @export
 * @enum {number}
 */
export enum CarrierType {
	/**
	 * 手機條碼
	 */
	PHONE = 'PHONE',

	/**
	 * 自然人憑證
	 */
	NATURAL_PERSON = 'NATURAL_PERSON',

	/**
	 * 會員發票
	 */
	MEMBER = 'MEMBER',

	/**
	 * 捐贈
	 */
	DONATE = 'DONATE',

	/**
	 * 無 : 公司發票
	 */
	NONE = 'NONE',
}
