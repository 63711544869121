/* eslint-disable indent, dot-notation */
import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import history from 'store/history';

import { useRedux } from 'util/hook/redux';
import { FormObject } from 'util/hook/useFormBuilder';
import { api } from 'util/api';
import {
	OrderListResource,
	OrderResource,
	V1UserOrdersListRequestParams,
	V1UserOrderCheckoutCreateRequestPayload,
	ApiResponseTemplate,
} from 'util/api/swaggerApi/data-contracts';

import { ERROR_MESSAGES } from 'enums/validators';
import { InvoiceType } from 'enums/invoiceType';
import { CreateOrderErrorType } from 'enums/createOrderErrorType';
import { OrderConfirmationType } from 'enums/orderConfirmationType';
import { redirectToResultPageByStatus } from 'components/organisms/OrderConfirmationPage/config';
import { errorModalData } from 'components/organisms/CheckoutPage/config';

import { isExist } from 'util/helper';
import { clearCart } from './cart';
import { GetState, State as GlobalState } from './reducers';
import { checkErrorStatus } from './signin';
import { toast } from './toast';

export interface BasePayload {
	[x: string]: any;
	status: number;
	errorCode?: string | null;
	message?: string;
}

export interface ApiError {
	error: BasePayload;
}

interface OrderListPayload {
	userOrderList: OrderListResource[];
	paginationCount: V1UserOrdersListRequestParams['page'];
}

interface OrderDetailPayload {
	userOrderDetail: OrderResource;
}

export const defaultCheckoutFormError = {
	taxNum: '',
};

export interface State {
	loading: boolean;
	userOrderList: OrderListResource[];
	userOrderDetail: OrderResource;
	retryPaymentError: BasePayload;
	paginationCount: V1UserOrdersListRequestParams['page'];
	orderResult: OrderListResource;
	orderPayment: {
		html3DSecure: string;
		virtualAccount: string;
		paymentExpiredAt: string;
	};
	checkoutFormError: {
		taxNum: string;
	};
}

export const defaultState: State = {
	loading: false,
	userOrderList: [],
	userOrderDetail: {},
	retryPaymentError: {
		status: -1,
	},
	paginationCount: 1,
	orderResult: {},
	orderPayment: {
		html3DSecure: '',
		virtualAccount: '',
		paymentExpiredAt: '',
	},
	checkoutFormError: {
		taxNum: '',
	},
};

interface CheckoutFormErrorPayload {
	key: string;
	error?: string;
}

/**
 * 更新結帳資訊表格錯誤
 */
export const updateCheckoutFormError = createAction(
	'UPDATE_CHECKOUT_FORM_ERROR',
	({ key, error }: CheckoutFormErrorPayload) => ({
		key,
		error,
	}),
);

/**
 * 清除結帳資訊表格錯誤
 */
export const clearCheckoutFormError = createAction('CLEAR_FORM', () => defaultCheckoutFormError);

/**
 * 取得會員訂單
 */
export const getUserOrderList = createAction(
	'GET_USER_ORDER_LIST',
	(page: V1UserOrdersListRequestParams) => async (dispatch: Dispatch) => {
		const { v1UserOrdersList } = api;
		try {
			const { data } = await v1UserOrdersList(page);
			const list = data?.data?.data as OrderListResource[] || [];
			const userOrderList = list
				.sort((pre, next) => dayjs(next.createdAt).valueOf() - dayjs(pre.createdAt).valueOf())
				.map(order => ({
					...order,
					createdAt: dayjs(order.createdAt).isValid()
						? dayjs(order.createdAt).format('YYYY.MM.DD')
						: '',
				}));
			const total = data?.data?.meta?.total || 0;
			const paginationCount = Math.ceil(total / 5);

			return { userOrderList, paginationCount };
		} catch (error) {
			dispatch(checkErrorStatus(error as ApiError));
			return { userOrderList: [], paginationCount: 1 };
		}
	},
);

export const getUserOrderDetailById = createAction<
	(dispatch: Dispatch) => Promise<OrderDetailPayload>,
	number
>('GET_USER_ORDER_DETAIL_BY_ID', id => async (dispatch: Dispatch) => {
	const { v1UserOrderDetail } = api;
	try {
		const { data, status } = await v1UserOrderDetail(id);
		if (status === 200) {
			const orderDetail = data?.data || {};
			const userOrderDetail: OrderResource = {
				...orderDetail,
				createdAt: dayjs(orderDetail.createdAt).isValid()
					? dayjs(orderDetail.createdAt).format('YYYY/MM/DD')
					: '',
				invoiceDate: dayjs(orderDetail.invoiceDate).isValid()
					? dayjs(orderDetail.invoiceDate).format('YYYY/MM/DD')
					: '',
				paymentExpiredAt: dayjs(orderDetail.paymentExpiredAt).isValid()
					? dayjs(orderDetail.paymentExpiredAt).format('YYYY/MM/DD HH:mm')
					: '',
			};
			return {
				userOrderDetail,
			};
		}
		return { userOrderDetail: {} };
	} catch (error) {
		dispatch(checkErrorStatus(error as ApiError));
		return { userOrderDetail: {} };
	}
});

/**
 * 建立新訂單
 */
const createOrderProcess = createAction(
	'CREATE_ORDER_PROCESS',
	(orderForm: FormObject) => async (dispatch: Dispatch, getState: GetState) => {
		await dispatch(createOrder(orderForm));
		const {
			order: { orderResult },
		} = getState();

		// 建立訂單成功後，透過回傳的 orderId 進行付款
		if (isExist(orderResult.id)) {
			await dispatch(clearCart());
			await dispatch(orderPayment(orderResult.id as number));
		}

		const {
			order: {
				orderResult: {
					orderNo,
				},
				orderPayment: {
					virtualAccount,
				},
			}
		} = getState();

		// ATM 付款，由前端直接轉導至結果頁
		if (isExist(orderNo) && isExist(virtualAccount)) {
			redirectToResultPageByStatus({
				status: OrderConfirmationType.WAITING_FOR_TRANSFER,
				orderId: orderResult.id as number,
				orderNo: orderNo as string,
			});
		}
	}
);

const createOrder = createAction(
	'CREATE_ORDER',
	(orderForm: FormObject) => async (dispatch: Dispatch) => {
		const { v1UserOrderCheckoutCreate } = api;

		try {
			const requestData: V1UserOrderCheckoutCreateRequestPayload['order'] = {
				'buyer_name': orderForm.buyerName.value,
				'buyer_country': orderForm.buyerCountry.value,
				'buyer_city': orderForm.buyerCity.value,
				'buyer_zone': orderForm.buyerArea.value,
				'buyer_address': orderForm.buyerAddress.value,
				'order_note': orderForm.orderRemark.value,
				'recipient_name': orderForm.name.value,
				'recipient_mobile': orderForm.mobile.value,
				'recipient_country': orderForm.country.value,
				'recipient_city': orderForm.city.value,
				'recipient_zone': orderForm.area.value,
				'recipient_address': orderForm.address.value,
				'invoice_type': orderForm.invoiceType.value,
				'payment_type': orderForm.payMethod.value,
			};

			if (orderForm.invoiceType.value === InvoiceType.ELECTRONIC) {
        requestData['carrier_no'] = orderForm.mobileBarcode.value;
			} else if (orderForm.invoiceType.value === InvoiceType.COMPANY) {
					requestData['tax_name'] = orderForm.companyName.value;
					requestData['tax_num'] = orderForm.taxId.value;
			}

			console.log('createOrder', orderForm, requestData);

			const requestPromise = new Promise(resolve => {
        const response = v1UserOrderCheckoutCreate({ order: requestData });
				resolve(response);
      });

			// 30 秒沒得到回應，視為 timeout
			const timeoutPromise = new Promise((_resolve, reject) => {
				setTimeout(() => {
					reject(new Error('timeout'));
				}, 30000);
			});

			const { status, data } = await Promise.race([requestPromise, timeoutPromise]) as ApiResponseTemplate;

			if (status === 200) {
				return data?.data;
			}
			return defaultState.orderResult;
		} catch (e) {
			const errorCode = (e as ApiError)?.error?.errorCode || '';

			// 情境為 too_many_attempts 訂單異常時，前台顯示之錯誤文字由前端帶入
			if (isExist(errorCode) && errorCode === 'too_many_attempts') {
				redirectToResultPageByStatus({
					status: OrderConfirmationType.ORDER_FAIL,
					orderMessage: '系統偵測到異常訂單行為，請聯絡客服專線，謝謝!',
				});
			} else if (isExist(errorCode) && errorCode === 'ECPAY_TAX_NUMBER_ERROR') {
				return dispatch(
					updateCheckoutFormError({ key: 'taxNum', error: ERROR_MESSAGES.TAX_NUMBER }),
				);
			} else {
				const errorMessage = isExist(errorModalData[errorCode as CreateOrderErrorType]?.content)
					? errorModalData[errorCode as CreateOrderErrorType]?.content
					: '系統偵測到網路異常，%0A請確認網路環境後再次提交訂單。';

				redirectToResultPageByStatus({
					status: OrderConfirmationType.ORDER_FAIL,
					orderMessage: errorMessage,
				});
			}

			return defaultState.orderResult;
		}
	}
);

const orderPayment = createAction(
	'ORDER_PAYMENT',
	(orderId: number) => async (dispatch: Dispatch) => {
		const { v1UserOrderPaymentCreate } = api;
		try {
			const { status, data } = await v1UserOrderPaymentCreate({ order_id: orderId });

			history.push({ pathname: '/payment3DSecure' });

			if (status === 200) {
				return {
					...defaultState.orderPayment,
					...data?.data,
					html3DSecure: (data as { data: { paymentUrl: string; } } )?.data?.paymentUrl || '',
				};
			}
			return defaultState.orderPayment;
		} catch (e) {
			if (isExist((e as ApiError)?.error?.message)) {
				dispatch(
					toast({
						id:  nanoid(),
						message: (e as ApiError)?.error?.message || '',
						type: 'warn',
						color: 'warning',
					}),
				);
			}

			const errorCode = (e as ApiError)?.error?.errorCode || '';
			const orderNo = (e as ApiError)?.error?.extra?.orderNo || '';

			// 已完成付款之訂單，導去付款成功頁
			if (isExist(errorCode) && errorCode === 'ORDER_ALREADY_PAYED') {
				redirectToResultPageByStatus({
					status: OrderConfirmationType.PAYMENT_SUCCESSFUL,
					orderNo,
					orderMessage: (e as ApiError)?.error?.message,
				});
			} else {
				redirectToResultPageByStatus({
					status: OrderConfirmationType.PAYMENT_FAIL,
					// 僅有可再重新付款的訂單才會帶入 orderId
					orderId: errorCode === 'ORDER_PAYMENT_FAILED' ? orderId : undefined,
					orderNo,
					orderMessage: (e as ApiError)?.error?.message,
				});
			}
			return defaultState.orderPayment;
		}
	}
);

export const clearUserOrderDetail = createAction('CLEAR_USER_ORDER_DETAIL');

export const reducer = {
	order: handleActions<State, any>(
		{
			GET_USER_ORDER_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_USER_ORDER_LIST_FULFILLED: (state, action: Action<OrderListPayload>) => ({
				...state,
				userOrderList: action.payload.userOrderList,
				paginationCount: action.payload.paginationCount,
				loading: false,
			}),
			CLEAR_USER_ORDER_DETAIL: state => ({
				...state,
				userOrderDetail: {
					...defaultState.userOrderDetail,
				},
			}),
			GET_USER_ORDER_DETAIL_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_USER_ORDER_DETAIL_BY_ID_FULFILLED: (state, action: Action<OrderDetailPayload>) => ({
				...state,
				userOrderDetail: action.payload.userOrderDetail,
				loading: false,
			}),
			CREATE_ORDER_PENDING: state => ({
				...state,
				loading: true,
			}),
			CREATE_ORDER_FULFILLED: (state, action: Action<OrderListResource>) => ({
				...state,
				orderResult: action.payload,
				loading: false,
			}),
			ORDER_PAYMENT_PENDING: state => ({
				...state,
				loading: true,
			}),
			ORDER_PAYMENT_FULFILLED: (state, action: Action<State['orderPayment']>) => ({
				...state,
				orderPayment: action.payload,
				loading: false,
			}),
			SET_RETRY_PAYMENT_ERROR: (state, action: Action<BasePayload>) => ({
				...state,
				retryPaymentError: action.payload,
			}),
			RETRY_PAYMENT_PENDING: state => ({
				...state,
				loading: true,
			}),
			RETRY_PAYMENT_FULFILLED: state => ({
				...state,
				loading: false,
			}),
			UPDATE_CHECKOUT_FORM_ERROR: (state, action) => ({
				...state,
				checkoutFormError: {
					...state.checkoutFormError,
					[action.payload.key]: action.payload.error,
				},
			}),
			CLEAR_FORM: (state, action) => ({
				...state,
				checkoutFormError: action.payload,
			}),
		},
		defaultState,
	),
};

const orderActionsMap = {
	getUserOrderList,
	getUserOrderDetailById,
	createOrderProcess,
	orderPayment,
	updateCheckoutFormError,
	clearCheckoutFormError,
};

const mapHooksToState = (state: GlobalState) => ({
	loading: state.order.loading,
	userOrderList: state.order.userOrderList,
	userOrderDetail: state.order.userOrderDetail,
	retryPaymentError: state.order.retryPaymentError,
	paginationCount: state.order.paginationCount,
	orderResult: state.order.orderResult,
	orderPayment: state.order.orderPayment,
	checkoutFormError: state.order.checkoutFormError,
});

type orderSelector = ReturnType<typeof mapHooksToState>;
type orderActionsMap = typeof orderActionsMap;

export const useOrder = () =>
	useRedux<orderSelector, orderActionsMap>(mapHooksToState, orderActionsMap);
