import React, { useCallback, useMemo, useState, useEffect } from 'react';

import Modal from 'components/atoms/Modal';
import Button from 'components/atoms/Button';

import { useToast } from 'models/toast';
import { useCart } from 'models/cart';
import { useHistory } from 'models/routing';
import { useMember } from 'models/member';
import { ModalTypes, useModal } from 'models/modal';

import useMedia from 'util/hook/useMedia';
import pushHistory from 'util/pushHistory';
import { useProductsForCart } from 'util/hook/useProducts';
import formatWithThousandsSeparator from 'util/formatWithThousandsSeparator';

import FrozenIcon from 'images/icon/frozen-sm.inline.svg';
import TrashIcon from 'images/icon/trash.inline.svg';
import classnames from 'classnames';
import styles from './index.module.css';

export interface ModalSideCartProperty {
	title: string;
}

interface Item {
	price?: number;
	count: number;
}

interface ProductBlockProperty {
	id?: number;
	productName: string;
	count: number;
	unitPrice: number;
	image: string;
	// materialNo?: string;
	// briefDescription?: string;
	// stateLabel?: string;
	isCombination?: boolean;
	productDescription?: string;
	shippingType?: string;
}

const ProductBlock: React.FC<ProductBlockProperty> = ({
	id,
	productName,
	count,
	unitPrice,
	image,
	productDescription,
	isCombination,
	shippingType,
}) => {
	const [, { toast }] = useToast();
	const [, { removeCartProductById }] = useCart();
	const [, { closeModal }] = useModal();

	const history = useHistory();
	const handleClickProduct = () => {
		const path = `/products/${id}`;
		pushHistory(history, path);
		window.scrollTo(0, 0);
		closeModal();
	};

	return (
		<div
			className={styles.productBlock}
			onKeyDown={() => {}}
			role="button"
			tabIndex={0}
			onClick={handleClickProduct}
		>
			<img src={image} alt="" />
			{shippingType === 'FROZEN' && (
				<div className={styles.frozenLabel}>
					<FrozenIcon />
				</div>
			)}
			<div className={styles.info}>
				<div className={styles.top}>
					{/* {stateLabel && (
						<ProductLabel
							text={stateLabel}
							textColor="#8C7DBE"
							borderColor="#8C7DBE"
							className={styles.productState}
							size="xs"
						/>
					)} */}
					<div
						className={classnames(styles.name, { [styles.withBadge]: shippingType === 'FROZEN' })}
					>
						{productName}
					</div>
				</div>
				<div className={styles.description}>{productDescription}</div>
				<div className={styles.price}>
					NT${formatWithThousandsSeparator(unitPrice)} x {count}
				</div>
			</div>
			<button
				type="button"
				className={styles.trashcan}
				onClick={e => {
					// gtmAddToOrRemovefromCart(-1, productName, materialNo, unitPrice, 0, briefDescription);
					e.stopPropagation();
					removeCartProductById(id, isCombination);
					toast({
						id: 'side-cart-remove',
						message: '已移除商品',
						type: 'success',
						color: 'green',
					});
				}}
			>
				<TrashIcon />
			</button>
		</div>
	);
};

const ModalSideCart: React.FC<ModalSideCartProperty> = ({ title }) => {
	const products = useProductsForCart();
	const calculateItemTotalPrice = useCallback((item: Item) => (item.price ?? 0) * item.count, []);
	const totalPrice = useMemo(
		() => products.reduce((acc, item) => acc + calculateItemTotalPrice(item), 0),
		[products, calculateItemTotalPrice],
	);

	const [{ ecUserToken }] = useMember();
	const history = useHistory();
	const [, { closeModal }] = useModal();
	const media = useMedia();

	const handleOnClick = () => {
		if (ecUserToken) {
			pushHistory(history, '/cart');
			closeModal(ModalTypes.ModalSideCart);
			window.scrollTo(0, 0);
		} else {
			closeModal(ModalTypes.ModalSideCart);
			pushHistory(history, '/signin?redirect=/cart');
			window.scrollTo(0, 0);
		}
	};

	const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

	useEffect(() => {
		const handleResize = () => {
			setViewportHeight(window.innerHeight);
		};

		window.addEventListener('resize', handleResize);
		window.addEventListener('orientationchange', handleResize);

		// Clean up the event listeners on unmount
		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('orientationchange', handleResize);
		};
	}, []);

	const productsToDisplay = products.filter(
		item => item.productType === 'NORMAL' || item.productType === 'COMBINATION',
	);

	const cartCalculate = useMemo(
		() =>
			productsToDisplay.length ? productsToDisplay.reduce((acc, item) => acc + item.count, 0) : 0,
		[productsToDisplay],
	);

	return (
		<Modal
			type={ModalTypes.ModalSideCart}
			cartCalculate={cartCalculate}
			showCloseButton
			title={title}
			className={{
				backdrop: styles.backdrop,
				title: styles.modalTitle,
				modal: styles.modal,
				wrapper: styles.modalWrapper,
				closeBtn: styles.closeBtn,
			}}
		>
			{productsToDisplay.length ? (
				<>
					<div
						className={styles.modalContent}
						style={{ height: `calc(${viewportHeight}px - ${media === 'desktop' ? 250 : 300}px)` }}
					>
						{productsToDisplay.map(item => (
							<ProductBlock
								productName={item.productName}
								count={item.count}
								unitPrice={item.price || 0}
								productDescription={item.briefDescription}
								image={item.image}
								key={item.productName}
								id={item.id}
								shippingType={item.shippingType}
							/>
						))}
						{/* <ProductBlock
							productName="小月綜"
							count={2}
							unitPrice={500}
							image={Image}
							productDescription="蛋黃酥 x 3 / 檸月酥 x 3"
						/> */}
					</div>
					<div className={styles.modalFooter}>
						<div className={styles.totalPrice}>
							<span>小計</span>
							<span>NT$ {formatWithThousandsSeparator(totalPrice)}</span>
						</div>
						<Button
							pattern="fill"
							name="去結帳"
							handleClick={handleOnClick}
							className={classnames(styles.checkout)}
						/>
					</div>
				</>
			) : (
				<div className={styles.emptyText}>您的購物車是空的</div>
			)}
		</Modal>
	);
};

export default ModalSideCart;
