/**
 * 建立新訂單錯誤之列舉
 *
 * @export
 * @enum {number}
/**
 *
 *
 * @export
 * @enum {number}
 */
export enum CreateOrderErrorType {
	/**
	 * 查無暫存訂單資料
	 */
	SHOPPING_CART_NOT_FOUND = 'SHOPPING_CART_NOT_FOUND',

	/**
	 * 訂單成立失敗
	 */
	ORDER_CREATE_FAILED = 'ORDER_CREATE_FAILED',

	/**
	 * 查無優惠券資料
	 */
	COUPON_NOT_FOUND = 'COUPON_NOT_FOUND',

	/**
	 * 綠界服務發生異常
	 */
	ECPAY_INVOICE_ERROR = 'ECPAY_INVOICE_ERROR',

	/**
	 * 手機載具條碼錯誤
	 */
	ECPAY_CARRIER_ERROR = 'ECPAY_CARRIER_ERROR',

	/**
	 * 統一編號錯誤
	 */
	ECPAY_TAX_NUMBER_ERROR = 'ECPAY_TAX_NUMBER_ERROR',

	/**
	 * 暫存訂單資料不齊全
	 */
	SHOPPING_CART_DATA_INVALID = 'SHOPPING_CART_DATA_INVALID',

	/**
	 * 很抱歉，您所選擇自取/宅配時間已售完，\\n請點擊下方按鈕以重新選擇時間。
	 */
	CAPACITY_INSUFFICIENT = 'CAPACITY_INSUFFICIENT',
}
