import React from 'react';
import styles from './index.module.css';

const LoadingSpinner: React.FC = () => (
	<div className={styles.spinnerContainer}>
		<div className={styles.loadingSpinner} />
		<p>日曆載入中，請稍候...</p>
	</div>
);

export default LoadingSpinner;
