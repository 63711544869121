export enum DeliveryTimeType {
	/**
	 * 未指定
	 */
	NONE = 'NONE',

	/**
	 * 13點前
	 */
	BEFORE_13_HOURS = 'BEFORE_13_HOURS',

	/**
	 * 14點後
	 */
	AFTER_14_HOURS = 'AFTER_14_HOURS',

	/**
	 * 12點前
	 */
	TO_12_HOURS = 'TO_12_HOURS',

	/**
	 * 13點前
	 */
	TO_13_HOURS = 'TO_13_HOURS',

	/**
	 * 14點前
	 */
	TO_14_HOURS = 'TO_14_HOURS',

	/**
	 * 15點前
	 */
	TO_15_HOURS = 'TO_15_HOURS',

	/**
	 * 16點前
	 */
	TO_16_HOURS = 'TO_16_HOURS',

	/**
	 * 17點前
	 */
	TO_17_HOURS = 'TO_17_HOURS',

	/**
	 * 18點前
	 */
	TO_18_HOURS = 'TO_18_HOURS',
}

export const getDeliveryTimeText = (deliveryTimeType: DeliveryTimeType): string => {
	switch (deliveryTimeType) {
		case DeliveryTimeType.NONE:
			return '不指定';
		case DeliveryTimeType.BEFORE_13_HOURS:
			return '13:00 前';
		case DeliveryTimeType.AFTER_14_HOURS:
			return '14:00 ~ 18:00';
		case DeliveryTimeType.TO_12_HOURS:
			return '11:00 ~ 12:00';
		case DeliveryTimeType.TO_13_HOURS:
			return '12:00 ~ 13:00';
		case DeliveryTimeType.TO_14_HOURS:
			return '13:00 ~ 14:00';
		case DeliveryTimeType.TO_15_HOURS:
			return '14:00 ~ 15:00';
		case DeliveryTimeType.TO_16_HOURS:
			return '15:00 ~ 16:00';
		case DeliveryTimeType.TO_17_HOURS:
			return '16:00 ~ 17:00';
		case DeliveryTimeType.TO_18_HOURS:
			return '17:00 ~ 18:00';
		default:
			return '';
	}
};
