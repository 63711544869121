/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
	interface Window {
		fbAsyncInit: () => void;
		FB: any;
	}
}

const insertScript = (id: string, src: string, options = { async: true, defer: true }) => {
	if (document.getElementById(id)) {
		return 1;
	}

	const fjs = document.getElementsByTagName('script')[0];
	const js = document.createElement('script');

	js.id = id;
	js.src = src;

	if (options.async) {
		js.async = true;
	}

	if (options.defer) {
		js.defer = true;
	}

	fjs?.parentNode?.insertBefore(js, fjs);
	return 0;
};

window.fbAsyncInit = window.fbAsyncInit || {};

const { FACEBOOK_ID } = process.env;

export const loadFacebookLibrary = () =>
	new Promise<void>(resolve => {
		window.fbAsyncInit = () => {
			window.FB.init({
				appId: FACEBOOK_ID,
				cookie: true,
				xfbml: true,
				version: 'v7.0',
			});
			window.FB.AppEvents.logPageView();
			resolve();
		};

		const result = insertScript('facebook-jssdk', 'https://connect.facebook.net/en_US/sdk.js');

		// Already insert
		if (result === 1) {
			resolve();
		}
	});
