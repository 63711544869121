import React from 'react';
import history from 'store/history';

import { setCartData } from 'models/cart';

import { CustomRoute } from 'util/hook/useRouter';
import { getEcUserByStorage, removeItem } from 'util/storage';
import pushHistory from 'util/pushHistory';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/checkout',
	components: () => [import(/* webpackChunkName: 'Checkout' */ './component')],
	render: ([Checkout]) => <Checkout />,
	onEnter: async ({ next, store }) => {
		const ecUserToken = getEcUserByStorage();

		if (!ecUserToken) {
			pushHistory(history, `/signin`);
			window.scrollTo(0, 0);
			return null;
		}
		await store.dispatch(setCartData());

		const children = await next();
		return children;
	},
};
export default routes;
