import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { getFaqs } from 'models/shoppingGuide';

const routes: CustomRoute = {
	path: '/faq',
	components: () => [import(/* webpackChunkName: 'frequentlyAskedQuestions' */ './component')],
	render: ([FrequentlyAskedQuestions]) => <FrequentlyAskedQuestions />,
	onEnter: async ({ next, store }) => {
		await store.dispatch(getFaqs());

		const children = await next();

		return children;
	},
};
export default routes;
