import { Dispatch } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';
import { createSelector } from 'reselect';
import history from 'store/history';
// import dayjs from 'dayjs';
import { nanoid } from 'nanoid';

// import { HeaderDropdownData } from 'components/organisms/HeaderDropdown';

import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import {
	UserInvoiceResource,
	UserRecipientResource,
	UserResource,
	V1UserUpdateRequestParams,
	V1UserUnlinkSocialiteUpdateRequestPayload,
} from 'util/api/swaggerApi/data-contracts';

import { MemberFeatureType } from 'enums/memberFeatureType';
import { ERROR_CODE, ERROR_MESSAGES } from 'enums/form';
import pushHistory from 'util/pushHistory';

// import { ApiError } from 'types/apiError';

import { GetState, State as GlobalState } from './reducers';
import { closeModal } from './modal';
import { toast } from './toast';

import { getUserCouponList } from './coupon';
import { getUserOrderList } from './order';
import { checkErrorStatus, ApiError } from './signin';

export type HeaderDropdownData = {
	id: number;
	name: string;
	expandData: HeaderDropdownData[];
	icon?: string;
	to: string;
	externalLink: string;
	isOpen?: boolean;
};

/**
 * 會員功能列表
 */
export const memberFeatureList: HeaderDropdownData[] = [
	{
		id: MemberFeatureType.ACCOUNT,
		name: '帳戶設定',
		to: `/member-centre?type=${MemberFeatureType.ACCOUNT}`,
		externalLink: '',
		icon: 'Account',
		expandData: [],
	},
	{
		id: MemberFeatureType.ORDERS,
		name: '我的訂單',
		to: `/member-centre?type=${MemberFeatureType.ORDERS}`,
		externalLink: '',
		icon: 'Order',
		expandData: [],
	},
	{
		id: MemberFeatureType.COUPONS,
		name: '我的優惠券',
		to: `/member-centre?type=${MemberFeatureType.COUPONS}`,
		externalLink: '',
		icon: 'Coupon',
		expandData: [],
	},
];

interface UserInfoPayload {
	userInfo: UserResource;
}

interface RecipientInfoPayload {
	homeRecipientList: UserRecipientResource[];
	storeRecipientList: UserRecipientResource[];
}

interface InvoiceInfoPayload {
	personInvoiceList: UserInvoiceResource[];
	companyInvoiceList: UserInvoiceResource[];
}

export interface LevelRule {
	level?: number | undefined;
	money?: string | undefined;
	levelDiscount?: number | undefined;
}

// For Global State usage
export interface State {
	loading: boolean;

	/**
	 * 登入後取得的 token，可根據此來判斷是否登入
	 *
	 * @type {string}
	 * @memberof State
	 */
	ecUserToken: string;

	/**
	 * 會員資訊
	 *
	 * @type {UserResource}
	 * @memberof State
	 */
	userInfo: UserResource;

	/**
	 * 宅配收件人資訊
	 *
	 * @type {UserRecipientResource[]}
	 * @memberof UserInfoPayload
	 */
	homeRecipientList: UserRecipientResource[];

	/**
	 * 超商取貨收件人資訊
	 *
	 * @type {UserRecipientResource[]}
	 * @memberof UserInfoPayload
	 */
	storeRecipientList: UserRecipientResource[];

	/**
	 * 個人發票資訊
	 *
	 * @type {UserInvoiceResource[]}
	 * @memberof State
	 */
	personInvoiceList: UserInvoiceResource[];

	/**
	 * 公司用發票資訊
	 *
	 * @type {UserInvoiceResource[]}
	 * @memberof State
	 */
	companyInvoiceList: UserInvoiceResource[];

	levelRules: LevelRule[];
	changeEmailStatus: BasePayload;
	changePasswordStatus: BasePayload;
	userUpdateStatus: BasePayload;
	unBindSocialiteStatus: BasePayload;
}

interface BasePayload {
	status: number;
	errorCode?: string | null;
	message?: undefined;
}

interface Error {
	error: BasePayload;
}

export const defaultState: State = {
	loading: false,
	ecUserToken: '',
	userInfo: {},
	homeRecipientList: [],
	storeRecipientList: [],
	personInvoiceList: [],
	companyInvoiceList: [],
	levelRules: [],
	changeEmailStatus: {
		status: 0,
		errorCode: null,
	},
	changePasswordStatus: {
		status: 0,
		errorCode: null,
	},
	userUpdateStatus: {
		status: 0,
		errorCode: null,
	},
	unBindSocialiteStatus: {
		status: 0,
		errorCode: null,
	},
};

interface normalSignupPayload {
	oldPassword: string;
	password: string;
	secondPassword: string;
}

interface facebookBindPayload {
	email: string;
	name: string;
	socialiteToken: string;
	mobile: string;
}

/**
 * 根據會員中心頁面取得相對應資料
 */
const getMemberCentreDataByType = createAction<void, MemberFeatureType>(
	'GET_MEMBER_CENTRE_DATA_BY_TYPE',
	type => (dispatch: Dispatch) => {
		switch (type) {
			case MemberFeatureType.ACCOUNT:
				dispatch(getUserInfo());
				// 	dispatch(getLevelRules());
				break;
			case MemberFeatureType.ORDERS:
				dispatch(getUserOrderList({ page: 1 }));
				break;
			case MemberFeatureType.COUPONS:
				dispatch(getUserCouponList({ page: 1, status: 'RECEIVED' }));
				break;
			default:
				break;
		}
	},
);

/**
 * 取得會員資料
 */
export const getUserInfo = createAction('GET_USER_INFO', () => async (dispatch: Dispatch) => {
	const { v1UserList } = api;
	try {
		const { data } = await v1UserList();
		const userInfo = data?.data || {};
		const newUserInfo: UserResource = {
			...userInfo,
		};
		return { userInfo: newUserInfo };
	} catch (error) {
		dispatch(checkErrorStatus(error as ApiError));
		return { userInfo: {} };
	}
});

/**
 * 清除會員資料
 */
export const clearUserInfo = createAction('CLEAR_USER_INFO');

/**
 * 更新會員資料
 */
export const updateUserInfo = createAction(
	'UPDATE_USER_INFO',
	(userInfo: V1UserUpdateRequestParams) => async (dispatch: Dispatch, getState: GetState) => {
		const { v1UserUpdate } = api;
		const {
			member: { userInfo: oldUserInfo },
		} = getState();

		const result: UserInfoPayload = {
			userInfo: oldUserInfo,
		};
		try {
			const { status, data } = await v1UserUpdate(userInfo);
			if (status === 200) {
				dispatch(closeModal());
				const id = nanoid();
				dispatch(
					toast({
						id,
						message: '已更新會員資料',
						type: 'success',
						color: 'green',
					}),
				);
				window.scrollTo(0, 0);
				const newUserInfo = data?.data || {};
				result.userInfo = {
					...newUserInfo,
				};
			}
			return result;
		} catch (error) {
			dispatch(checkErrorStatus(error as ApiError));
			return { userInfo: oldUserInfo };
		}
	},
);

export const checkUserUpdateError = createAction(
	'CHECK_USER_UPDATE_STATUS',
	async (e: ApiError) => ({
		status: (e as Error).error.status,
		errorCode: (e as Error).error.errorCode,
	}),
);

export const changeEmail = createAction(
	'CHANGE_EMAIL',
	(email: string) => async (dispatch: Dispatch<any>) => {
		try {
			const { v1UserChangeEmailUpdate } = api;
			const { status } = await v1UserChangeEmailUpdate({ email });

			// if (status === 200 && usage === 'RESET_PASSWORD') {
			// 	window.location.href = `/email-verify?email=${email}&type=RESET_PASSWORD`;
			// }

			return { status };
		} catch (e) {
			// dispatch(openModal(ModalTypes.ErrorModal));
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const changePassword = createAction(
	'CHANGE_PASSWORD',
	(formData: normalSignupPayload) => async (dispatch: Dispatch) => {
		try {
			const { v1UserChangePasswordUpdate } = api;
			const { password, secondPassword, oldPassword } = formData;
			const { status } = await v1UserChangePasswordUpdate({
				confirm_password: secondPassword,
				new_password: password,
				old_password: oldPassword,
			});
			if (status === 200) {
				pushHistory(history, '/member-centre');
				window.scrollTo(0, 0);

				const id = nanoid();
				dispatch(
					toast({
						id,
						message: '已變更密碼',
						type: 'success',
						color: 'green',
					}),
				);
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

const facebookNativeLogin = () =>
	new Promise((resolve, reject) => {
		window.FB.login(
			(response: any) => {
				if (response.status === 'connected') {
					resolve(response);
				} else {
					console.log(response);
				}
			},
			{ scope: 'public_profile,email' },
		);
	});

/**
 * 會員中心 Facebook 綁定流程
 */
export const verifyAndBindFacebook = createAction(
	'SIGNUP_FACEBOOK',
	(mobile: string, name: string, email: string) =>
		async (dispatch: Dispatch<any>): Promise<object | null> => {
			const auth: any = await facebookNativeLogin();

			const { accessToken } = auth.authResponse;

			try {
				const { v1AuthFacebookVerifyCreate } = api;
				const { status, data } = await v1AuthFacebookVerifyCreate({
					token: accessToken,
					mobile,
				});

				if (status === 200 && data?.data) {
					const { socialiteToken } = data?.data as {
						name: string;
						email: string;
						socialiteToken: string;
					};

					const facebookBind = {
						email,
						name,
						socialiteToken,
						mobile,
					};

					await dispatch(bindFacebook(facebookBind));
				}

				return null;
			} catch (error) {
				dispatch(checkFacebookError(error as ApiError));
				return null;
			}
		},
);

export const bindFacebook = createAction(
	'BIND_FACEBOOK',
	(formData: facebookBindPayload) =>
		async (dispatch: Dispatch<any>): Promise<object | null> => {
			try {
				const { name, email, socialiteToken, mobile } = formData;

				const { v1AuthFacebookLinkCreate } = api;
				const { status } = await v1AuthFacebookLinkCreate({
					email,
					name,
					mobile,
					socialite_token: socialiteToken,
				});

				if (status === 200) {
					window.scrollTo(0, 0);
					dispatch(getUserInfo());
					const id = nanoid();
					dispatch(
						toast({
							id,
							message: '已成功綁定 Facebook',
							type: 'success',
							color: 'green',
						}),
					);
				}
				return { status };
			} catch (e) {
				const { message } = (e as Error).error;

				window.scrollTo(0, 0);
				const id = nanoid();
				return dispatch(
					toast({
						id,
						message: message || 'Facebook 綁定失敗', // Add a default value for the message variable
						type: 'warn',
						color: 'warning',
					}),
				);
			}
		},
);

export const checkFacebookError = createAction(
	'CHECK_FACEBOOK_STATUS',
	(e: ApiError) => async (dispatch: Dispatch<any>) => {
		const { errorCode, message } = (e as Error).error;

		if (errorCode === ERROR_CODE.FACEBOOK_ACCOUNT_ALREADY_BIND) {
			history.push({
				pathname: '/member-centre',
				search: 'type=1',
			});

			window.scrollTo(0, 0);
			const id = nanoid();

			dispatch(
				toast({
					id,
					message: ERROR_MESSAGES.FACEBOOK_ACCOUNT_ALREADY_BIND,
					type: 'warn',
					color: 'warning',
				}),
			);
		} else {
			window.scrollTo(0, 0);
			const id = nanoid();
			dispatch(
				toast({
					id,
					message: message || '', // Add a default value for the message variable
					type: 'warn',
					color: 'warning',
				}),
			);
		}
	},
);

/**
 * 解除綁定 Facebook
 */

export const unBindSocialite = createAction(
	'UN_BIND_SOCIALITE',
	({ type, password }: V1UserUnlinkSocialiteUpdateRequestPayload) =>
		async (dispatch: Dispatch<any>) => {
			try {
				const { v1UserUnlinkSocialiteUpdate } = api;
				const { status } = await v1UserUnlinkSocialiteUpdate({ type, password });

				if (status === 200) {
					dispatch(closeModal());

					window.scrollTo(0, 0);
					dispatch(getUserInfo());
					const id = nanoid();
					dispatch(
						toast({
							id,
							message: type === 'FACEBOOK' ? '已取消綁定 Facebook' : '已取消綁定 Line',
							type: 'success',
							color: 'green',
						}),
					);
				}

				return { status };
			} catch (error) {
				return {
					status: (error as Error).error.status,
					errorCode: (error as Error).error.errorCode,
				};
			}
		},
);

// 	'DELETE_INVOICE_BY_ID',
// 	(invoiceId: number) => async (dispatch: Dispatch) => {
// 		const { v1UserInvoiceDelete } = api;
// 		try {
// 			const { status } = await v1UserInvoiceDelete(invoiceId);

// 			if (status === 200) {
// 				const id = nanoid();
// 				dispatch(
// 					toast({
// 						id,
// 						message: '資料刪除成功',
// 						type: 'success',
// 						color: 'green',
// 					}),
// 				);
// 				dispatch(getInvoiceList());
// 			}
// 		} catch (error) {
// 			console.log(error);
// 		}
// 	},
// );

// export const getLevelRules = createAction('GET_LEVEL_RULES', () => async (dispatch: Dispatch) => {
// 	const { v1UserLevelRulesList } = api;
// 	try {
// 		const { data, status } = await v1UserLevelRulesList();
// 		if (status === 200) {
// 			return data?.data || [];
// 		}
// 		return [];
// 	} catch (error) {
// 		dispatch(checkErrorStatus(error as ApiError));
// 		return [];
// 	}
// });

export const setEcUserToken = createAction<string, string>(
	'SET_EC_USER_TOKEN',
	token => token || '',
);

export const reducer = {
	member: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_USER_INFO_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_USER_INFO_FULFILLED: (state, action: Action<UserInfoPayload>) => ({
				...state,
				userInfo: action.payload.userInfo,
				loading: false,
			}),
			SET_EC_USER_TOKEN: (state, action: Action<string>) => ({
				...state,
				ecUserToken: action.payload,
			}),
			UPDATE_USER_INFO_PENDING: state => ({
				...state,
				loading: true,
			}),
			UPDATE_USER_INFO_FULFILLED: (state, action: Action<UserInfoPayload>) => ({
				...state,
				userInfo: action.payload.userInfo,
				loading: false,
			}),
			GET_RECIPIENT_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_RECIPIENT_LIST_FULFILLED: (state, action: Action<RecipientInfoPayload>) => ({
				...state,
				homeRecipientList: action.payload.homeRecipientList,
				storeRecipientList: action.payload.storeRecipientList,
				loading: false,
			}),
			GET_INVOICE_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_INVOICE_LIST_FULFILLED: (state, action: Action<InvoiceInfoPayload>) => ({
				...state,
				personInvoiceList: action.payload.personInvoiceList,
				companyInvoiceList: action.payload.companyInvoiceList,
				loading: false,
			}),
			GET_LEVEL_RULES_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_LEVEL_RULES_FULFILLED: (state, action: Action<LevelRule[]>) => ({
				...state,
				levelRules: action.payload,
				loading: false,
			}),
			CLEAR_USER_INFO: state => ({
				...state,
				userInfo: {
					...defaultState.userInfo,
				},
			}),
			CHANGE_EMAIL_PENDING: state => ({
				...state,
				loading: true,
			}),
			CHANGE_EMAIL_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				changeEmailStatus: action.payload,
				loading: false,
			}),
			CHANGE_EMAIL_REJECTED: state => ({
				...state,
				loading: false,
			}),
			CHANGE_PASSWORD_PENDING: state => ({
				...state,
				loading: true,
			}),
			CHANGE_PASSWORD_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				changePasswordStatus: action.payload,
				loading: false,
			}),
			CHANGE_PASSWORD_REJECTED: state => ({
				...state,
				loading: false,
			}),
			CHECK_USER_UPDATE_STATUS_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				userUpdateStatus: action.payload,
				loading: false,
			}),
			UN_BIND_SOCIALITE_PENDING: state => ({
				...state,
				loading: true,
			}),
			UN_BIND_SOCIALITE_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				unBindSocialiteStatus: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const loadingSelector = (state: GlobalState) => state.member.loading;
const ecUserTokenSelector = (state: GlobalState) => state.member.ecUserToken;
const userInfoSelector = (state: GlobalState) => state.member.userInfo;
const homeRecipientListSelector = (state: GlobalState) => state.member.homeRecipientList;
const storeRecipientListSelector = (state: GlobalState) => state.member.storeRecipientList;
const personInvoiceListSelector = (state: GlobalState) => state.member.personInvoiceList;
const companyInvoiceListSelector = (state: GlobalState) => state.member.companyInvoiceList;
const levelRulesSelector = (state: GlobalState) => state.member.levelRules;
const changeEmailStatusSelector = (state: GlobalState) => state.member.changeEmailStatus;
const changePasswordStatusSelector = (state: GlobalState) => state.member.changePasswordStatus;
const userUpdateStatusSelector = (state: GlobalState) => state.member.userUpdateStatus;
const unBindSocialiteStatusSelector = (state: GlobalState) => state.member.unBindSocialiteStatus;

// Memoized selector using createSelector
const mapHooksToState = createSelector(
	[
		loadingSelector,
		ecUserTokenSelector,
		userInfoSelector,
		homeRecipientListSelector,
		storeRecipientListSelector,
		personInvoiceListSelector,
		companyInvoiceListSelector,
		levelRulesSelector,
		changeEmailStatusSelector,
		changePasswordStatusSelector,
		userUpdateStatusSelector,
		unBindSocialiteStatusSelector,
	],
	(
		loading,
		ecUserToken,
		userInfo,
		homeRecipientList,
		storeRecipientList,
		personInvoiceList,
		companyInvoiceList,
		levelRules,
		changeEmailStatus,
		changePasswordStatus,
		userUpdateStatus,
		unBindSocialiteStatus,
	) => ({
		loading,
		ecUserToken,
		userInfo,
		homeRecipientList,
		storeRecipientList,
		personInvoiceList,
		companyInvoiceList,
		levelRules,
		changeEmailStatus,
		changePasswordStatus,
		userUpdateStatus,
		unBindSocialiteStatus,
		// Incorporating the logic for showIncompleteHint
		showIncompleteHint:
			Object.keys(userInfo).length === 0 ? false : !(userInfo?.name && userInfo?.mobile),
	}),
);

const memberActionsMap = {
	getMemberCentreDataByType,
	// deleteRecipientById,
	// deleteInvoiceById,
	setEcUserToken,
	updateUserInfo,
	getUserInfo,
	changeEmail,
	changePassword,
	verifyAndBindFacebook,
	unBindSocialite,
};

type MemberSelector = ReturnType<typeof mapHooksToState>;
type MemberActionsMap = typeof memberActionsMap;

/**
 * @description 會員相關 hook
 * @returns
 */
export const useMember = () =>
	useRedux<MemberSelector, MemberActionsMap>(mapHooksToState, memberActionsMap);
