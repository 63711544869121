import React from 'react';

import Link from 'components/atoms/Link';
import Navigation from 'components/molecules/Navigation';

import LogoImage from 'images/logo.inline.svg';

import styles from './index.module.css';

const Header: React.FC = () => (
	<header className={styles.header}>
		<div className={styles.headerContainer}>
			<Link to="/" className={styles.logo}>
				<LogoImage />
			</Link>
			<Navigation />
		</div>
	</header>
);

export default Header;
