/* eslint-disable react/no-unstable-nested-components, react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any */
import React, { useEffect, ReactNode, useState } from 'react';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import LabelCalendar from 'components/atoms/LabelCalendar';
import TextRemind from 'components/atoms/TextRemind';
import LoadingSpinner from 'components/atoms/LoadingSpinner';

import useMedia from 'util/hook/useMedia';
import { fixBackgroundScrollY, unfixBackgroundScrollY } from 'util/documentBackgroundScroll';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './index.module.css';

const days = ['日', '一', '二', '三', '四', '五', '六'];
const months = [
	'1 月',
	'2 月',
	'3 月',
	'4 月',
	'5 月',
	'6 月',
	'7 月',
	'8 月',
	'9 月',
	'10 月',
	'11 月',
	'12 月',
];

const locale = {
	localize: {
		day: (n: number) => days[n],
		month: (n: number) => months[n],
	},
	formatLong: {
		date: () => 'yyyy/mm/dd',
	},
};

interface CalendarProperty {
	className?: string;
	isOpen: boolean;
	openPanel: () => void;
	closePanel: () => void;
	isRangeSelect?: boolean;
	startDate?: Date | null;
	endDate?: Date | null;
	onDateChange?: (dates: any) => void;
	monthsShown?: number;
	filterDate?: (date: Date) => boolean;
	maxDate?: Date;
	unavailableDates?: Date[] | string[];
	MobileCalendarContainer?: React.FC<{ children: ReactNode }>;
	loading?: boolean;
	isCartCalendar?: boolean;
	selectedDate?: string | Date | null;
}

const Calendar: React.FC<CalendarProperty> = ({
	className,
	isOpen,
	openPanel,
	closePanel,
	// isRangeSelect = false,
	startDate = new Date(),
	endDate,
	onDateChange,
	monthsShown = 1,
	filterDate,
	maxDate,
	unavailableDates,
	loading,
	// MobileCalendarContainer,
	isCartCalendar = false,
	selectedDate,
}) => {
	const media = useMedia();
	const [bookingMessage, setBookingMessage] = useState<string | null>(null);
	const [currentViewingMonth, setCurrentViewingMonth] = useState(new Date().getMonth() + 1);

	const mobileDatePickerProps = {
		open: isOpen,
		shouldCloseOnSelect: true,
		onInputClick: () => openPanel(),
	};

	const desktopDatePickerProps = {};

	const datePickerProps = media !== 'desktop' ? mobileDatePickerProps : desktopDatePickerProps;

	useEffect(() => {
		const today = new Date();
		today.setHours(0, 0, 0, 0); // Normalize today to the start of the day for accurate comparison

		const isDateUnavailable = (date: Date) =>
			(unavailableDates ?? []).some(
				unavailableDate => new Date(unavailableDate).toDateString() === date.toDateString(),
			);

		const isCurrentMonthFullyBooked = () => {
			const viewingMonthIndex = currentViewingMonth - 1;
			const viewingYear = today.getFullYear(); // Consider today's year for dynamic year handling
			// Start checks from either the start of the viewing month or today's date, whichever is later
			const startOfMonth = new Date(viewingYear, viewingMonthIndex, 1);
			const startCheckDate = startOfMonth < today ? today : startOfMonth;
			const endOfMonth = new Date(viewingYear, viewingMonthIndex + 1, 0);

			// Adjust to calculate the last date to check within the current viewing month, today, or the maxDate, whichever comes first
			const lastDateToCheck = maxDate && maxDate < endOfMonth ? maxDate : endOfMonth;

			for (
				let date = new Date(startCheckDate);
				date <= lastDateToCheck;
				date.setDate(date.getDate() + 1)
			) {
				if (!isDateUnavailable(date)) {
					return false; // Found a date that is not unavailable, meaning the month is not fully booked
				}
			}

			return true; // No available dates found in the check range, thus the month is fully booked
		};

		const isNextMonthAvailable = () => {
			const viewingMonthIndex = currentViewingMonth - 1;
			const startOfNextMonth = new Date(today.getFullYear(), viewingMonthIndex + 1, 1);
			return maxDate && maxDate >= startOfNextMonth;
		};

		const updateMessage = () => {
			// Ensure the message logic only applies if the current viewing month/year matches or is after today's month/year
			const viewingMonthIsCurrentOrFuture =
				new Date(today.getFullYear(), currentViewingMonth - 1, 1) >=
				new Date(today.getFullYear(), today.getMonth(), 1);

			if (viewingMonthIsCurrentOrFuture && isCurrentMonthFullyBooked()) {
				if (isNextMonthAvailable()) {
					setBookingMessage('本月訂購時間已額滿，請至下個月份查看');
				} else {
					setBookingMessage('本月訂購時間已額滿');
				}
			} else {
				setBookingMessage(null);
			}
		};

		updateMessage();
	}, [maxDate, unavailableDates, isOpen, currentViewingMonth]);

	useEffect(() => {
		if (isOpen && media !== 'desktop') {
			fixBackgroundScrollY();
		}

		return () => {
			unfixBackgroundScrollY();
		};
	}, [isOpen]);

	return (
		<div
			className={classnames(
				styles.calendarWrapper,
				className,
				isCartCalendar && styles.isCartCalendar,
			)}
		>
			{loading ? (
				<LoadingSpinner />
			) : (
				<>
					<DatePicker
						dateFormat="yyyy/MM/dd"
						wrapperClassName={styles.inputWidth}
						selected={isCartCalendar ? selectedDate : ''}
						minDate={new Date()}
						endDate={endDate}
						onChange={onDateChange}
						monthsShown={monthsShown}
						excludeDates={unavailableDates}
						locale={locale as any}
						maxDate={maxDate}
						startDate={startDate}
						filterDate={filterDate}
						calendarClassName={styles.reactDatepicker}
						onCalendarOpen={openPanel}
						onCalendarClose={closePanel}
						inline
						onMonthChange={(date: Date) => setCurrentViewingMonth(date.getMonth() + 1)}
						dayClassName={(date: Date) => {
							if (
								isCartCalendar &&
								selectedDate &&
								new Date(selectedDate).getTime() === date.getTime()
							) {
								return `${styles.oneDay} ${styles.selectedDay}`;
							}
							return styles.oneDay;
						}}
						{...datePickerProps}
					/>
					<div className={styles.divider} />
					<div className={styles.labels}>
						<LabelCalendar text="已售完" size="xs" className={styles.label} />
						<LabelCalendar text="可預購" size="xs" className={styles.label} dotColor="#242424" />
					</div>
					{bookingMessage !== null && (
						<TextRemind className={styles.reminder} text={bookingMessage} />
					)}
				</>
			)}
		</div>
	);
};

export default Calendar;
