import { Dispatch } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';
import dayjs from 'dayjs';

import history from 'store/history';

import { useRedux } from 'util/hook/redux';
import {
	CartData,
	clearProductData,
	getProductQuantity,
	removeProductQuantityById,
	storeProductQuantity,
} from 'util/storeProductQuantity';
import { FormObject, FormRowData } from 'util/hook/useFormBuilder';
import { api } from 'util/api';
import pushHistory from 'util/pushHistory';
import { RecipientType } from 'enums/recipientType';
import { PickupType } from 'enums/pickupType';
import { OrderConfirmationType } from 'enums/orderConfirmationType';
import { DeliveryTimeType } from 'enums/deliveryTimeType';
import { ShoppingCartErrorType } from 'enums/shoppingCartErrorType';
import {
	ShippingFeeImageCollection,
	V1UserOrderShoppingCartUpdateRequestPayload,
	ShoppingCartResource,
	CalendarResource,
} from 'util/api/swaggerApi/data-contracts';

import { isExist } from 'util/helper';

import { createSelector } from 'reselect';
import { ModalTypes, openModal, openModalWithMessage } from './modal';

import { GetState, State as GlobalState } from './reducers';

// const { v1UserOrderShoppingCartList } = api;

interface OrderTempsResponse {
	items: {
		products: {
			id: string;
			quantity: string;
		}[];
		productCombinations: {
			id: string;
			quantity: string;
		}[];
		gifts: {
			id: string;
			quantity: string;
		}[];
	};
	order: {
		islandsType: string;
		shipType: string;
	};
	events: {
		couponEventId: number;
	};
}

export interface BasePayload {
	status: number;
	errorCode?: string | null;
	message?: string;
}

export interface ApiError {
	error: BasePayload;
}

export interface ShippingFeeImagesPayload {
	shippingFeeImages: ShippingFeeImageCollection[];
}

interface CartPayload {
	cart: ShoppingCartResource;
}

export type DeliveryMethod = { method: RecipientType; time: DeliveryTimeType };

export const setCartData = createAction(
	'SET_CART_DATA',
	(useApiData?: boolean) => async (dispatch: Dispatch, getState: GetState) => {
		const {
			cart: { cartData },
		} = getState();

		// if (!cartData || useApiData) {
		// 	// useApiData:到 step2 頁面、從 step2 回 step1 會強制使用暫存訂單資料 (從其他頁面進入 step1 不會)
		// 	try {
		// 		const { data } = await v1UserOrderShoppingCartList();
		// 		const cartDataFromApi: CartData = {
		// 			normal: {},
		// 			combination: {},
		// 		};

		// 		const {
		// 			items: { products, productCombinations },
		// 		} = data?.data as unknown as OrderTempsResponse;

		// 		products?.forEach(product => {
		// 			if (product.id) {
		// 				cartDataFromApi.normal[product.id] = Number(product.quantity);
		// 				// useApiData 時使用 api 暫存訂單之資料更新 reducer 跟 localStorage
		// 				if (useApiData) {
		// 					storeProductQuantity(Number(product.id), Number(product.quantity), false);
		// 				}
		// 			}
		// 		});
		// 		productCombinations?.forEach(product => {
		// 			if (product.id) {
		// 				cartDataFromApi.combination[product.id] = Number(product.quantity);
		// 				if (useApiData) {
		// 					storeProductQuantity(Number(product.id), Number(product.quantity), true);
		// 				}
		// 			}
		// 		});

		// 		return {
		// 			cartData: getProductQuantity() && !useApiData ? getProductQuantity() : cartDataFromApi,
		// 			apiData: cartDataFromApi,
		// 		};
		// 	} catch (e) {
		// 		// dispatch(checkErrorStatus(e as ApiError));
		// 		return { cartData: getProductQuantity() };
		// 	}
		// }
		// 非第一次操作購物車頁面
		return { cartData: getProductQuantity() };
	},
);

export const removeCartProductById = createAction(
	'REMOVE_CART_PRODUCT_BY_ID',
	(productId: number, isCombination = false) =>
		(dispatch: Dispatch) => {
			removeProductQuantityById(productId, isCombination);
			dispatch(setCartData());
		},
);

export const storeCartProduct = createAction(
	'STORE_CART_PRODUCT_BY_ID',
	(productId: number, quantity: number, isCombination = false) =>
		(dispatch: Dispatch) => {
			storeProductQuantity(productId, quantity, isCombination);
			dispatch(setCartData());
		},
);

export const setDeliveryMethod = createAction('SET_DELIVERY_METHOD', (data: FormRowData) => data);

export const clearCart = createAction('CLEAR_CART', () => (dispatch: Dispatch) => {
	clearProductData();
	dispatch(setCartData());
});

export const setConfirmationStatus = createAction(
	'SET_CONFIRMATION_STATUS',
	(status: OrderConfirmationType | undefined) => status,
);

export const selectInvoice = createAction('SELECT_INVOICE', (data: InvoiceProperty | null) => data);

export const selectRecipient = createAction(
	'SELECT_RECIPIENT',
	(data: ConsigneeInfoProperty | null) => data,
);

export const checkIsOverPurchaseLimit = createAction(
	'IS_OVER_PURCHASE_LIMIT',
	(data: boolean) => data,
);

export const setDeliveryDate = createAction('SET_DELIVERY_DATE', (data: string) => {
	if (data === '') {
		return '';
	}
	return dayjs(data).format('YYYY/MM/DD');
});

export const getShippingImages = createAction<Promise<ShippingFeeImagesPayload>>(
	'GET_SHIPPING_IMAGES',
	async () => {
		try {
			const { v1ShippingFeesList } = api;
			const { data } = await v1ShippingFeesList();
			if (data?.data) {
				return {
					shippingFeeImages: data?.data,
				};
			}

			return { shippingFeeImages: [] };
		} catch (e) {
			return { shippingFeeImages: [] };
		}
	},
);

export const getShoppingCart = createAction<Promise<CartPayload>>('GET_SHOPPING_CART', async () => {
	try {
		const { v1UserOrderShoppingCartList } = api;
		const { data } = await v1UserOrderShoppingCartList();
		if (data?.data) {
			return {
				cart: data?.data,
			};
		}

		return { cart: {} };
	} catch (e) {
		return { cart: {} };
	}
});

export const sendShoppingCart = createAction(
	'SEND_SHOPPING_CART',
	(finalTotalPrice: number, isReadyForCheckout: boolean) =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { v1UserOrderShoppingCartUpdate } = api;

			const {
				cart: { deliveryMethod, deliveryDate, cartData },
				coupon: { selectCoupon },
			} = getState();

			// 	map delivery time
			const deliveryTimeMap = {
				[DeliveryTimeType.NONE]: 'ALL',
				[DeliveryTimeType.BEFORE_13_HOURS]: 'AM',
				[DeliveryTimeType.AFTER_14_HOURS]: 'PM',
				[DeliveryTimeType.TO_12_HOURS]: '1',
				[DeliveryTimeType.TO_13_HOURS]: '2',
				[DeliveryTimeType.TO_14_HOURS]: '3',
				[DeliveryTimeType.TO_15_HOURS]: '4',
				[DeliveryTimeType.TO_16_HOURS]: '5',
				[DeliveryTimeType.TO_17_HOURS]: '6',
				[DeliveryTimeType.TO_18_HOURS]: '7',
			};

			const deliveryTime = deliveryMethod?.time;
			const deliveryTimeValue = deliveryTime ? deliveryTimeMap[deliveryTime] : undefined;

			const shoppingData: V1UserOrderShoppingCartUpdateRequestPayload = {
				items: cartData?.normal ?? {},
				frontend_amount: finalTotalPrice,
				coupon_id: 0,
			};

			if (selectCoupon) {
				shoppingData.coupon_id = selectCoupon.id;
			}

			if (deliveryMethod?.method) {
				shoppingData.delivery_type = deliveryMethod.method;
			}

			if (deliveryDate && deliveryTime) {
				shoppingData.delivery_date = deliveryDate;
				shoppingData.delivery_time = deliveryTimeValue as
					| '1'
					| '2'
					| '3'
					| '4'
					| '5'
					| '6'
					| '7'
					| 'ALL'
					| 'AM'
					| 'PM'
					| undefined;
			}

			try {
				const { status } = await v1UserOrderShoppingCartUpdate(shoppingData);
				// pushHistory(history, `/checkout`);
				// window.scrollTo(0, 0);
				if (status === 200) {
					await dispatch(getShoppingCart());
					if (isReadyForCheckout) {
						pushHistory(history, '/checkout');
					}
				}

				return {};
			} catch (e) {
				const apiError = (e as ApiError).error;

				if (isExist(apiError?.errorCode)) {
					return { sendShoppingCartError: apiError.errorCode };
				}

				return { sendShoppingCartError: 'SYSTEM_WAITING' };
			}
		},
);

// 清除 sendShoppingCartError
export const clearSendShoppingCartError = createAction('CLEAR_SEND_SHOPPING_CART_ERROR', () => ({
	sendShoppingCartError: undefined,
}));

interface CalendarDetailPayload {
	cartStorePickupCalendar: CalendarResource;
}

export const getStorePickupCalendarDetail = createAction<Promise<CalendarDetailPayload>, number>(
	'GET_CART_STORE_CALENDAR_DETAIL',
	async (capacity: number) => {
		try {
			const { v1CalendarDetail } = api;
			const { data } = await v1CalendarDetail({ type: PickupType.STORE_PICKUP, capacity });
			const cartStorePickupCalendar = {
				...data?.data,
			};

			return { cartStorePickupCalendar };
		} catch (e) {
			return { cartStorePickupCalendar: {} };
		}
	},
);

interface HomeDeliveryCalendarDetailPayload {
	cartHomeDeliveryCalendar: CalendarResource;
}

export const getCartHomeDeliveryCalendarDetail = createAction<
	Promise<HomeDeliveryCalendarDetailPayload>,
	number
>('GET_CART_HOME_CALENDAR_DETAIL', async (capacity: number) => {
	try {
		const { v1CalendarDetail } = api;
		const { data } = await v1CalendarDetail({ type: PickupType.HOME_DELIVERY, capacity });
		const cartHomeDeliveryCalendar = {
			...data?.data,
		};

		return { cartHomeDeliveryCalendar };
	} catch (e) {
		return { cartHomeDeliveryCalendar: {} };
	}
});

export interface Order {
	id?: number;
	no: string;
	virtualAccount: string;
	expiryDate: string;
	totalPrice: number;
}

export interface InvoiceProperty {
	taxNum?: string;
	taxName?: string;
	carrierType?: string;
	carrierNo?: string;
	id?: number;
	handleClick?: (id: number) => void;
	select?: number;
}

export interface ConsigneeInfoProperty {
	name?: string;
	mobileNumber?: string;
	telephoneNumber?: string;
	city?: string;
	cityZone?: string;
	address?: string;
	expectedDeliveryTime?: string;
	id?: number;
	handleClick?: (id: number) => void;
	select?: number;
	storeCode?: string;
	storeName?: string;
	storeAddress?: string;
}

export interface State {
	loading: boolean;
	cartData: CartData | null;
	confirmationStatus: OrderConfirmationType | undefined;
	deliveryMethod: DeliveryMethod | null;
	invoice: InvoiceProperty | null;
	recipient: ConsigneeInfoProperty | null;
	isOverPurchaseLimit: boolean;
	deliveryDate: string | null;
	shippingFeeImages: ShippingFeeImageCollection[];
	cart: ShoppingCartResource;
	sendShoppingCartError: ShoppingCartErrorType | undefined;
	cartStorePickupCalendar: CalendarResource;
	cartHomeDeliveryCalendar: CalendarResource;
	calendarLoading: boolean;
}

export const defaultState: State = {
	invoice: null,
	recipient: null,
	loading: false,
	cartData: null,
	confirmationStatus: undefined,
	deliveryMethod: null,
	isOverPurchaseLimit: false,
	deliveryDate: '',
	shippingFeeImages: [],
	cart: {},
	sendShoppingCartError: undefined,
	cartStorePickupCalendar: {},
	cartHomeDeliveryCalendar: {},
	calendarLoading: false,
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	cart: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_CART_DATA_FULFILLED: (
				state,
				action: Action<{
					cartData: CartData | null;
					apiData: CartData | null;
				}>,
			) => ({
				...state,
				cartData: action.payload.cartData,
				loading: false,
			}),
			SET_DELIVERY_METHOD: (state, action: Action<DeliveryMethod>) => ({
				...state,
				deliveryMethod: action.payload,
			}),
			SET_DELIVERY_DATE: (state, action: Action<string>) => ({
				...state,
				deliveryDate: action.payload,
			}),
			SET_CONFIRMATION_STATUS: (state, action: Action<OrderConfirmationType>) => ({
				...state,
				confirmationStatus: action.payload,
			}),
			SELECT_INVOICE: (state, action: Action<InvoiceProperty>) => ({
				...state,
				invoice: action.payload,
			}),
			SELECT_RECIPIENT: (state, action: Action<ConsigneeInfoProperty>) => ({
				...state,
				recipient: action.payload,
			}),
			IS_OVER_PURCHASE_LIMIT: (state, action: Action<boolean>) => ({
				...state,
				isOverPurchaseLimit: action.payload,
			}),
			GET_SHIPPING_IMAGES_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_SHIPPING_IMAGES_FULFILLED: (state, action: Action<ShippingFeeImagesPayload>) => ({
				...state,
				shippingFeeImages: action.payload.shippingFeeImages,
				loading: false,
			}),
			SEND_SHOPPING_CART_FULFILLED: (
				state,
				action: Action<{
					sendShoppingCartError: ShoppingCartErrorType | undefined;
				}>,
			) => ({
				...state,
				sendShoppingCartError: action.payload.sendShoppingCartError,
			}),
			GET_SHOPPING_CART_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_SHOPPING_CART_FULFILLED: (
				state,
				action: Action<{
					cart: ShoppingCartResource;
				}>,
			) => ({
				...state,
				cart: action.payload.cart,
				loading: false,
			}),
			GET_CART_STORE_CALENDAR_DETAIL_PENDING: state => ({
				...state,
				calendarLoading: true,
			}),
			GET_CART_STORE_CALENDAR_DETAIL_FULFILLED: (state, action: Action<CalendarDetailPayload>) => ({
				...state,
				cartStorePickupCalendar: action.payload.cartStorePickupCalendar,
				calendarLoading: false,
			}),
			GET_CART_HOME_CALENDAR_DETAIL_PENDING: state => ({
				...state,
				calendarLoading: true,
			}),
			GET_CART_HOME_CALENDAR_DETAIL_FULFILLED: (
				state,
				action: Action<HomeDeliveryCalendarDetailPayload>,
			) => ({
				...state,
				cartHomeDeliveryCalendar: action.payload.cartHomeDeliveryCalendar,
				calendarLoading: false,
			}),
			CLEAR_SEND_SHOPPING_CART_ERROR: state => ({
				...state,
				sendShoppingCartError: undefined,
			}),
		},
		defaultState,
	),
};

const cartActionsMap = {
	setCartData,
	removeCartProductById,
	storeCartProduct,
	clearCart,
	setDeliveryMethod,
	setConfirmationStatus,
	selectInvoice,
	selectRecipient,
	checkIsOverPurchaseLimit,
	setDeliveryDate,
	getShippingImages,
	sendShoppingCart,
	getCartHomeDeliveryCalendarDetail,
	getStorePickupCalendarDetail,
	clearSendShoppingCartError,
	getShoppingCart,
};

const cartDataSelector = (state: GlobalState) => state.cart.cartData;
const confirmationStatusSelector = (state: GlobalState) => state.cart.confirmationStatus;
const deliveryMethodSelector = (state: GlobalState) => state.cart.deliveryMethod;
const invoiceSelector = (state: GlobalState) => state.cart.invoice;
const recipientSelector = (state: GlobalState) => state.cart.recipient;
const isOverPurchaseLimitSelector = (state: GlobalState) => state.cart.isOverPurchaseLimit;
const deliveryDateSelector = (state: GlobalState) => state.cart.deliveryDate;
const shippingFeeImagesSelector = (state: GlobalState) => state.cart.shippingFeeImages;
const cartSelector = (state: GlobalState) => state.cart.cart;
const sendShoppingCartErrorSelector = (state: GlobalState) => state.cart.sendShoppingCartError;
const cartStorePickupCalendarSelector = (state: GlobalState) => state.cart.cartStorePickupCalendar;
const cartHomeDeliveryCalendarSelector = (state: GlobalState) =>
	state.cart.cartHomeDeliveryCalendar;
const loadingSelector = (state: GlobalState) => state.cart.loading;
const calendarLoadingSelector = (state: GlobalState) => state.cart.calendarLoading;

// Memoized selector
const mapHooksToState = createSelector(
	[
		cartDataSelector,
		confirmationStatusSelector,
		deliveryMethodSelector,
		invoiceSelector,
		recipientSelector,
		isOverPurchaseLimitSelector,
		deliveryDateSelector,
		shippingFeeImagesSelector,
		cartSelector,
		sendShoppingCartErrorSelector,
		cartStorePickupCalendarSelector,
		cartHomeDeliveryCalendarSelector,
		loadingSelector,
		calendarLoadingSelector,
	],
	(
		cartData,
		confirmationStatus,
		deliveryMethod,
		invoice,
		recipient,
		isOverPurchaseLimit,
		deliveryDate,
		shippingFeeImages,
		cart,
		sendShoppingCartError,
		cartStorePickupCalendar,
		cartHomeDeliveryCalendar,
		loading,
		calendarLoading,
	) => ({
		cartData,
		confirmationStatus,
		deliveryMethod,
		invoice,
		recipient,
		isOverPurchaseLimit,
		deliveryDate,
		shippingFeeImages,
		cart,
		sendShoppingCartError,
		cartStorePickupCalendar,
		cartHomeDeliveryCalendar,
		loading,
		calendarLoading,
	}),
);

type CartSelector = ReturnType<typeof mapHooksToState>;
type CartActionsMap = typeof cartActionsMap;

export const useCart = () =>
	useRedux<CartSelector, CartActionsMap>(mapHooksToState, cartActionsMap);
