import React from 'react';

import Toast from 'components/atoms/Toast';
import CookieCard from 'components/atoms/CookieCard';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';

interface AppProperty {
	children: React.ReactNode;
}

const App: React.FC<AppProperty> = ({ children }) => (
	<>
		<Header />
		{children}
		<Footer />
		<Toast />
		<CookieCard />
	</>
);

export default App;
