import React from 'react';
import classnames from 'classnames';

import Lottie from 'react-lottie';

import loadingData from './loading.json';

import styles from './index.module.css';

interface LoadingProperty {
	className?: string;
}

const Loading: React.FC<LoadingProperty> = ({ className = '' }) => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loadingData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	return (
		<div className={classnames(styles.loading, className)}>
			<Lottie options={defaultOptions} height={100} width={100} />
			<p>Loading</p>
		</div>
	);
};

export default Loading;
