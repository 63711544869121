/**
 * 會員中心功能之列舉
 *
 * @export
 * @enum {number}
 */
export enum MemberFeatureType {
	/**
	 * 帳戶設定
	 */
	ACCOUNT = 1,

	/**
	 * 我的訂單
	 */
	ORDERS,

	/**
	 * 我的優惠券
	 */
	COUPONS,
}
