import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/signup',
	components: () => [import(/* webpackChunkName: 'SignUp' */ './component')],
	render: ([SignUp]) => <SignUp />,
};

export default routes;
