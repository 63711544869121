/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Action, createAction, handleActions } from 'redux-actions';
import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import {
	CouponEventResource,
	UserCouponResource,
	V1UserCouponsListRequestParams,
} from 'util/api/swaggerApi/data-contracts';

import { VisibilityRuleType } from 'enums/visibilityRuleType';
import { nanoid } from 'nanoid';
import { Dispatch } from 'redux';
import { State as GlobalState } from './reducers';
import { checkErrorStatus } from './signin';
import { toast } from './toast';
import { ModalTypes, openModal } from './modal';

export interface BasePayload {
	status: number;
	errorCode?: string | null;
	message?: string;
}

export interface ApiError {
	error: BasePayload;
}

export const getCouponsList = createAction<Promise<{ couponsList: CouponEventResource[] }>>(
	'GET_COUPONS_LIST',
	async () => {
		try {
			const { v1CouponEventsList } = api;
			const { data } = await v1CouponEventsList();
			let list = (data?.data as CouponEventResource[]) || [];
			list = list.filter(coupon => coupon.currentVisibilityRule === VisibilityRuleType.ONLINE);
			return { couponsList: list as CouponEventResource[] };
		} catch (e) {
			return { couponsList: [] };
		}
	},
);

interface UserCouponListPayload {
	userCouponList: UserCouponResource[];
	paginationCount: V1UserCouponsListRequestParams['page'];
}

// 領取優惠券
export const getCouponByCode = createAction(
	'GET_COUPON_BY_CODE',
	(code: string) => async (dispatch: Dispatch) => {
		try {
			const { v1UserCouponsReceiveCreate } = api;
			const { status, message } = await v1UserCouponsReceiveCreate({ code });
			if (status === 200) {
				dispatch(getUserCouponList({ status: 'RECEIVED' }));
				dispatch(setErrMessageEmpty());
				const id = nanoid();
				dispatch(
					toast({
						id,
						message: '優惠券領取成功',
						type: 'success',
						color: 'green',
					}),
				);

				return { errMessage: '' };
			}
			dispatch(openModal(ModalTypes.ErrorModal));
			return { errMessage: message };
		} catch (e) {
			dispatch(openModal(ModalTypes.ErrorModal));
			return { errMessage: (e as ApiError).error.message };
		}
	},
);

// 領取並使用優惠券
export const toUseCouponByCode = createAction(
	'TO_USE_COUPON_BY_CODE',
	(code: string, amount: number) => async (dispatch: Dispatch) => {
		try {
			const { v1UserCouponsReceiveCreate } = api;
			const { data, status, message } = await v1UserCouponsReceiveCreate({ code });
			if (status === 200) {
				if (
					data?.data?.[0].couponEvent?.minimumPrice &&
					data?.data?.[0].couponEvent?.minimumPrice > amount
				) {
					return { selectCoupon: {}, errMessage: '此優惠券不符合使用條件' };
				}

				dispatch(getUserCouponList({ status: 'RECEIVED' }));
				const id = nanoid();
				dispatch(
					toast({
						id,
						message: '已使用優惠券',
						type: 'success',
						color: 'green',
					}),
				);
				dispatch(setErrMessageEmpty());

				const resultSelectCoupon = data?.data ? data?.data[0] : {};

				return { selectCoupon: resultSelectCoupon, errMessage: '' };
			}
			return { selectCoupon: {}, errMessage: message };
		} catch (e) {
			return { selectCoupon: {}, errMessage: (e as ApiError).error.message };
		}
	},
);

export const setSelectCoupon = createAction<CouponEventResource, CouponEventResource>(
	'SET_SELECT_COUPON',
	coupon => coupon,
);

/**
 * 取得會員優惠券列表
 */
export const getUserCouponList = createAction(
	'GET_USER_COUPON_LIST',
	(params: V1UserCouponsListRequestParams) => async (dispatch: Dispatch) => {
		try {
			const { v1UserCouponsList } = api;
			const { data } = await v1UserCouponsList(params);
			const { page } = params;

			const list =
				(page
					? (data?.data?.data as UserCouponResource[])
					: (data?.data as UserCouponResource[])) || [];

			const userCouponList = list.map(coupon => {
				const couponEvent = coupon.couponEvent || {};

				const result: UserCouponResource = {
					...coupon,
					couponEvent: {
						...couponEvent,
						eventBeginAt: dayjs(couponEvent.eventBeginAt).isValid()
							? dayjs(couponEvent.eventBeginAt).format('YYYY.MM.DD HH:mm')
							: '',
						eventEndAt: dayjs(couponEvent.eventEndAt).isValid()
							? dayjs(couponEvent.eventEndAt).format('YYYY.MM.DD HH:mm')
							: '',
					},
				};

				return result;
			});

			const total = data?.data?.meta?.total ?? 0;
			const paginationCount = Math.ceil(total / 6);

			return { userCouponList, paginationCount };
		} catch (e) {
			dispatch(checkErrorStatus(e as ApiError));
			return { userCouponList: [], paginationCount: 1 };
		}
	},
);
export const setSelectCouponRule = createAction<UserCouponResource, UserCouponResource>(
	'SET_SELECT_COUPON_RULE',
	coupon => coupon,
);

export const setErrMessageEmpty = createAction('SET_ERR_MESSAGE_EMPTY', () => '');

export interface State {
	loading: boolean;
	couponsList: CouponEventResource[];
	selectCoupon: UserCouponResource;
	selectedCouponRule: CouponEventResource;
	errMessage: string;
	userCouponList: UserCouponResource[];
	gettingErrMessage: string;
	paginationCount: V1UserCouponsListRequestParams['page'];
}

export const defaultState: State = {
	loading: false,
	couponsList: [],
	selectCoupon: {},
	selectedCouponRule: {},
	errMessage: '',
	userCouponList: [],
	gettingErrMessage: '',
	paginationCount: 1,
};

export const reducer = {
	coupon: handleActions<State, any>(
		{
			GET_COUPONS_LIST_FULFILLED: (state, action) => ({
				...state,
				couponsList: action.payload.couponsList,
				loading: false,
			}),
			TO_USE_COUPON_BY_CODE_PENDING: state => ({
				...state,
				loading: true,
			}),
			TO_USE_COUPON_BY_CODE_FULFILLED: (state, action) => ({
				...state,
				selectCoupon: action.payload.selectCoupon,
				loading: false,
				errMessage: action.payload.errMessage,
			}),
			GET_COUPON_BY_CODE_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_COUPON_BY_CODE_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				gettingErrMessage: action.payload.errMessage,
			}),
			GET_USER_COUPON_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_USER_COUPON_LIST_FULFILLED: (state, action: Action<UserCouponListPayload>) => ({
				...state,
				userCouponList: action.payload.userCouponList,
				paginationCount: action.payload.paginationCount,
				loading: false,
			}),
			SET_SELECT_COUPON: (state, action) => ({
				...state,
				selectCoupon: action.payload,
			}),
			SET_SELECT_COUPON_RULE: (state, action) => ({
				...state,
				selectedCouponRule: action.payload,
			}),
			SET_ERR_MESSAGE_EMPTY: state => ({
				...state,
				errMessage: '',
			}),
		},
		defaultState,
	),
};

const couponActionsMap = {
	getCouponsList,
	getCouponByCode,
	toUseCouponByCode,
	getUserCouponList,
	setSelectCoupon,
	setSelectCouponRule,
	setErrMessageEmpty,
};

const loadingSelector = (state: GlobalState) => state.coupon.loading;
const couponsListSelector = (state: GlobalState) => state.coupon.couponsList;
const selectCouponSelector = (state: GlobalState) => state.coupon.selectCoupon;
const errMessageSelector = (state: GlobalState) => state.coupon.errMessage;
const userCouponListSelector = (state: GlobalState) => state.coupon.userCouponList;
const selectedCouponRuleSelector = (state: GlobalState) => state.coupon.selectedCouponRule;
const gettingErrMessageSelector = (state: GlobalState) => state.coupon.gettingErrMessage;
const paginationCountSelector = (state: GlobalState) => state.coupon.paginationCount;

// Memoized selector using createSelector
const mapHooksToState = createSelector(
	[
		loadingSelector,
		couponsListSelector,
		selectCouponSelector,
		errMessageSelector,
		userCouponListSelector,
		selectedCouponRuleSelector,
		gettingErrMessageSelector,
		paginationCountSelector,
	],
	(
		loading,
		couponsList,
		selectCoupon,
		errMessage,
		userCouponList,
		selectedCouponRule,
		gettingErrMessage,
		paginationCount,
	) => ({
		loading,
		couponsList,
		selectCoupon,
		errMessage,
		userCouponList,
		selectedCouponRule,
		gettingErrMessage,
		paginationCount,
	}),
);

type couponSelector = ReturnType<typeof mapHooksToState>;
type couponActionsMap = typeof couponActionsMap;

export const useCoupon = () =>
	useRedux<couponSelector, couponActionsMap>(mapHooksToState, couponActionsMap);
