import { CarrierType } from 'enums/carrierType';
// import { ShippingRemarkType } from 'enums/shippingRemarkType';
import { DeliveryTimeType } from 'enums/deliveryTimeType';
import { InvoiceType } from 'enums/invoiceType';
import { CreateOrderErrorType } from 'enums/createOrderErrorType';

export const arrivedTime = [
	{
		label: '不指定',
		value: DeliveryTimeType.NONE,
	},
	{
		label: '13:00 前',
		value: DeliveryTimeType.BEFORE_13_HOURS,
	},
	{
		label: '14:00 ~ 18:00',
		value: DeliveryTimeType.AFTER_14_HOURS,
	},
];

export const pickUpArrivedTime = [
	{
		label: '11:00 ~ 12:00',
		value: DeliveryTimeType.TO_12_HOURS,
	},
	{
		label: '12:00 ~ 13:00',
		value: DeliveryTimeType.TO_13_HOURS,
	},
	{
		label: '13:00 ~ 14:00',
		value: DeliveryTimeType.TO_14_HOURS,
	},
	{
		label: '14:00 ~ 15:00',
		value: DeliveryTimeType.TO_15_HOURS,
	},
	{
		label: '15:00 ~ 16:00',
		value: DeliveryTimeType.TO_16_HOURS,
	},
	{
		label: '16:00 ~ 17:00',
		value: DeliveryTimeType.TO_17_HOURS,
	},
	{
		label: '17:00 ~ 18:00',
		value: DeliveryTimeType.TO_18_HOURS,
	},
];
// export const shippingRemark = [
// 	{
// 		label: '無指定',
// 		value: ShippingRemarkType.NONE,
// 	},
// 	{
// 		label: '煩請配送前致電',
// 		value: ShippingRemarkType.CALL,
// 	},
// 	{
// 		label: '煩請管理員簽收',
// 		value: ShippingRemarkType.ADMINISTRATOR,
// 	},
// 	{
// 		label: '煩請假日勿配送',
// 		value: ShippingRemarkType.NOT_HOLIDAY,
// 	},
// ];

export const invoiceType = [
	{
		label: '個人發票（二聯式）',
		value: InvoiceType.PERSON,
	},
	{
		label: '公司用發票（三聯式）',
		value: InvoiceType.COMPANY,
	},
	{
		label: '電子發票',
		value: InvoiceType.ELECTRONIC,
	},
];

export const retentionMethod = [
	{
		label: '會員載具',
		value: CarrierType.MEMBER,
	},
	{
		label: '手機條碼載具',
		value: CarrierType.PHONE,
	},
	{
		label: '自然人憑證',
		value: CarrierType.NATURAL_PERSON,
	},
	{
		label: '捐贈發票',
		value: CarrierType.DONATE,
	},
];

export const initForm = {
	name: {
		// 收件人姓名
		value: '',
		required: true,
		formError: null,
		touched: false,
	},
	mobile: {
		// 手機
		value: '+886',
		required: true,
		formError: null,
		touched: false,
		originalValue: '',
	},
	telephoneNumber: {
		// 市話
		value: '',
		required: false,
		formError: null,
		touched: false,
	},
	orderRemark: {
		// 訂單備註
		value: '',
		required: false,
		formError: null,
		touched: false,
	},
	payMethod: {
		// 付款方式
		value: 'CREDIT',
		required: true,
		formError: null,
		touched: false,
	},
	saveConsignee: {
		// 儲存為常用收件人資料
		value: false,
		required: false,
		formError: null,
		touched: false,
	},
	saveInvoice: {
		// 儲存為常用收件人資料
		value: false,
		required: false,
		formError: null,
		touched: false,
	},
	agreeTerms: {
		// 同意條款
		value: false,
		required: true,
		formError: null,
		touched: false,
	},
	sameAsBuyer: {
		// 同訂購人資料
		value: false,
		required: false,
		formError: null,
		touched: false,
	},
	// shippingRemark: {
	// 	// 貨運註記
	// 	value: ShippingRemarkType.NONE,
	// 	label: '無指定',
	// 	required: false,
	// 	formError: null,
	// 	touched: false,
	// },
	time: {
		// 希望到貨時間
		value: DeliveryTimeType.NONE,
		label: '不指定',
		required: true,
		formError: null,
		touched: false,
	},
};

export const initToHomeData = {
	country: {
		// 國家
		value: '台灣',
		label: '台灣',
		required: true,
		formError: null,
		touched: false,
	},
	city: {
		// 城市
		value: '',
		label: '',
		required: true,
		touched: false,
		formError: null,
	},
	area: {
		// 地區
		value: '',
		label: '',
		required: true,
		formError: null,
		touched: false,
	},
	address: {
		// 地址
		value: '',
		required: true,
		formError: null,
		touched: false,
	},
	remark: {
		// 貨運註記
		value: '',
		required: false,
		formError: null,
		touched: false,
	},
};

// export const initToStoreData = {
// 	storeCode: {
// 		// 門市號碼
// 		value: '',
// 		required: false,
// 		formError: null,
// 		touched: false,
// 	},
// 	storeName: {
// 		// 門市
// 		value: '',
// 		required: true,
// 		formError: null,
// 		touched: false,
// 	},
// 	storeAddress: {
// 		// 門市地址
// 		value: '',
// 		required: true,
// 		formError: null,
// 		touched: false,
// 	},
// };

export const initInvoiceData = {
	invoiceType: {
		// 發票類型
		value: 'E_INVOICE',
		required: true,
		formError: null,
		touched: false,
	},
	mobileBarcode: {
		// 手機條碼
		value: '/',
		required: false, // saveMethod === mobile required
		formError: null,
		touched: false,
		errorMessage: null,
	},
	certificate: {
		// 自然人憑證
		value: '',
		required: false, // saveMethod === certificate required
		formError: null,
		touched: false,
	},
	taxId: {
		// 統編
		value: '',
		required: false, // invoiceType === company required
		formError: null,
		touched: false,
	},
	companyName: {
		// 抬頭
		value: '',
		required: false, // invoiceType === company required
		formError: null,
		touched: false,
	},
};

export const buyerForm = {
	buyerName: {
		// 收件人姓名
		value: '',
		required: true,
		formError: null,
		touched: false,
	},
	buyerMobile: {
		// 收件人手機
		value: '',
		required: true,
		formError: null,
		touched: false,
	},
	buyerCountry: {
		// 國家
		value: '台灣',
		label: '台灣',
		required: true,
		formError: null,
		touched: false,
	},
	buyerCity: {
		// 收件人城市
		value: '',
		label: '',
		required: true,
		touched: false,
		formError: null,
	},
	buyerArea: {
		// 收件人地區
		value: '',
		label: '',
		required: true,
		formError: null,
		touched: false,
	},
	buyerAddress: {
		// 收件人地址
		value: '',
		required: true,
		formError: null,
		touched: false,
	},
};

export const errorModalData = {
	[CreateOrderErrorType.SHOPPING_CART_NOT_FOUND]: {
		title: '操作錯誤',
		content: '查無暫存訂單資料',
		onClick: () => {
			window.scrollTo(0, 0);
			window.location.reload();
		},
		btnText: '確認',
	},
	[CreateOrderErrorType.ORDER_CREATE_FAILED]: {
		title: '很抱歉',
		content: '訂單成立失敗',
		onClick: () => {
			window.scrollTo(0, 0);
			window.location.reload();
		},
		btnText: '確認',
	},
	[CreateOrderErrorType.COUPON_NOT_FOUND]: {
		title: '很抱歉',
		content: '查無優惠券資料',
		onClick: () => {},
		btnText: '確認',
	},
	[CreateOrderErrorType.ECPAY_INVOICE_ERROR]: {
		title: '很抱歉',
		content: '綠界服務發生異常',
		onClick: () => {},
		btnText: '確認',
	},
	[CreateOrderErrorType.ECPAY_CARRIER_ERROR]: {
		title: '操作錯誤',
		content: '手機載具條碼錯誤',
		onClick: () => {},
		btnText: '確認',
	},
	[CreateOrderErrorType.ECPAY_TAX_NUMBER_ERROR]: {
		title: '操作錯誤',
		content: '統一編號錯誤',
		onClick: () => {},
		btnText: '確認',
	},
	[CreateOrderErrorType.SHOPPING_CART_DATA_INVALID]: {
		title: '操作錯誤',
		content: '暫存訂單異常，請點擊下方按鈕再次提交訂單。',
		onClick: () => {},
		btnText: '確認',
	},
	[CreateOrderErrorType.CAPACITY_INSUFFICIENT]: {
		title: '很抱歉',
		content: '您所選擇自取/宅配時間已售完"',
		onClick: () => {},
		btnText: '確認',
	},
};
