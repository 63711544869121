import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import { BannerResource } from 'util/api/swaggerApi/data-contracts';
import { getImageFromStorage } from 'util/getImageFromStorage';

import { createSelector } from 'reselect';
import { State as GlobalState } from './reducers';

export interface BannerPayload {
	banners: BannerResource[];
}

export const getBanners = createAction<Promise<BannerPayload>>('GET_BANNERS', async () => {
	try {
		const { v1BannersList } = api;
		const { data } = await v1BannersList();
		if (data?.data) {
			return {
				banners: data?.data
					.map(banner => ({
						...banner,
						image: banner.image ? getImageFromStorage(banner.image) : '',
						imageMobile: banner.imageMobile ? getImageFromStorage(banner.imageMobile) : '',
					}))
					.filter(banner => banner.currentVisibilityRule === 'ONLINE'),
			};
		}

		return { banners: [] };
	} catch (e) {
		return { banners: [] };
	}
});

export interface State {
	loading: boolean;
	banners: BannerResource[];
}

export const defaultState: State = {
	loading: false,
	banners: [],
};

export const reducer = {
	banners: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_BANNERS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_BANNERS_FULFILLED: (state, action) => ({
				...state,
				banners: [...action.payload.banners],
				loading: false,
			}),
		},
		defaultState,
	),
};

const bannerActionsMap = {
	getBanners,
};

const mapHooksToState = createSelector(
	(state: GlobalState) => state.banners.banners,
	banners => ({
		banners,
	}),
);

type BannerSelector = ReturnType<typeof mapHooksToState>;
type BannerActionsMap = typeof bannerActionsMap;

export const useBanner = () =>
	useRedux<BannerSelector, BannerActionsMap>(mapHooksToState, bannerActionsMap);
