/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import { handleActions, createAction, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import { ModalResource } from 'util/api/swaggerApi/data-contracts';
import { getImageFromStorage } from 'util/getImageFromStorage';

import { GetState, State as GlobalState } from './reducers';

export enum ModalTypes {
	Empty,
	Normal,
	CouponModal,
	ConsigneeInfoModal,
	InvoiceInfoModal,
	ProfileEditedModal,
	ReceivingInfoEditedModal,
	InvoiceEditedModal,
	ModalEvent,
	MaintainModal,
	ErrorModal,
	DeleteConfirmModal,
	SignUpGiftModal,
	ModalShippingFee,
	ModalSideCart,
	ModalUnbind,
	ModalCalendar,
	ModalCartError,
	ModalOrderError,
	DuplicateOrderModal,
}

export interface ModalMessage {
	title: string;
	content?: string;
}

type ModalAction = (() => void) | null;

interface ReturnModal {
	type: ModalTypes;
	message: ModalMessage;
}

interface ReturnActionModal extends ReturnModal {
	action?: ModalAction;
}

const setModalBackgroundScrollY = () => {
	if (!document.body.classList.contains('no-scroll')) {
		document.body.classList.add('no-scroll');
	}

	if (!document.documentElement.classList.contains('no-scroll')) {
		document.documentElement.classList.add('no-scroll');
	}
};

const restoreModalBackgroundScrollY = () => {
	document.documentElement.classList.remove('no-scroll');

	if (document.body.classList.contains('no-scroll')) {
		document.body.classList.remove('no-scroll');
	}
};

// for 已經於頁面中定義好的 Modal，沒有客製化訊息
export const openModal = createAction<void, ModalTypes>(
	'OPEN_MODAL',
	type => (dispatch: Dispatch) => {
		setModalBackgroundScrollY();

		dispatch(
			openModalWithMessage(type, {
				title: '',
				content: '',
			}),
		);
	},
);

// for 需要顯示客製化訊息的 Modal
export const openModalWithMessage = createAction<ReturnModal, ModalTypes, ModalMessage>(
	'OPEN_MODAL_WITH_MESSAGE',
	(type, message) => {
		setModalBackgroundScrollY();

		return { type, message };
	},
);

// for 需要顯示客製化訊息以及有動作按鈕的 Modal
export const openModalWithAction = createAction<
	ReturnActionModal,
	ModalTypes,
	ModalMessage,
	ModalAction
>('OPEN_MODAL_WITH_ACTION', (type, message, action) => {
	setModalBackgroundScrollY();
	return { type, message, action };
});
// restoreModalBackgroundScrollY

export const closeModal = createAction(
	'CLOSE_MODAL',
	() => (_dispatch: Dispatch, getState: GetState) => {
		restoreModalBackgroundScrollY();
		const {
			modal: { type },
		} = getState();

		return type.slice(0, type.length - 1);
	},
);

export const openMaintainModal = createAction<void>(
	'OPEN_MAINTAIN_MODAL',
	() => (dispatch: Dispatch, getState: GetState) => {

		const {
			modal: { isMaintainModalOpened, maintainImgInfo },
		} = getState();

		if (!isMaintainModalOpened && maintainImgInfo.image) {
			dispatch(openModal(ModalTypes.MaintainModal));
		}
	},
);

export const getMaintainImg = createAction('GET_MAINTAIN_IMG', async () => {
	try {
		const { v1ModalsList } = api;
		const { status, data } = await v1ModalsList();

		if (status === 200 && data?.data?.image) {
			return {
				...data.data,
				image: getImageFromStorage(data.data.image),
			};
		}
		return {};
	} catch (error) {
		return {};
	}
});

export interface State {
	type: ModalTypes[];
	message: ModalMessage;
	action: ModalAction;
	isMaintainModalOpened: boolean;
	loading: boolean;
	maintainImgInfo: ModalResource;
}

export const defaultState: State = {
	type: [ModalTypes.Empty],
	message: {
		title: '',
		content: '',
	},
	action: null,
	isMaintainModalOpened: false,
	loading: false,
	maintainImgInfo: {},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	modal: handleActions<State, any>(
		{
			OPEN_MODAL_WITH_MESSAGE: (state, action) => ({
				...state,
				type: [...state.type, action.payload.type],
				message: action.payload.message,
			}),
			OPEN_MODAL_WITH_ACTION: (state, action) => ({
				...state,
				type: [...state.type, action.payload.type],
				message: action.payload.message,
				action: action.payload.action,
			}),
			CLOSE_MODAL: (state, action: Action<ModalTypes[]>) => ({
				...state,
				type: action.payload,
				message: {
					title: '',
					content: '',
				},
				action: null,
			}),
			OPEN_MAINTAIN_MODAL: state => ({
				...state,
				isMaintainModalOpened: true,
			}),
			GET_MAINTAIN_IMG_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_MAINTAIN_IMG_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				maintainImgInfo: action.payload,
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => state.modal;

const modalActionMap = {
	openModal,
	openModalWithMessage,
	closeModal,
	openModalWithAction,
	openMaintainModal,
	getMaintainImg,
};

type ModalSelector = ReturnType<typeof mapHooksToState>;
type ModalActionsMap = typeof modalActionMap;

export const useModal = () =>
	useRedux<ModalSelector, ModalActionsMap>(mapHooksToState, modalActionMap);

