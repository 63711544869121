export enum StorageKey {
	/**
	 * api access token
	 */
	EC_USER = 'ec-user',

	/**
	 * 記住我功能的狀態
	 */
	AUTO_COMPLETE = 'auto-complete',

	/**
	 * 重置密碼時的密鑰
	 */
	RESET_PASSWORD = 'reset-password',

	/**
	 * line login code 解碼後可以重複使用的 id_token
	 */
	LINE_AUTH = 'line-auth',

	/**
	 * 用於 line 綁定的 socialite_token
	 */
	LINE_BIND = 'line-bind',

	/**
	 * 用於 line 流程驗證密碼後綁定所需之密鑰 key
	 */
	LINE_VERIFY_PASSWORD = 'line-verify-password',

	/**
	 * 轉址所需資訊
	 */
	REDIRECT_INFO = 'redirect-info',

	/**
	 * 某些情境會需要登入後轉址，此時所需資訊
	 */
	SIGNIN_CALLBACK_INFO = 'signin-callback-info',

	/**
	 * cookie 同意狀態
	 */
	COOKIE_CONSENT = 'cookie-consent',

	/**
	 * 用於 facebook 綁定的 socialite_token, name, email
	 */
	FACEBOOK_BIND = 'facebook-bind',
}
