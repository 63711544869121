import React from 'react';
import classnames from 'classnames';
import ShoppingCartIcon from 'images/icon/shopping-cart.inline.svg';

import formatWithThousandsSeparator from 'util/formatWithThousandsSeparator';

import styles from './index.module.css';

interface CartProperty {
	quantity: number;
	className?: string;
	isCartActive?: boolean;
}

const IconCart: React.FC<CartProperty> = ({ quantity, className, isCartActive }) => (
	<div className={classnames(styles.cart, isCartActive && styles.isCartActive, className)}>
		<ShoppingCartIcon />
		{quantity !== 0 && (
			<span className={styles.quantity}>{formatWithThousandsSeparator(quantity)}</span>
		)}
	</div>
);

export default IconCart;
