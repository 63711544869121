import { getItem, setItem } from './storage';

interface ProductData {
	[productId: string]: number;
}
export interface CartData {
	normal: ProductData;
	combination: ProductData;
}

export const storeProductQuantity = (
	productId: number,
	quantity: number,
	isCombination = false,
) => {
	try {
		const existingData = getItem('productData');
		let dataToStore: CartData = {
			normal: {},
			combination: {},
		};
		if (existingData) {
			dataToStore = JSON.parse(existingData);
		}

		if (isCombination) {
			dataToStore.combination[productId] = quantity;
		} else {
			dataToStore.normal[productId] = quantity;
		}

		setItem('productData', JSON.stringify(dataToStore));

		console.log(`商品ID ${productId} 的數量已成功儲存為 ${quantity}。`);
	} catch (error) {
		console.error('儲存商品資料時發生錯誤:', error);
	}
};

export const getProductQuantity = (): CartData | null => {
	try {
		const existingData = getItem('productData');
		if (!existingData) {
			return null;
		}
		const data = JSON.parse(existingData);
		return data;
	} catch (error) {
		console.error('取出商品資料時發生錯誤:', error);
		return null;
	}
};

export const getProductQuantityById = (productId: number, isCombination = false) => {
	try {
		const existingData = getItem('productData');
		if (!existingData) {
			return null;
		}
		const data = JSON.parse(existingData);
		if (isCombination) {
			return data.combination[productId];
		}
		return data.normal[productId];
	} catch (error) {
		console.error('取出商品資料時發生錯誤:', error);
		return undefined;
	}
};

export const removeProductQuantityById = (productId: number, isCombination = false) => {
	try {
		const existingData = getItem('productData');
		if (!existingData) {
			return;
		}
		const data = JSON.parse(existingData);
		if (isCombination) {
			delete data.combination[productId];
		} else {
			delete data.normal[productId];
		}
		console.log('商品數量資料已成功清空。');
		setItem('productData', JSON.stringify(data));
	} catch (error) {
		console.error('刪除商品資料時發生錯誤:', error);
	}
};

export const clearProductData = () => {
	try {
		const emptyData: CartData = { normal: {}, combination: {} };
		setItem('productData', JSON.stringify(emptyData));

		console.log('商品數量資料已成功清空。');
	} catch (error) {
		console.error('清空商品資料時發生錯誤:', error);
	}
};
