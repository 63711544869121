import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { parseQueryString } from 'util/parseQueryString';
import { getLineIdToken } from 'models/thirdPartyAuth';

const routes: CustomRoute = {
	path: '/line-auth',
	components: () => [import(/* webpackChunkName: 'lineAuth' */ './component')],
	render: ([LineAuth]) => <LineAuth />,
	onEnter: async ({ next, store }) => {
		const { code } = parseQueryString();

		if (code) {
			await store.dispatch(getLineIdToken(code, 'register'));
		}
		const children = await next();
		return children;
	},
};
export default routes;
